import React from 'react';
import {connect} from "react-redux";
import {closeDrawer} from "../../store/actions/drawersActions";
import Modal from "../framework/Modal";
import {image} from "../../app/helpers";
import {Link} from "react-router-dom";
import routes from "../../app/routes";


class BasketModal extends React.Component   {

    render = () => {
        const item = this.props.modal?.item;
        return  <Modal width={560} zIndex={100}
                       show={this.props.modal.status} close={() => this.props.closeDrawer("basketModal")}>
            <div className="text-black-50 text-center mb-3">
                <i className="far fa-check-circle text-success mr-2" style={{fontSize: 40}} /> <br />
                Proizvod dodat u korpu:
            </div>


            {item &&
            <div className="d-flex align-items-center mb-3">
                <div className="mr-2" style={{flex: "0 0 100px"}}>
                    <img src={image(item.image_path)} alt={item.name} className="img-fluid" />
                </div>
                <div>
                    <div className="weight_500">{item?.name}</div>
                </div>
            </div>}

            <div className="row">
                <div className="col-md-6 mb-3 mb-md-0 pr-md-1">
                    <Link  onClick={() => this.props.closeDrawer("basketModal")}
                           to={routes.basket.route} className="btn text-uppercase btn-primary btn-block text-white font-weight-bold">
                        <i className="fas fa-shopping-cart mr-1" /> Produžite na <br /> poručivanje
                    </Link>
                </div>
                <div className="col-md-6 pl-md-1">
                <button onClick={() => this.props.closeDrawer("basketModal")}
                    className="btn btn-outline-primary btn-block font-weight-bold text-uppercase">
                    <i className="fa fa-arrow-left mr-1" />  Vratite se <br /> nazad</button>
                </div>
            </div>
        </Modal>
    };
}
const mapStateToProps = state => ({
    modal: state.drawers.basketModal
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer,
};
export default connect(mapStateToProps, mapDispatchToProps)(BasketModal);
