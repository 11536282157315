import React from 'react';
import {connect} from "react-redux";
import {currencies} from "../../providers/price";


class FormatPrice extends React.Component {

    render() {
        let price = this.props.price;
        if (!price && price !== 0) return "";
        price = parseFloat(price);
        let short = currencies()[0].short;
        if(this.props.currency.kurs && !this.props.original) {
            price = price / this.props.currency.kurs;
            short = currencies()[this.props.currency.id].short;
        }
        price = price.toFixed(0);
        let strPrice = String(price);
        if (price > 999) {
            const pos = -3;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        if (price > 999999) {
            const pos = -7;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        return strPrice.replace(".", ",") + " " + short;
    }
}
const mapStateToProps = state => ({
    currency: state.currency,
});
export default connect(mapStateToProps)(FormatPrice);
