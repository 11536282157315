import {SET_CATEGORIES_BY_ID} from "../actions/categoriesByIdActions";

function loop(arr, acc) {
    for(let i=0; i<arr.length; i++) {
        const item = arr[i];
        acc[item.id] = item;
        loop(item.children, acc);
    }
    return acc;
}

const categoriesByIdReducer = function(state = {}, {type, payload})      {
    switch(type)        {
        case SET_CATEGORIES_BY_ID:
            return loop(payload, {});
        default:
            return state;
    }
}
export default categoriesByIdReducer;