import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import {order_status, paying_method} from "../../providers/order";
import {image} from "../../app/helpers";
import routes from "../../app/routes";
import {Link} from "react-router-dom";
import {processPrice} from "../../providers/price";
import FormatPrice from "./FormatPrice";
import Transaction from "../pages/orderStatus/Transaction";
const sqlFrmat = 'Do MMMM YYYY, HH:mm';


class Printable extends React.Component {

    state = {

    }

    print = (elem) =>    {
        let root = document.getElementById("root").innerHTML;
        document.getElementById("root").innerHTML = document.getElementById(elem).innerHTML;

        window.print();
        window.close();
        document.getElementById("root").innerHTML = root;
        return true;
    }

    render = () => {
        let order = this.props.order;
        if(order.checkout_error) {
            return "";
        }
        const status = order_status(order.status);
        return <div>
            <div className="text-right font_2">
                <button onClick={() => this.print("printable")}>
                   <i className="fa fa-print" /> Odštampaj
                </button>
            </div>
            <div id="printable">
                <div className="mb-4">
                    <h4> {"Porudžbina #" + order.code}</h4>
                </div>
                <div className="mb-4 font_4" style={{color: status.color}}>
                    <strong> Status: {status.title} </strong>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Osnovno </div>
                        <div className="mb-1">Šifra</div>
                        <div className="mb-3">
                            <strong>#{order.code}</strong>
                        </div>
                        <div className="mb-1">Cena</div>
                        <div className="mb-3">
                            <div className="font_2">
                                <strong><FormatPrice price={order.full_price} /></strong>
                            </div>
                        </div>
                        <div className="mb-1">Datum</div>
                        <div className="mb-3">
                            <strong>{moment.utc(order.created_at).local().format(sqlFrmat)}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Kontakt </div>
                        {order.firm_name &&
                        <div>
                            <div className="mb-1">Firma i PIB</div>
                            <div className="mb-3">
                                <strong>{order.firm_name}</strong>
                                <div className="text-black-50">{order.firm_pib}</div>
                            </div>
                        </div>}

                        <div className="mb-1">Ime i prezime</div>
                        <div className="mb-3">
                            <strong>{order.name} {order.lastname}</strong>
                        </div>

                        <div className="mb-1">Telefon</div>
                        <div className="mb-3">
                            <strong>{order.phone}</strong>
                        </div>
                        <div className="mb-1">Email</div>
                        <div className="mb-3">
                            <strong>{order.email}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Adresa </div>
                        <div className="mb-1">Adresa</div>
                        <div className="mb-3">
                            <strong>{order.address}</strong>
                        </div>
                        <div className="mb-1">Grad</div>
                        <div className="mb-3">
                            <strong>{order.zip} {order.city}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Način plaćanja </div>
                        {paying_method(order.payment_method)}
                    </div>
                </div>

                {order.message &&
                <div className="p-3 mb-3 bcg-2">
                    <div className="font-weight-bold mb-3 border-bottom"> Dodatna poruka </div>
                    <div className="pre_wrap">{order.message}</div>
                </div>}

                <table className="table table-bordered">
                    <tbody>
                    {order.products.map((item) => {
                        return <tr key={item.id}>
                            <td width={140}>
                                <img src={image(item.image_path)} alt={item.name} className="img-fluid" />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Link to={routes.product.route(item)} className="text-primary">
                                    <strong>{item.name}</strong>
                                </Link>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div className="mb-1">Kupovna cena:</div>
                                <div className="mb-3">
                                    <strong><FormatPrice price={item.pivot.price} /></strong>
                                </div>
                                <div className="mb-1">Trenutna cena:</div>
                                <div>
                                    <strong><FormatPrice price={processPrice(item)} /></strong>
                                </div>
                            </td>
                            <td>
                                <div className="mb-1">Količina:</div>
                                <div className="mb-3 font_2">
                                    <strong>{item.pivot.qty}</strong>
                                </div>
                            </td>
                            <td>
                                <div className="mb-1">Ukupno:</div>
                                <div className="mb-3">
                                    <strong>
                                        <FormatPrice price={item.pivot.price * item.pivot.qty} />
                                    </strong>
                                </div>
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td colSpan={4}> </td>
                        <td width={160}>
                            <div className="mb-2 font-weight-bold">Ukupno:</div>
                            <div className="mb-2 text-primary">
                                <strong><FormatPrice price={order.full_price} original={true} /></strong>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
export default connect(mapStateToProps)(Printable);