import {OPEN_DRAWER, CLOSE_DRAWER} from "../actions/drawersActions";

const drawers = {
    search: {status: false},
    basketModal: {status: false},
    categories: {status: false},
}

const drawersReducer = function(state= drawers, {type, payload})      {
    switch(type)        {
        case OPEN_DRAWER:
            if(payload.name)        {
                return {...state, [payload.name]: {...drawers[payload.name], ...payload, status: true}}
            }
            return {...state, [payload]: {status: true}};
        case CLOSE_DRAWER:
            if(payload.name)        {
                return {...state, [payload.name]: {...drawers[payload.name], ...payload, status: false}}
            }
            return {...state, [payload]: {status: false}};
        default:
            return state;
    }
}
export default drawersReducer;