import React from 'react';
import logo from "../../assets/images/logo.png"
import MainSearch from "./MainSearch";
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import Bag from "../../assets/svg/Bag"
import User from "../../assets/svg/User"
import {userName} from "../../providers/userProvider";


class MainHeader extends React.Component {

    openBasket = (e) => {
        e.preventDefault();
        if(this.props.swipeMenu.status)     {
            this.props.closeDrawer("swipeMenu");
        } else  {
            this.props.openDrawer({name: "swipeMenu", tab: "basket"});
        }
    }

    render() {
        return (
            <header>
                <div className="d-md-flex align-items-center">
                    <div className="text-center mb-md-0 mb-2">
                        <Link to={routes.welcome.route}>
                            <img src={logo} className="img-fluid" width={200} alt="Logo GoGorila" />
                        </Link>
                    </div>

                    <div className="flex-grow-1 ml-lg-5 ml-md-4 mr-md-3">
                        <MainSearch history={this.props.history} />
                    </div>

                    <div className="d-none d-md-block">
                        <Link to={this.props.user ? routes.dashboard.route : routes.login.route} className="btn btn-link inherit">
                            <User width={24} />
                            <div> {this.props.user ? userName(this.props.user) : routes.login.name} </div>
                        </Link>
                        <Link to={routes.basket.route} className="btn btn-link inherit position-relative">
                            {this.props.basket?.length > 0 &&
                            <span className="position-absolute font_08 bg-primary text-white font-weight-bold d-inline-block"
                                  style={{top: 0, right: 0, borderRadius: "50%", width: 20, height: 20}}>
                                {this.props.basket?.length}
                            </span>}
                            <Bag width={24} />
                            <div> KORPA </div>
                        </Link>
                    </div>
                </div>
                <div className="mt-md-4 main_pages" style={{top: this.props.open ? 0 : -48}}>
                    <div className="main_pages_scroll d-flex align-items-center">
                        <button
                            onClick={() => this.props.openDrawer({name: "categories"})}
                            className="btn btn-primary pr-3 pr-md-5 font-weight-bold mr-3 d-none d-md-block">
                            <i className="fa fa-bars mr-2" /> KATEGORIJE
                        </button>

                        <div className="weight_500">
                       <span className="mr-3">
                           <Link className={this.props.location.pathname === routes.welcome.route ? "header_active" : ""}
                               to={routes.welcome.route}>
                               {routes.welcome.name}
                           </Link>
                       </span>
                        <span className="mr-3">
                            <Link className={this.props.location.pathname.startsWith(routes.shop.route()) ? "header_active" : ""}
                                to={routes.shop.route()}>
                               {routes.shop.name}
                           </Link>
                        </span>
                            <span className="mr-3">
                                <Link className={this.props.location.pathname === "/preporuceno" ? "header_active" : ""}
                                      to={routes.recommended.route()}>
                                {routes.recommended.name}
                               </Link>
                            </span>
                            <span className="mr-3">
                                <Link className={this.props.location.pathname === "/ponuda" ? "header_active" : ""}
                                    to={routes.offer.route}>
                                {routes.offer.name}
                               </Link>
                            </span>
                            <span className="mr-3">
                                <Link className={this.props.location.pathname === "/novo" ? "header_active" : ""}
                                      to={routes.new.route}>
                                {routes.new.name}
                               </Link>
                            </span>
                            <span className="mr-3">
                                  <Link className={this.props.location.pathname === routes.categories.route ? "header_active" : ""}
                                      to={routes.categories.route}>
                                       {routes.categories.name}
                                   </Link>
                            </span>
                            <span className="mr-3">
                                  <Link className={this.props.location.pathname === routes.brands.route ? "header_active" : ""}
                                      to={routes.brands.route}>
                                       {routes.brands.name}
                                   </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
const mapStateToProps = state => ({
    wishlist: state.wishlist,
    basket: state.basket,
    user: state.user
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
