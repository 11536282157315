import React from 'react';
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import Error404 from "../../elements/Error404";
import {getProducts} from "../../../app/hooks";
import Pagination from "../../framework/Pagination";
import ProductRender from "../../elements/ProductRender";
import {formToQueryString} from "../../../app/helpers";
import {getChildrenIds, unmarshal} from "../../../providers/categories";
import Categories from "./Categories";
import Breadcrumbs from "../../framework/Breadcrumbs";
import Product from "./Product";
import {setData} from "../../../store/actions/dataActions";
import Filters from "./Filters";
import {seoDescription, seoKeywords, seoReset, seoTitle} from "../../../providers/seoProvider";
import Loader from "../../framework/Loader";
import Drawer from "../../framework/Drawer";


class Shop extends React.Component {


    default = {
        page: 1,
        brand: "",
        search: "",
        categories: false,
        limit: 40,
        order: "",
        offer: "",
        new: "",
        filters: {}
    }
    state = {
        loading: true,
        error404: false,
        query: false,
        category: false,
        categories: false,
        filters: false,
        productId: false,
        products: {data: []},
        form: this.default
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const form = {...this.state.form, ...{[name]: value}};
        let queryString = formToQueryString(form);
        window.history.pushState(form, "", queryString);
        this.load(true);
    }
    newRoute = (route) => {
        const form = {...this.state.form, page: 1, filters: {}, categories: false}
        const queryString = formToQueryString(form);
        window.history.pushState(this.state.form, "", route + queryString);
        this.load();
    }

    scrollToTop = () => {
        window.scrollTo({ top: 120, behavior: 'smooth' });
    };

    renderCategoriesAndFilters = () => {
        return <>
            <Categories
                category={this.state.category}
                cats={this.state.categories}
                newRoute={this.newRoute} />

            <Filters
                filters={this.state.filters}
                selected={this.state.form.filters}
                editField={this.editField} />
        </>
    }

    render() {
        if(this.state.loading) {
            return <Loader />;
        }
        if(this.state.error404)        {
            return <Error404 />
        }
        if(this.state.productId)        {
            return <Product id={this.state.productId} history={this.props.history} />
        }
        return (
            <div className="container mt-3 mb-5">
                <div className="d-md-none">
                    <Drawer
                        width="80%"
                        anchor="left"
                        zIndex={101}
                        show={this.props.drawers.categories.status}
                        close={() => this.props.closeDrawer("categories")}>

                        <div className="m-2">
                            {this.renderCategoriesAndFilters()}
                        </div>
                    </Drawer>
                </div>


                <div className="mb-3">
                    <Breadcrumbs category={this.state.category} />
                </div>
                <div className="row">
                    <div className="col-md-3 d-none d-md-block">
                        {this.renderCategoriesAndFilters()}
                    </div>

                    <div className="col-md-9">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                                {this.state.form.brand &&
                                <div className="mb-3">
                                    <button onClick={()=>this.editField({target:{name:"brand",value:""}})}
                                            className="btn btn-primary btn-sm">
                                        BREND: <strong>{this.state.form.brand}</strong> &nbsp; <i className="fa fa-close" />
                                    </button>
                                </div>}

                                {this.state.form.search &&
                                <div className="mb-3">
                                    <button onClick={()=>this.editField({target:{name:"search",value:""}})}
                                            className="btn btn-primary btn-sm">
                                        PRETRAGA: <strong>{this.state.form.search}</strong> &nbsp; <i className="fa fa-close" />
                                    </button>
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="mr-2 d-none d-md-block"> Sortiraj: </span>
                                <select onChange={this.editField}
                                        style={{minWidth: 166}}
                                        name="order" value={this.state.form.order} className="form-control mr-2">
                                    {[{name: "Poslednje", value: ""},
                                        {value: "cenirastuce", name: "Ceni rastuće"},
                                        {value: "ceniopadajuce", name: "Ceni opadajuće"},
                                        {value: "imenu", name:"Po imenu"}].map((item, index) => {
                                        return <option key={item.value} value={item.value}> {item.name} </option>
                                    })}
                                </select>

                                <span className="mr-2 d-none d-md-block"> Prikaži: </span>
                                <select onChange={this.editField}
                                    name="limit" value={this.state.form.limit} className="form-control">
                                    <option>20</option>
                                    <option>40</option>
                                    <option>80</option>
                                    <option>120</option>
                                </select>
                            </div>
                        </div>

                        {this.state.products.data.length === 0
                            ? <div className="m-5 font-weight-bold">Prazna pretraga.</div>
                            : <div>

                                <div className="row">
                                    {this.state.products.data.map((item, index) => {
                                        return <div key={item.id} className="col-md-3 col-6 p-0 mb-3">
                                            <ProductRender item={item} />
                                        </div>
                                    })}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <Pagination
                                        change={this.editField}
                                        page={this.state.form.page}
                                        lastPage={this.state.products.last_page} />
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }

    extractParams = () => {
        const query = document.location.search; // could be '?foo=bar'
        const urlParams =  new URLSearchParams(decodeURIComponent(query));
        const params = {};
        const keys = Object.keys(this.state.form);
        for(let i=0; i<keys.length; i++) {
            let filter = urlParams.get(keys[i]) ? urlParams.get(keys[i]) : false;
            if (filter) {
                if(keys[i] === "filters")   {
                    params[keys[i]] = JSON.parse(filter);
                } else  {
                    params[keys[i]] = filter;
                }
            }
        }
        return params;
    }

    load = (scroll = false) => {
        seoReset();
        const pathname = document.location.pathname;
        const href = document.location.href;
        const splitArr = pathname.split("/");
        if(splitArr.length === 0) {
            this.setState({...this.state, error404: true, loading: false, query: href});
            return false;
        }
        const path = splitArr[splitArr.length - 1];
        let appendForm = {};
        let category = false;
        if(path === "shop") {
            this.props.setData({id: window.location.href, type: "page", title: "Shop"});
        } else if(path === "ponuda") {
            this.props.setData({id: window.location.href, type: "page", title: "Specijalna ponuda"});
            appendForm["offer"] = true;
        } else if(path === "novo") {
            this.props.setData({id: window.location.href, type: "page", title: "Novo"});
            appendForm["new"] = true;
        } else if(splitArr.length > 4)  { // PRODUCT SINGLE
            const productId = splitArr[splitArr.length - 2];
            this.setState({...this.default, productId: productId, loading: false, query: href});
            return false;
        } else {
            category = unmarshal(this.props.categories).find(item => item.slug === path);
            if(category)    {
                seoTitle(category.seo_title || category.name);
                seoDescription(category.seo_description);
                seoKeywords(category.seo_keywords);
                this.props.setData({id: window.location.href, type: "category", title: category.name});
                appendForm = {categories: getChildrenIds(category) };
            }
        }
        const params = this.extractParams();
        let form = {...this.default, ...params};
        this.setState({...this.state, category: category || false, productId: false, form: form, query: href});
        form = {...form, ...appendForm};

        if(this.props.tracked.length > 0)   {
            form = {...form, tracked: this.props.tracked.slice(0, 5)};
        }
        getProducts(form).then((response) => {
            if(scroll)  {
                this.scrollToTop();
            }
            this.setState({...this.state,
                products: response.data.products,
                categories: response.data.categories,
                filters: response.data.filters,
                loading: false});
        })
    }

    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.query && this.state.query !== document.location.href)    {    // QUERY PARAMS
            this.load();
        }
        else if(this.props.location.pathname !== prevProps.location.pathname) {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
    drawers: state.drawers,
    tracked: state.tracked
});
const mapDispatchToProps = {
    setData: setData,
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Shop);
