import React from "react";
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import Error404 from "../../elements/Error404";
import {getOrderByCode} from "../../../app/hooks";
import Printable from "../../elements/Printable";
import Transaction from "./Transaction";



class OrderStatus extends React.Component {

    state = {
        loading: true,
        order: null
    }

    render = () => {
        if(this.state.loading) {
            return <Loader height={300}/>
        }
        if(!this.state.order) {
            return <Error404 />
        }

        return <div>
            <div className="container mt-4 mb-5 font_2">
                <Transaction order={this.state.order} />
                <Printable order={this.state.order} />
            </div>
        </div>
    };

    componentDidMount() {
        getOrderByCode({code: this.props.match.params.code}).then((orderResponse) => {
            this.setState({...this.state, loading: false, order: orderResponse.data});
        }).catch((error) => {
            this.setState({...this.state, loading: false});
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);
