import React from 'react';
import {Link} from "react-router-dom";
import {getProducts} from "../../app/hooks";
import ProductRender from "../elements/ProductRender";
import {connect} from "react-redux";
import routes from "../../app/routes";
import Title from "../elements/Title";
import slugify from "slugify";

class Carousel extends React.Component {

    state = {
        products: false,
        limit: 5,
        offset: 0,
        full: false,

        title: false,
        url: false
    }

    scroll = (event) => {
        const scrollLeft =  event.target.scrollLeft + event.target.offsetWidth;
        const limit = event.target.firstChild.firstChild.offsetWidth * (this.state.limit - 1);
        if(scrollLeft > limit && !this.state.full)      {
            this.setState({...this.state, full: true});
        }
    }

    title = () => {
        if(!this.state.title) return "";
        return <div className="container mb-3 mt-3">
            <Title name={this.state.url
                ? <Link to={this.state.url}>
                    {this.state.title}
                </Link>
                : this.state.title}
                append={<i className="fa fa-arrow-right d-md-none" />} />
        </div>
    }

    carousel = () => {
        let products = [null, null, null, null, null];
        if(this.state.products) {
            if(this.state.full)     {
                products = this.state.products;
            } else  {
                const offset = this.state.offset*this.state.limit;
                const end = offset + this.state.limit;
                products = this.state.products.slice(offset, end);
            }
        }
        return (
            <div onScroll={this.scroll}  style={{overflowX: "scroll"}} className="pb-2 no_scroll">
                <div className="d-flex align-items-stretch noSwipe">

                    {products.map((item, i) => {
                        return  <div key={item ? item.id : i} className="carousel_item">
                            <ProductRender item={item} />
                        </div>
                    })}

                </div>
            </div>
        )
    }

    left = () => {
        this.setState({...this.state, offset: this.state.offset - 1});
    }
    right = () => {
        this.setState({...this.state, offset: this.state.offset + 1});
    }

    render() {
        const block = this.props.block;
        if(!block.carousel_display)    {
            return "";
        }
        let blockLgClass = "border_rounded";
        let blockSmClass = "d-md-none";
        if(block.special)   {
            blockLgClass = blockLgClass + " border_rounded_primary";
            blockSmClass = blockSmClass + " border_rounded_primary";
        }
        return (
            <div className={this.props.className || ""}>

                <div className="container d-none d-md-block position-relative">
                    <div className={blockLgClass}>
                        {this.title()}
                        <div className="arrows d-none d-md-block">
                            {this.state.offset > 0 &&
                            <button onClick={this.left} className="btn btn-link arrow_left shadow">
                                <i className="fa fa-chevron-left" />
                            </button>}
                            {(((this.state.offset * this.state.limit) + this.state.limit) < this.state.products.length) &&
                            <button onClick={this.right} className="btn btn-link arrow_right shadow">
                                <i className="fa fa-chevron-right" />
                            </button>}
                        </div>
                        {this.carousel()}
                    </div>
                </div>

                <div className={blockSmClass}>
                    {this.title()}
                    {this.carousel()}
                </div>

            </div>
        );
    }

    loadProducts = (categoryId, price, title) => {
        let url = false;
        const category = this.props.categoriesById[categoryId];
        if(category)  {
            title = category.name;
            url = routes.shop.route(categoryId);
        }
        getProducts({category: categoryId, avgPrice: price}).then((response) => {
            this.setState({...this.state, title: title, url: url, products: response.data.products.data});
        });
    }


    load = () => {
        const block = this.props.block;
        if(block.carousel_display === 1)    {
            const trackedId = this.props.trackedId - 1;
            if(!this.props.tracked[trackedId]) {
                this.loadProducts(block.category_id, block.category_price, block.title);
                return;
            }
            const tracked = this.props.tracked[trackedId];
            if(!tracked.category_id)        {
                this.loadProducts(block.category_id, block.category_price, block.title);
                return;
            }
            this.loadProducts(tracked.category_id, tracked.price, block.title);
        }
        if(block.carousel_display === 2)    {
            this.loadProducts(block.category_id, block.category_price, block.title);
        }
        if(block.carousel_display === 3)    {
            const products = block.carousel.map(item => item.product);
            this.setState({...this.state, title: block.title, url: block.title ? routes.recommended.route(slugify(block.title).toLocaleLowerCase()) : "",
                products: products});
        }
    }

    componentDidMount() {
        if(!this.props.block.carousel_display)    {
            return false;
        }
        this.load();
    }
}
const mapStateToProps = state => ({
    categoriesById: state.categoriesById,
    tracked: state.tracked
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
