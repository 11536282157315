import React from 'react';


class SearchButton extends React.Component {

    render() {
        return (
            <button type="submit" className="btn btn-link position-absolute font_2"
                    style={{top: 4, right: 4}}>
                <i className="fa fa-search" />
            </button>
        );
    }

}

export default SearchButton;
