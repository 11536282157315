import React from "react";


class Bag extends React.Component {


    render = () => {
        let spread = {}
        if (this.props.width) {
            spread.width = this.props.width;
        }
        return <svg {...spread} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={this.props.className}>
<g>
	<path d="M41.667,91.584c-3.4,0-6.167-2.767-6.167-6.168c0-3.4,2.766-6.166,6.167-6.166s6.167,2.766,6.167,6.166
		C47.833,88.817,45.067,91.584,41.667,91.584z M41.667,83.25c-1.195,0-2.167,0.972-2.167,2.166c0,1.195,0.972,2.168,2.167,2.168
		s2.167-0.973,2.167-2.168C43.833,84.222,42.861,83.25,41.667,83.25z"/>
</g>
            <g>
	<path d="M75,91.584c-3.4,0-6.166-2.767-6.166-6.168c0-3.4,2.766-6.166,6.166-6.166s6.166,2.766,6.166,6.166
		C81.166,88.817,78.4,91.584,75,91.584z M75,83.25c-1.194,0-2.166,0.972-2.166,2.166c0,1.195,0.972,2.168,2.166,2.168
		s2.166-0.973,2.166-2.168C77.166,84.222,76.194,83.25,75,83.25z"/>
</g>
            <g>
	<path d="M42.292,70.752c-4.863,0-9.101-3.446-10.084-8.269L21.305,12.417H10.417c-1.104,0-2-0.896-2-2s0.896-2,2-2h12.5
		c0.94,0,1.754,0.655,1.954,1.575l3.395,15.588c0.352-0.309,0.813-0.496,1.318-0.496h60c0.596,0,1.161,0.266,1.541,0.725
		c0.38,0.459,0.535,1.064,0.424,1.649l-6.668,35c-1.002,4.915-5.391,8.366-10.337,8.292H42.5
		C42.431,70.751,42.361,70.752,42.292,70.752z M29.01,29l7.111,32.658c0.617,3.023,3.271,5.155,6.339,5.092h32.124
		c3.064,0.061,5.762-2.068,6.372-5.065l6.211-32.601H29.583C29.384,29.083,29.191,29.054,29.01,29z"/>
</g>
</svg>
    }

}

export default Bag;
