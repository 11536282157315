import {SET_CATEGORIES} from "../actions/categoriesActions";


const categoriesReducer = function(state = [], {type, payload})      {
    switch(type)        {
        case SET_CATEGORIES:
            return payload;
        default:
            return state;
    }
}
export default categoriesReducer;