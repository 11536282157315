import {breadcrumbs} from "../providers/categories";
import slugify from "slugify";


var routes = {
    welcome: {route: "/", name: "NASLOVNA"},
    shop: {route: (category_id) => {
         if(!category_id) return "/shop";
         return "/" + breadcrumbs(category_id).map(item => item.slug).join("/");
        }, name: "SHOP"},
    product: {route: (product) => {
        return `${routes.shop.route(product.category_id)}/${product.id}/${slugify(product.name)}`;
    }, name: "PROIZVOD"},
    wishlist: {route: "/omiljeno", name: "OMILJENO"},
    basket: {route: "/korpa", name: "KORPA"},
    thankYou: {route: function(code)  {
        return `/thank-you/${code}`;
    }},
    orderStatus: {route: function(code)  {
        return `/porudzbina/status/${code}`;
    }},
    categories: {route: "/kategorije", name: "KATEGORIJE"},
    brands: {route: "/brendovi", name: "BRENDOVI"},
    offer: {route: "/ponuda?offer=true", name: "AKCIJA"},
    recommended: {route: (slug) => {
        if(!slug) return "/preporuceno";
            return `/preporuceno/${slug}`;
        }, name: "PREPORUČENO"},
    new: {route: "/novo?new=true", name: "NOVO"},

    login: {route: "/nalog", name: "NALOG"},
    register: {route: "/register", name: "REGISTRUJTE SE"},
    password_recovery: {route: "/password/recovery", name: ""},
    password_reset: {route: "/password/reset", name: ""},

    dashboard: {route: "/dashboard", name: "DASHBOARD"},
    orders: {route: "/porudzbine", name: "PORUDŽBINE"},
    order: {route: (id) => { return `/porudzbina/${id}`; }, name: "PORUDŽBINA"},
    newsletter: {route: "/newsletter", name: "NEWSLETTER"},

    faq: [
        {route: "/o-nama", name: "O nama"},
        {route: "/opsti-uslovi-poslovanja", name: "Opšti uslovi poslovanja"},
        {route: "/saobraznost-i-reklamacije", name: "Saobraznost i reklamacije"},
        {route: "/politika-privatnosti", name: "Politika privatnosti"},
        {route: "/politika-kolacica", name: "Politika kolačića"},
        {route: "/kontaktiraje-nas", name: "Kontaktiraje nas"},
        {route: "/cesto-postavljana-pitanja", name: "Često postavljana pitanja"},

    ]
}

/*O nama
Opšti uslovi poslovanja
Saobraznost i reklamacije
Politika privatnosti
Politika kolačića (ovo prekopirati staro)
Kontaktiraje nas (napraviti polja sa podacima gde mogu da nas kupci pitaju)
Često postavljana pitanja*/

export default routes;