import {SET_USER, UPDATE_USER} from "../actions/userActions";
import {checkUser} from "../../providers/userProvider";

const userReducer = function(state = checkUser(), {type, payload})      {
    switch(type)        {
        case UPDATE_USER:
            return {...state, [payload.field]: payload.value};
        case SET_USER:
            return payload;
        default:
            return state;
    }
}
export default userReducer;