import React from "react";
import {connect} from "react-redux";
import Modal from "../../framework/Modal";
import {image} from "../../../app/helpers";
import {postAvailability} from "../../../app/hooks";
import * as serviceWorker from "../../../app/serviceWorker";


class ProductAvailability extends React.Component   {

    default = {
        product_id: this.props.item.id,
        poruka: "Možete li da mi proverite dostupnost proizvoda?",
        email_active: true,
        email: this.props.user?.email ? this.props.user?.email : "",
        notification_active: false,
        endpoint: false
    }
    state = {
        open: false,
        pushManager: false,
        permission: true,

        form: this.default,
        success: false
    }

    open = () => {
        this.setState({...this.state, open: true});
    }
    close = () => {
        this.setState({...this.state, open: false});
    }
    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    }


    urlB64ToUint8Array = base64String => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    };

    subscribe = () => {
        return serviceWorker.register().then(registration => {
            const applicationServerKey = this.urlB64ToUint8Array(
                'BLPiQkaggRTQRyiu4csmBR4fhkJLKao_4LgwdVMoeiyUeanIA0-4tVa39ToTxol1Se2ImJx_ZX9nv0gI65fH99E'
            );
            const options = {applicationServerKey, userVisibleOnly: true};
            return registration.pushManager.subscribe(options);
        });
    }

    registerForNotification = async (e) => {
        let checked = e.target.checked;
        if(!checked)    {
            this.setState({...this.state, form: {...this.state.form, notification_active: false}});
            return;
        }
        if (window.Notification.permission !== 'granted') {
            this.setState({...this.state, permission: false});
            const permission = await window.Notification.requestPermission();
            if (permission !== 'granted') {
                this.setState({...this.state, permission: "denied"});
                return false;
            }
        }

        this.subscribe().then(pushSubscription => {
            this.setState({...this.state, permission: true,
                form: {...this.state.form, notification_active: true, endpoint: pushSubscription}});
        });

    }

    submit = (e) => {
        e.preventDefault();
        postAvailability(this.state.form).then((response) => {
            this.setState({...this.state, success: true, form: this.default});
            setTimeout(() => {
                this.setState({...this.state, open: false, success: false});
            }, 2000);
        })
    }

    render = () => {
        const item = this.props.item;
        return <div>
            <Modal reload={true} width={660} zIndex={100} show={this.state.open} close={this.close}>
                {item &&
                <div className="d-flex align-items-center mb-3 border-bottom pb-2">
                    <div className="mr-2" style={{flex: "0 0 70px"}}>
                        <img src={image(item.image_path)} alt={item.name} className="img-fluid" />
                    </div>
                    <div>
                        <div className="weight_500">{item?.name}</div>
                    </div>
                </div>}

                <div className="weight_500 mb-3 font_2"> Pošaljite nam upit i odgovorićemo u najkraćem mogućem roku. </div>

                <form onSubmit={this.submit}>
                    <div className="p-2 bg-light mb-3">
                        <div className="weight_500"> Vaša poruka: </div>
                        <textarea onChange={this.changeField} value={this.state.form.poruka}
                                  name="poruka" className="form-control" required />
                    </div>

                    <div className="weight_500 font_2"> Primanje odgovora: </div>

                    <div className="p-2 bg-light mb-3">
                        <div className="d-flex align-items-center">
                            <label className="chbx mr-1">
                                <input onChange={(e) =>
                                    this.setState({...this.state, form: {...this.state.form, email_active: e.target.checked}})}
                                       type="checkbox" value={1} checked={this.state.form.email_active} /><span />
                            </label>

                            <input onChange={this.changeField} value={this.state.form.email}
                                   type="email" className="form-control mb-2" name="email" placeholder="Putem email-a (Vaš email)"
                                   disabled={!this.state.form.email_active} required />
                        </div>

                        <div>
                            {this.state.pushManager &&
                            <div>
                                <label className="chbx d-flex align-items-baseline">
                                    <span>
                                        <input onChange={this.registerForNotification}
                                               type="checkbox" value={1} checked={this.state.form.notification_active} /><span />
                                    </span>

                                    <span className="ml-2 weight_500">
                                    Putem notifikacije <br/>
                                        <span className="text-black-50 font-weight-normal font-italic font_09">
                                            Vaš pretraživač podržava Web Push notifikacije.
                                            Primajte odgovor direktno na uredjaj.
                                            Nije potrebno registrovanje.
                                        </span>
                                    </span>
                                </label>
                            </div>}

                            {!this.state.permission &&
                            <div className="font_09">
                                Morate odobriti primanje notifikacija.
                            </div>}
                            {this.state.permission === "denied" &&
                            <div className="font_1 text-danger weight_500">
                                Primanje notifikacija nije odobreno kod vas u pretraživaču.
                            </div>}

                            {!this.state.form.notification_active && !this.state.form.email_active &&
                            <div className="text-danger">
                                Morate čekirati jednu opciju (ili obe) za primanje odgovora. <br />
                                (email i/ili notifikacija)
                            </div>}
                        </div>
                    </div>

                {this.state.success &&
                <div className="alert alert-success mt-3"> Upit uspešno poslat. </div>}

                  <button className="btn btn-primary mr-2"
                          disabled={!this.state.form.notification_active && !this.state.form.email_active}>
                      Pošaljite upit
                  </button>
                    <button onClick={this.close} className="btn btn-outline-primary">
                            Zatvorite
                    </button>
                </form>

            </Modal>

            <button onClick={this.open} className="btn btn-info">
                <i className="fa fa-check" /> Proverite dostupnost
            </button>
        </div>
    }

    componentDidMount() {
        let pushManager = true;
        if (!('serviceWorker' in navigator)) {
            pushManager = false;
        }
        if (!('PushManager' in window)) {
            pushManager = false;
        }
        if (!pushManager) {
            return false;
        }
        this.setState({...this.state, pushManager: true});
    }
}
const mapStateToProps = state => ({
    user: state.user
});
export default connect(mapStateToProps)(ProductAvailability);
