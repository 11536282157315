import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import {image} from "../../../app/helpers";
import Title from "../../elements/Title";


class Categories extends React.Component {

    state = {
        search: ""
    }

    render() {
        return (
            <div className="container mt-5 mb-5">

                <div>
                    {this.props.categories.map((category) => {
                        return <div key={category.id} className="mb-4">
                            <div className="mb-3">
                                <Link to={routes.shop.route(category.id)}>
                                    <Title name={category.name}
                                   nameAppend={<span className="font-weight-normal font_09 text-black-50">({category.count})</span>} />
                                </Link>
                            </div>

                            <div className="row">
                                {category.children.map((cat1) => {
                                    return <div className="mb-3 col-md-3" key={cat1.id}>
                                        <div className="border_rounded p-2">
                                            {cat1.image_path &&
                                            <div className="mb-2">
                                            <img src={image(cat1.image_path, false, cat1.image_bucket)} alt={cat1.name} className="img-fluid" />
                                            </div>}

                                            <div>
                                                <Link to={routes.shop.route(cat1.id)} className="link weight_500 font_2">
                                                    {cat1.name}
                                                    <span className="ml-1 font-weight-normal font_09 text-black-50">({cat1.count})</span>
                                                </Link>
                                            </div>

                                            {cat1.children.map((cat2) => {
                                                return <div key={cat2.id}>
                                                    <Link key={cat2.id} className="link" to={routes.shop.route(cat2.id)}>
                                                        {cat2.name}
                                                        <span className="ml-1 font-weight-normal font_09 text-black-50">({cat2.count})</span>
                                                    </Link>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        );
    }
    componentDidMount() {

    }
}
const mapStateToProps = state => ({
    categories: state.categories
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
