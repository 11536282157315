export const SET_TRACKED = "SET_TRACKED";
export const ADD_TO_TRACKED = "ADD_TO_TRACKED";
export const REMOVE_FROM_TRACKED = "REMOVE_FROM_TRACKED";

export function setTracked(value) {
    return {
        type: SET_TRACKED,
        payload: value
    }
}

export function addToTracked(value) {
    return {
        type: ADD_TO_TRACKED,
        payload: value
    }
}
export function removeFromTracked(value) {
    return {
        type: REMOVE_FROM_TRACKED,
        payload: value
    }
}