import { combineReducers, createStore } from "redux";

import drawers from "./reducers/drawersReducer";
import categories from "./reducers/categoriesReducer";
import categoriesById from "./reducers/categoriesByIdReducer";
import wishlist from "./reducers/wishlistReducer";
import basket from "./reducers/basketReducer";
import data from "./reducers/dataReducer";
import currency from "./reducers/currencyReducer";
import user from "../store/reducers/userReducer";
import tracked from "../store/reducers/trackedReducer";




const store = createStore(combineReducers({
    drawers: drawers,
    categories: categories,
    categoriesById: categoriesById,
    wishlist: wishlist,
    basket: basket,
    data: data,
    currency: currency,
    user: user,
    tracked: tracked,
}));

export default store;