import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {setUser} from "../../../../store/actions/userActions";
import {loginUser} from "../../../../providers/userProvider";
import routes from "../../../../app/routes";
import {postLoginUser} from "../../../../app/hooks";
import Google from "./Google";
import Facebook from "./Facebook";

//const Google = React.lazy(() => import("./Google"));
//const Facebook = React.lazy(() => import("./Facebook"));


class LoginForm extends React.Component {

    form = {
        email: "",
        password: "",
        remember: false
    }
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}});
        postLoginUser(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, success: true, error: {}});
            this.login(response);
        }).catch((error) => {
            this.error(error);
        });
    };

    login = (response) => {
        let user = loginUser(response, this.state.form.remember);
        if(user) {
            this.props.setUser(user);
            window.location.reload();
        }
    };

    error = (error) => {
        this.setState({...this.state, loading: false, error: error, success: false});
    };


    render = () => {
        let form = this.state.form;
        return <div>
                <form action="#" method="POST" onSubmit={this.submit}>
                    <div>
                        <input
                            onChange={this.changeField}
                            name="email"
                            value={form.email}
                            placeholder="Email"
                            type="email"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.email &&
                        <div className="alert alert-danger">{this.state.error.email}</div>}
                    </div>

                    <div>
                    <input
                        type="password"
                        onChange={this.changeField}
                        name="password"
                        value={form.password}
                        placeholder="Lozinka"
                        className="form-control mb-3 form-control-lg"
                        required={true}
                    />
                        {this.state.error.password &&
                        <div className="alert alert-danger">{this.state.error.password}</div>}
                    </div>

                    <label>
                        <input
                            type="checkbox"
                            checked={form.remember}
                            onChange={(e) => this.setState({...this.state, form: {...this.state.form, remember: e.target.checked}})}
                            value="1"
                            className="mr-2 font_2"
                        />
                        Zapamti me?
                    </label>
                    <button type="submit" disabled={this.state.loading} className="btn btn-lg btn-block btn-primary mb-3">
                        Ulogujte se
                    </button>
                </form>

            <div>
                <Google login={this.login} error={this.error} />
                <Facebook login={this.login} error={this.error} />
            </div>

            {this.state.error.message &&
            <div className="alert alert-danger mb-3">
                {this.state.error.message}
            </div>}

            {this.state.success &&
            <div className="alert alert-success mb-3">
                Uspešno ste ulogovani.
            </div>}

                <div className="font_1 mb-3">
                    <Link to={routes.register.route}>
                        Registrujte nalog?
                    </Link>
                </div>
                <div className="font_1">
                    <Link to={routes.password_recovery.route}>
                        Zaboravili ste lozinku?
                    </Link>
                </div>
            </div>
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);