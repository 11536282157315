import {ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST} from "../actions/wishlistActions";
import {loadWishlist} from "../../providers/wishlist";

const wishlistReducer = function(state = loadWishlist(), {type, payload})      {
    switch(type)        {
        case ADD_TO_WISHLIST:
            if(state.includes(payload)) {
                return state;
            }
            return [...state, payload];
        case REMOVE_FROM_WISHLIST:
            return state.filter(item => item !== payload);
        default:
            return state;
    }
}
export default wishlistReducer;