import React from "react";
import BankLogos from "../../layouts/BankLogos";
import routes from "../../../app/routes";
import {randString, server} from "../../../app/helpers";
import {emptyBasket} from "../../../store/actions/basketActions";
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import Title from "../../elements/Title";
import FormatPrice from "../../elements/FormatPrice";
import {prepareCheckout, putOrder} from "../../../app/hooks";


class CardDrawer extends React.Component {

    state = {
        loading: true,
        checkout: undefined,
        code: randString(8).toUpperCase(),
        agreed: false,
        agreedAlert: false,
        saved: false
    }

    agreed = (e) => {
        if(e.target.checked && !this.state.saved)       {
            let basket = this.props.basket.map((item) => {
                return {id: item.id, qty: item.qty, filters: item.filters}
            });
            let form = {...this.props.form, basket: basket, code: this.state.code, checkout: this.state.checkout.id};
            this.setState({...this.state, agreed: e.target.checked});
            if(this.props.user)     {
                form.user_id = this.props.user.id;
            }
            putOrder(form).then((response) => {
                this.setState({...this.state, saved: true});
            }).catch((errors) => {
                this.props.setErrors(errors);
                this.props.close();
            });
            return;
        }
        this.setState({...this.state, agreed: e.target.checked});
    }

    render = () => {
        const form = this.props.form;
        if(this.state.loading)
               return <Loader height={300} />
        if(!this.state.checkout)
            return <div className="text-center font_3 mt-5">Pogresan kod.</div>
        if(!this.state.checkout.id)
            return <div className="text-center font_3 mt-5">{this.state.checkout.result.description}</div>

        return <div className="row m-0">
            <div className="col-12 mb-3">
                <div className="order_banks text-center">
                    <BankLogos />
                </div>
            </div>

            <div className="col-md-6">
                <div className="mb-3 m-md-3">
                    <Title name="Vaše informacije" />
                    {form.firm_name &&
                    <div className="mt-3">
                        <div className="mb-3">
                            <div className="">Firma:</div>
                            <div className="font_2 weight_500">
                                {form.firm_name}
                            </div>
                        </div>
                        <div className="">
                            <div className="">PIB:</div>
                            <div className="font_2 weight_500">
                                {form.firm_pib}
                            </div>
                        </div>
                    </div>}

                    <div className="mt-3 mb-3">
                        <div className="">Ime i prezime:</div>
                        <div className="font_2 weight_500">
                            {form.name} {form.lastname}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="">Email:</div>
                        <div className="font_2 weight_500">
                            {form.email}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="">Telefon:</div>
                        <div className="font_2 weight_500">
                            {form.phone}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="">Adresa:</div>
                        <div className="font_2 weight_500">
                            {form.address}, {form.zip} {form.city}
                        </div>
                    </div>
                    {form.message &&
                    <div className="mb-3">
                        <div className="">Dodatna poruka:</div>
                        <div className="font_2 weight_500 pre_wrap">{form.message}</div>
                    </div>}
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3 m-md-3">
                    <Title name="Porudžbina" />
                    <table className="mt-3 mb-4 table table-bordered">
                        <tbody>
                            <tr>
                                <td>Broj porudžbine:</td>
                                <td><div className="font_2 weight_500"> {this.state.code} </div></td>
                            </tr>
                            <tr>
                                <td>Trgovac:</td>
                                <td><div className="font_2 weight_500">GOGORILA DOO Čačak</div></td>
                            </tr>
                            <tr>
                                <td>Iznos za plaćanje:</td>
                                <td><div className="font_2 weight_500">
                                    <FormatPrice price={this.props.fullPrice} />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className={(this.state.agreedAlert && !this.state.agreed) ? "checkout_tos text-center p-2 mb-2" : "text-center p-2 mb-2"}>
                        <label className="font_2 mb-0 font-weight-bold">
                            <input type="checkbox" onChange={this.agreed}
                                   checked={this.state.agreed} /> &nbsp; Slažem se sa &nbsp;
                            <span className="d-none d-md-inline-block">
                                <a href={routes.faq[1].route} style={{color: "#d40000"}} target="popup"
                                   onClick={() => window.open(routes.faq[1].route,'name','width=600,height=600')}>
                                    uslovima
                                </a>
                            </span>
                            <span className="d-md-none">
                               <a href={routes.text2} rel="noopener noreferrer" style={{color: "#ff5300"}} target="_blank">
                                   uslovima
                               </a>
                            </span>
                            &nbsp; poslovanja
                        </label>
                        {this.state.agreedAlert && !this.state.agreed &&
                        <div className="red font_2">Morate čekirati da se slažete sa uslovima plaćanja.</div>}
                    </div>

                    <div className="position-relative">
                        <div className={this.state.agreed ? "none" : "checkout_prevent"}
                             onClick={() =>  this.setState({...this.state, agreedAlert: true})} />

                        <form action={server("order/checkout")} className="paymentWidgets" data-brands="VISA MASTER MAESTRO">
                            <Loader height={150} />
                        </form>

                    </div>
                </div>
            </div>
        </div>
    };

    componentDidMount() {
        this.setState({...this.state, loading: true});
        prepareCheckout({amount: this.props.fullPrice}).then((response) => {
            if(!response.data.id)     {
                this.setState({...this.state, loading: false, checkout: response.data});
                return false;
            }
            window.wpwlOptions = {
                labels:
                    { cardHolder: "Ime i prezime",
                        cvv: "CVV kod",
                        cardNumber:"Broj kartice",
                        expiryDate:"Datum isteka",
                        cvvHint: "Tri broja sa poleđine kartice",
                        givenName: "Vaše ime",
                        surname: "Vaše prezime",
                        submit: "Platite"
                    },
                //style: "plain",
                forceCardHolderEqualsBillingName: true,
                showCVVHint: true,
                brandDetection: true,

            }
            const script = document.createElement("script");
            script.src = "https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=" + response.data.id;
            script.async = true;
            script.onload = () => {
                this.setState({...this.state, loading: false, checkout: response.data})
            };
            document.body.appendChild(script);
        });
    }
}
const mapStateToProps = state => ({
    user: state.user,
    basket: state.basket
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(CardDrawer);
