import React from "react";
import unicredit from "../../assets/images/unicredit.png";
import visa from "../../assets/images/visa.png";
import master from "../../assets/images/master.png";
import allSecure from "../../assets/images/all-secure.png";
import mcSc from "../../assets/images/mc-sc.png";
import vbv from "../../assets/images/vbv.png";
import maestro from "../../assets/images/maestro.png";


class BankLogos extends React.Component {

    render = () => {
        return <div>
            <a href='https://www.unicreditbank.rs/rs/' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={unicredit} className='img-fluid' alt="Unicredit bank" />
            </a>
            <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={visa} className='img-fluid' alt="Visa" />
            </a>
            <a href='https://www.mastercard.us/en-us/merchants/safety-security/securecode.html'rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={master} className='img-fluid' alt="Mastercard" />
            </a>
            <a href='https://www.allsecure.rs/' className='d-inline-block' rel="noopener noreferrer" target="_blank">
                <img src={allSecure} className='img-fluid' alt="All Secure" />
            </a>
            <a href='https://www.mastercard.us/en-us/merchants/safety-security/securecode.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={mcSc} className='img-fluid' alt="Mastercard safety-security" />
            </a>
            <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={vbv} className='img-fluid' alt="Visa verified" />
            </a>
            <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                <img src={maestro} className='img-fluid' alt="Maestro" />
            </a>
        </div>
    };
}

export default BankLogos;
