import React from "react";
import {connect} from "react-redux";
import {serverIO} from "../../app/helpers";

class Socket extends React.Component {

    socket = null;

    get_uid()    {
        return localStorage.getItem("uid", false);
    }
    set_uid(uid)      {
        localStorage.setItem("uid", uid);
    }

    render = () => {
        return ""
    };

    pong = () => {
        if(this.props.data) {
            this.socket.emit("pong", {data: this.props.data, uid: this.get_uid()});
        }
    }

    componentDidMount() {
        this.socket = window.io(serverIO());
        this.socket.on("connect", () => {
            const data = {};
            const uid = this.get_uid();
            if(uid)     {
                data["uid"] = uid;
            }
            this.socket.emit("join", data);
        });
        this.socket.on("joined", (data) => {
            if("uid" in data)  {
                this.set_uid(data["uid"]);
            }
        });
        this.socket.on("ping", () => {
            this.pong();
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && this.props.data.id !== prevProps.data.id)    {
            this.pong();
        }
    }
}
const mapStateToProps = state => ({
    data: state.data
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Socket);
