import React from 'react';
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import FiltersBlock from "./FiltersBlock";


class Filters extends React.Component {

    route = (e, route) => {
        e.preventDefault();
        this.props.newRoute(route);
    }

    state = {
        filters: false
    }

    toggleFilter = (filter) => {
        if(!filter.parent_filter_id) return;
        const parentId = parseInt(filter.parent_filter_id);
        const filterId = parseInt(filter.filter_id);

        if(!this.props.selected[parentId])     {
            let newState = {...this.props.selected, [parentId]: [filterId]};
            this.props.editField({target: {name: "filters", value: newState}})
            return false;
        }
        var index = this.props.selected[parentId].indexOf(filterId);
        if(index === -1) {
            let newState = {...this.props.selected, [parentId]: [...this.props.selected[parentId], filterId]};
            this.props.editField({target: {name: "filters", value: newState}})
            return false;
        }
        if(this.props.selected[parentId].length === 1) {
            let holder = {};
            for(let key in this.props.selected)  {
                if(parentId !== parseInt(key))   {
                    holder[key] = this.props.selected[key];
                }
            }
            this.props.editField({target: {name: "filters", value: holder}})
            return false;
        }
        let newFilters = this.props.selected[parentId].filter(item => item !== filterId);
        let newState = {...this.props.selected, [parentId]: newFilters};
        this.props.editField({target: {name: "filters", value: newState}})
    }


    filters = () => {
        let holder = [];
        for(let key in this.state.filters)  {
            const items = this.state.filters[key]
            holder.push(<div key={key} className="mb-2">
                <div className="font-weight-bold mb-1">
                    {key}
                </div>
                <FiltersBlock
                    toggleFilter={this.toggleFilter}
                    items={items}
                    selected={this.props.selected} />
            </div>)
        }
        return holder;
    }

    render() {
        if(!this.state.filters)  {
            return <Loader height={100} opacity={0.1} />
        }
        return (
            <div className="">
                {this.filters()}
            </div>
        );
    }
    load = () => {
        if(!this.props.filters || this.props.filters.length === 0) {
            this.setState({...this.state, filters: false});
        }
        let holder = {};
        for(let i=0; i<this.props.filters.length; i++)   {
            const item = this.props.filters[i];
            if(!holder[item.parent_filter_name])   {
                holder[item.parent_filter_name] =  [];
            }
            holder[item.parent_filter_name].push(item);
        }
        this.setState({...this.state, filters: holder});
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.filters?.length !== prevProps.filters?.length)    {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
    categoriesById: state.categoriesById
});
export default connect(mapStateToProps)(Filters);
