export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

export function addToWishlist(value) {
    return {
        type: ADD_TO_WISHLIST,
        payload: value
    }
}

export function removeFromWishlist(value) {
    return {
        type: REMOVE_FROM_WISHLIST,
        payload: value
    }
}
