import React from 'react';
import {connect} from "react-redux";
import {closeDrawer} from "../../store/actions/drawersActions";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import logo from "../../assets/images/logo.png";
import {image} from "../../app/helpers";
import Title from "../elements/Title";
import {Swipeable} from "react-swipeable";
import {removeFromTracked} from "../../store/actions/trackedActions";



class CategoriesDrawer extends React.Component   {

    state = {
        selectedCategoryId: false,
        openRightPanel: false
    }

    closeDrawer = () => {
        this.props.closeDrawer("categories");
    }

    openCategory = (e, categoryId) => {
        e.preventDefault();
        this.setState({...this.state, selectedCategoryId: categoryId, openRightPanel: true});
    }
    closeRightPanel = () => {
        this.setState({...this.state, openRightPanel: false});
    }

    removeTracked = (categoryId) => {
        this.props.removeFromTracked(categoryId);
    }

    render = () => {
        let category = this.state.selectedCategoryId ? this.props.categoriesById[this.state.selectedCategoryId] : this.props.categories[0];
        return <div className="d-flex h-100 categories_drawer">
            <div className="transparency_block p-1 text-right">
                {this.state.openRightPanel
                    ? <button onClick={this.closeRightPanel}
                              className="btn btn-primary btn-sm d-md-none">
                        <i className="fa fa-chevron-left font_06 mr-1" /> nazad
                    </button>
                    : <button onClick={this.closeDrawer} className="btn btn-danger btn-sm d-md-none">
                       <i className="fa fa-chevron-left font_06 mr-1" /> zatvorite
                    </button>}
            </div>
            <div className={`${this.state.openRightPanel ? "open" : ""} categories_left_panel weight_500 pb-5`}>
                <div className="text-center border-bottom mt-3 pb-4">
                    <img src={logo} className="img-fluid" width={200} alt="GoGorila" />
                </div>

                {this.props.tracked.length > 0 &&
                <div className="border-bottom">
                    <div className="font-weight-normal text-black-50 pl-3 pt-2">
                        Vaše kategorije:
                    </div>

                    {this.props.tracked.map((item, index) => {
                        if(index > 4) return "";
                        const category = this.props.categoriesById[item.category_id];
                        return <div className="d-flex align-items-center" key={item.category_id}>
                            <button onClick={() => this.removeTracked(item.category_id)}
                                className="btn btn-link btn-sm p-1 ml-2" title="Prestanite sa praćenjem.">
                               <i className="fas fa-minus-circle" />
                            </button>
                            <Link onClick={this.closeDrawer} to={routes.shop.route(item.category_id)}
                                  className="link pr-3 pl-2 flex-grow-1">
                                {category.name}
                                <span className="ml-1 font-weight-normal font_09 text-black-50">({category.count})</span>
                            </Link>
                        </div>
                    })}
                </div>}

                {this.props.categories.map((item) => {
                    if(parseInt(item.count) === 0)  {
                        return "";
                    }
                    return <div key={item.id}>
                        <Link onClick={this.closeDrawer} to={routes.shop.route(item.id)}
                              onMouseOver={() => this.setState({...this.state, selectedCategoryId: item.id})}
                              className="link pl-3 pr-3 d-none d-md-block justify-content-between align-items-center">
                            {item.name}
                            <span className="ml-1 font-weight-normal font_09 text-black-50">({item.count})</span>
                        </Link>
                        <Link onClick={(e) => this.openCategory(e, item.id)} to={routes.shop.route(item.id)}
                              className="link pl-3 pr-3 d-flex d-md-none justify-content-between align-items-center">
                            <div>
                                {item.name}
                                <span className="ml-1 font-weight-normal font_09 text-black-50">({item.count})</span>
                            </div>

                            <i className="fa fa-chevron-right font_09 text-black-50" />
                        </Link>
                    </div>
                })}
            </div>

            <div className="categories_right_panel flex-grow-1 pt-4 pb-5">
                <Swipeable onSwipedRight={this.closeRightPanel}>
                    <div className="row m-0">
                        {category.children.map((cat1) => {
                            if(parseInt(cat1.count) === 0)  {
                                return "";
                            }
                            return <div className="mb-4 col-md-3" key={cat1.id}>
                                <div>
                                    {cat1.image_path &&
                                    <div className="mb-4 d-none d-md-block">
                                        <img src={image(cat1.image_path, false, cat1.image_bucket)} alt={cat1.name} className="img-fluid" />
                                    </div>}

                                    <div>
                                        <Link onClick={this.closeDrawer} to={routes.shop.route(cat1.id)} className="weight_500">
                                            <Title name={cat1.name} font={2}
                                           nameAppend={<span className="font-weight-normal font_09 text-black-50">({cat1.count})</span>} />
                                        </Link>
                                    </div>

                                    {cat1.children.length > 0 &&
                                    <div className="mt-3">
                                        {cat1.children.map((cat2) => {
                                            if(parseInt(cat2.count) === 0)  {
                                                return "";
                                            }
                                            return <div key={cat2.id}>
                                                <Link onClick={this.closeDrawer}
                                                      key={cat2.id} className="link weight_500" to={routes.shop.route(cat2.id)}>
                                                    {cat2.name}
                                                    <span className="ml-1 font-weight-normal font_09 text-black-50">({cat2.count})</span>
                                                </Link>
                                            </div>
                                        })}
                                    </div>}
                                </div>
                            </div>
                        })}
                    </div>
                </Swipeable>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    categories: state.categories,
    categoriesById: state.categoriesById,
    tracked: state.tracked
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer,
    removeFromTracked: removeFromTracked
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDrawer);
