import React from "react";
import  {Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import routes from "../../../app/routes";
import {deleteFromBasket, emptyBasket, setBasket} from "../../../store/actions/basketActions";
import {Link} from "react-router-dom";
import {processPrice} from "../../../providers/price";
import {paying_method} from "../../../providers/order";
import BankLogos from "../../layouts/BankLogos";
import {getUserInfo, putOrder} from "../../../app/hooks";
import {image} from "../../../app/helpers";
import Qty from "../../elements/Qty";
import FormatPrice from "../../elements/FormatPrice";
import Drawer from "../../framework/Drawer";
import CardDrawer from "./CardDrawer";
import Title from "../../elements/Title";


class OrderForm extends React.Component {

    default = {
        tab: 1,

        name: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        zip: "",
        city: "",
        message: "",
        payment_method: "1",

        firm_name: "",
        firm_pib: "",
    }
    state = {
        redirectCode: false,
        form: this.default,
        preparingSubmit: 0,
        errors: {},
        cardDrawer: false,
        checked: false,
        checkedError: false,
    };

    change = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let newState = {...this.state, form: {...this.state.form, [field]: value}};
        this.setState(newState);
    }

    startSubmit = (e) => {
        e.preventDefault();
        if(this.state.form.payment_method === "3")       {
            this.setState({...this.state, cardDrawer: true});
            return false;
        }
        this.startSubmitLoop(4);
    }
    stopSubmit = (e) => {
        e.preventDefault();
        if(this.startSubmitTimer)   {
            window.clearTimeout( this.startSubmitTimer );
        }
        this.startSubmitTimer = false;
        this.setState({...this.state, preparingSubmit: 0});
    }
    startSubmitTimer = false;
    startSubmitLoop = (timer) => {
        this.setState({...this.state, preparingSubmit: timer - 1});
        if(timer === 1) {
            if(this.startSubmitTimer)   {
                window.clearTimeout( this.startSubmitTimer );
                this.startSubmitTimer = false;
                this.submit();
            }
            return false;
        }
        this.startSubmitTimer = setTimeout(() => {
            this.startSubmitLoop(timer - 1);
        }, 1000);
    }

    submit = (e) => {
        e.preventDefault();
        if(!this.state.checked) {
            this.setState({...this.state, checkedError: true});
            return false;
        }
        if(this.state.form.payment_method === "3")       {
            this.setState({...this.state, cardDrawer: true});
            return false;
        }

        let basket = this.props.basket.map((item) => {
            return {id: item.id, qty: item.qty, filters: item.filters}
        });
        let form = {...this.state.form, basket: basket};
        if(this.props.user)     {
            form.user_id = this.props.user.id;
        }
        this.setState({...this.state, loading: true});
        putOrder(form).then((response) => {
            this.props.emptyBasket();
            this.setState({...this.state, loading: false, form: this.default, redirectCode: response.data.code});
        }).catch((errors) => {
            this.setState({...this.state, loading: false, errors: errors});
        });
    }

    setErrors = (errors) => {
        this.setState({...this.state, loading: false, errors: errors});
    }

    error = (name) => {
        if(this.state.errors[name]) {
            return <div className="alert alert-danger prewrap">{this.state.errors[name]}</div>
        }
    }

    checked = (e) => {
        this.setState({...this.state, checked: e.target.checked, checkedError: !e.target.checked});
    }

    render = () => {
        if(this.state.redirectCode) {
            return <Redirect to={routes.thankYou.route(this.state.redirectCode)}  />
        }
        let form = this.state.form;
        let total = 0;
        this.props.products.map((item) => {
            let fullPrice = processPrice(item.product) * item.qty;
            total += fullPrice;
            return "";
        });

        return <div>
            <Drawer width="80%" reload={true} show={this.state.cardDrawer}
                    close={() => this.setState({...this.state, cardDrawer: false})}>
                <CardDrawer
                    form={form}
                    setErrors={this.setErrors}
                    fullPrice={total}
                    close={() => this.setState({...this.state, cardDrawer: false})} />
            </Drawer>

            <div className="mb-5">
                <form action="/" method="POST" onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Title font="2" name="Informacije za poručivanje" />

                            <div className="text-primary font-italic font_08 mb-3 mt-2">
                                Polja označena zvezdicom (*) su obavezna.
                            </div>

                            <div className="weight_500">
                                <button onClick={() => this.setState({...this.state, form: {...this.state.form, tab: 1}})}
                                        type="button" className={`btn ${form.tab === 1 ? 'btn-light' : 'btn-link'}`}>
                                    fizičko lice
                                </button>
                                <button  onClick={() => this.setState({...this.state, form: {...this.state.form, tab: 2}})}
                                    type="button" className={`btn ${form.tab === 2 ? 'btn-light' : 'btn-link'}`}>
                                    pravno lice
                                </button>
                            </div>
                            {form.tab === 1 &&
                            <div className="bg-light p-2">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Ime: <span className="text-primary">*</span><br />
                                            <input
                                                name="name" value={form.name || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                            {this.error("name")}
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Prezime: <span className="text-primary">*</span><br />
                                            <input
                                                name="lastname" value={form.lastname || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Email: <span className="text-primary">*</span><br />
                                            <input
                                                name="email" value={form.email || ""} onChange={this.change}
                                                type="email" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Telefon: <span className="text-primary">*</span><br />
                                            <input
                                                name="phone" value={form.phone || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                </div>

                                <label className="w-100 mb-3">
                                    Adresa: <span className="text-primary">*</span><br />
                                    <input
                                        name="address" value={form.address || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>

                                <div className="row mb-3">
                                    <div className="col-5 col-md-4 pr-0">
                                        <label className="w-100">
                                            Poštanski br<span className="d-none d-md-inline-block">oj</span>: <span className="primary">*</span><br />
                                            <input
                                                name="zip" value={form.zip || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-7 col-md-8">
                                        <label className="w-100">
                                            Grad: <span className="text-primary">*</span><br />
                                            <input
                                                name="city" value={form.city || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                </div>

                                <label className="w-100">
                                    Dodatna poruka:<br />
                                    <textarea
                                        name="message" value={form.message || ""} onChange={this.change}
                                        rows={4} className="form-control" />
                                </label>
                            </div>}
                            {form.tab === 2 &&
                            <div className="bg-light p-2">
                                <label className="w-100 mb-3">
                                    Naziv firme: <span className="text-primary">*</span><br />
                                    <input
                                        name="firm_name" value={form.firm_name || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                    {this.error("firm_name")}
                                </label>

                                <label className="w-100 mb-3">
                                    PIB: <span className="text-primary">*</span><br />
                                    <input
                                        name="firm_pib" value={form.firm_pib || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                    {this.error("firm_pib")}
                                </label>

                                <label className="w-100 mb-3">
                                    Adresa: <span className="text-primary">*</span><br />
                                    <input
                                        name="address" value={form.address || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>

                                <div className="row mb-3">
                                    <div className="col-5 col-md-4 pr-0">
                                        <label className="w-100">
                                            Poštanski br<span className="d-none d-md-inline-block">oj</span>: <span className="primary">*</span><br />
                                            <input
                                                name="zip" value={form.zip || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-7 col-md-8">
                                        <label className="w-100">
                                            Grad: <span className="text-primary">*</span><br />
                                            <input
                                                name="city" value={form.city || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Kontakt osoba (ime): <span className="text-primary">*</span><br />
                                            <input
                                                name="name" value={form.name || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                            {this.error("name")}
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Kontakt osoba (prezime): <span className="text-primary">*</span><br />
                                            <input
                                                name="lastname" value={form.lastname || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Kontakt email: <span className="text-primary">*</span><br />
                                            <input
                                                name="email" value={form.email || ""} onChange={this.change}
                                                type="email" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="w-100">
                                            Kontakt telefon: <span className="text-primary">*</span><br />
                                            <input
                                                name="phone" value={form.phone || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                </div>

                                <label className="w-100">
                                    Dodatna poruka:<br />
                                    <textarea
                                        name="message" value={form.message || ""} onChange={this.change}
                                        rows={4} className="form-control" />
                                </label>
                            </div>}
                        </div>

                        <div className="col-md-6">
                            <div className="mb-4">
                                <Title font="2" name="Dostava" />
                                <div className="mt-2 font_3">
                                Dostava se vrši preko kurirske službe i
                                    iznosi 350 dinara za
                                    porudžbine do 30 kg težine. Za više informacija
                                    kliknite na <a href={routes.faq[1].route + "#isporuka"} target="_blank" rel="noreferrer">
                                    <strong className="text-primary">link</strong>
                                </a>.
                                </div>
                            </div>

                            <div className="mb-4 font_2">
                                <Title font="2" name="Način plaćanja" />

                                <div className="mb-2 mt-2">
                                    <label className="p-3 w-100 bg-light">
                                        <input type="radio" name="payment_method" value={1}
                                               onChange={this.change}
                                               checked={form.payment_method === "1"} /> {paying_method(1)}
                                               <div className="font_09 mt-1 font-italic">Plaćanje prilikom preuzimanja paketa kurirskoj službi</div>
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <label className="p-3 w-100 bg-light">
                                        <input type="radio" name="payment_method" value={2}
                                               onChange={this.change}
                                               checked={form.payment_method === "2"} /> {paying_method(2)}
                                        <div className="font_09 mt-1 font-italic">Nakon što
                                            završite proces naručivanja na vašu e-mail adresu
                                        će stići informacija sa potrebnim podacima za uplatu.
                                        Uplatu možete izvršiti u svim bankama i poštama Srbije</div>

                                    </label>
                                </div>

                                {/*<div className="p-3 bg-light">
                                    <label className="w-100">
                                        <input type="radio" name="payment_method" value={3}
                                               onChange={this.change}
                                               checked={form.payment_method === "3"} /> {paying_method(3)}
                                        <div className="font_09 mt-1 font-italic">
                                            Sigurnost plaćanja je zagarantovana. Kompletan proces
                                            plaćanja karticama se obavlja kroz online sistem banke.
                                            GoGorila nema pristup podacima sa vaše kartice.
                                        </div>
                                    </label>
                                    <div className="order_banks text-center">
                                        <BankLogos />
                                    </div>
                                </div>*/}
                            </div>


                            <div className="mb-3 d-md-none border p-2"
                                 style={{borderWidth: 2, borderStyle: "solid", borderRadius: 10}}>
                                <div className="text-primary font-weight-bold mb-3">
                                    VAŠA KORPA
                                </div>
                                {this.props.products.map((item) => {
                                    const category = this.props.categoriesById[item.product.category_id];
                                    let fullPrice = processPrice(item.product) * item.qty;
                                    return <div key={item.unique} className="border-bottom">
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="mr-2" style={{width: 60}}>
                                                <img src={image(item.product.image_path)} alt={item.product.name} className="img-fluid"/>
                                            </div>
                                            <div>
                                                <div>
                                                    <Link to={routes.shop.route(category?.id)} className="text-primary">
                                                        {category?.name}
                                                    </Link>
                                                </div>
                                                <Link to={routes.product.route(item.product)} className={'font-weight-bold'}>
                                                    {item.product.name}
                                                </Link>
                                                <div className="font_08">
                                                    <FormatPrice price={processPrice(item.product)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <Qty
                                                size="sm"
                                                value={item.qty}
                                                max={item.product.stock}
                                                onChange={(val) => this.props.changeQty(item.product.id, val)}/>
                                        </div>
                                        <div className="font-weight-bold text-primary">
                                            Ukupno: <FormatPrice price={fullPrice} />
                                        </div>

                                        <div className="text-right mb-2">
                                            <button className="btn btn-outline-primary btn-sm"
                                                    onClick={() => this.props.delete(item)}>
                                                Uklonite <i className="fa fa-trash"/>
                                            </button>
                                        </div>
                                    </div>
                                })}
                            </div>

                            <div className="mb-3">
                                <Title font="2" name="UKUPNO" />
                                <div className="text-primary mb-2 mt-2 font_4 font-weight-bold">
                                    <FormatPrice price={total} original={true} />
                                </div>
                            </div>

                            {Object.keys(this.state.form).map((name) => {
                                return <div key={name}> {this.error(name)} </div>
                            })}

                            <div className="mb-3">
                                <label className={`chbx p-2 ${this.state.checkedError ? "border border-danger" : ""}`}>
                                    <input type="checkbox" checked={this.state.checked}
                                           onChange={this.checked} /><span />
                                    &nbsp; Pročitao sam i saglasan sam sa Politikom privatnosti,  Politikom kolačića i Opštim uslovima poslovanja
                                    {this.state.checkedError &&
                                    <div className="alert alert-danger p-2 mt-2">Obavezno.</div>}
                                </label>
                            </div>


                            {form.payment_method === "3"
                                ? <button className="btn btn-lg font-weight-bold btn-success mr-3">
                                    <i className="fas fa-credit-card" />
                                    <span className="ml-2">PRODUŽITE NA PLAĆANJE</span>
                                </button>
                                : this.state.preparingSubmit
                                    ? <button onClick={this.stopSubmit}
                                        className="btn btn-lg font-weight-bold btn-danger mr-3">
                                        ({this.state.preparingSubmit})
                                        <span className="ml-2">OTKAŽITE</span>
                                    </button>
                                    : <button disabled={this.state.loading}
                                        className="btn btn-lg font-weight-bold btn-success mr-3">
                                        <i className="fa fa-shopping-cart" />
                                        <span className="ml-2">PORUČITE</span>
                                    </button>}
                            {this.state.loading &&
                            <span> Procesuiram... </span>}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    };

    componentDidMount() {
        if(!this.props.user)    {
            return false;
        }
        getUserInfo().then((response) => {
            var result = {};
            for (var key in this.default) {
                if (response.data[key]) {
                    result[key] = response.data[key];
                }
            }
            const fill = {...this.default, ...result};
            this.setState({...this.state, form: fill});
        });
    }
}

const mapStateToProps = state => ({
    user: state.user,
    basket: state.basket,
    categories: state.categories,
    categoriesById: state.categoriesById
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket,
    deleteFromBasket: deleteFromBasket,
    setBasket: setBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
