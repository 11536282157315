import React from "react";
import moment from "moment";


class Timer extends React.Component {
    state = {
        loaded: false,
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    tick = (then) => {
        let timeleft = then.diff(moment.utc().local(), 'seconds');

        if(timeleft < 0) {
            clearInterval(this.interval);
            return false;
        }

        let days =  Math.floor((timeleft / (60*60*24)));
        let hours = Math.floor((timeleft / (60*60)) % 24);
        let minutes = Math.floor(timeleft / 60 % 60);
        let seconds = timeleft % 60;

        this.setState({ days, hours, minutes, seconds });
    };

    componentDidMount() {
        if(!this.props.end) return;
        const then = moment.utc(this.props.end).local();
        this.setState({...this.state, loaded: true});
        this.tick(then);
        /*if(this.props.size === "small" && moment.utc(then).local().diff(moment.utc(),'seconds') > 60*60*24)    {
            return false;
        }*/
        this.interval = setInterval(() => {
            this.tick(then);
        }, 1000);
    }

    componentWillUnmount() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        if(!this.state.loaded) return "";
        let { days, hours, minutes, seconds } = this.state;
        if(minutes < 10) minutes = "0" + minutes;
        if(seconds < 10) seconds = "0" + seconds;
        if(this.props.size === "small") {
            return (
                <div className="countdown-small-wrapper mb-2">
                    {days > 0 && <><span className="bg-light">{days}</span> : </>}
                    <span className="bg-light">{hours}</span> : <span className="bg-light">{minutes}</span> : <span className="bg-light">{seconds}</span>
                </div>
            )
        }
        return (
            <div className='d-flex'>
                {days !== undefined &&
                <div className='countdown-item'>
                    {days}
                    <span className="countdown-item-title">dani</span>
                </div>}
                {hours !== undefined &&
                <div className='countdown-item'>
                    {hours}
                    <span className="countdown-item-title">sati</span>
                </div>}
                {minutes && (
                    <div className='countdown-item'>
                        {minutes}
                        <span className="countdown-item-title">minuti</span>
                    </div>
                )}
                {seconds && (
                    <div className='countdown-item'>
                        {seconds}
                        <span className="countdown-item-title">sekunde</span>
                    </div>
                )}
            </div>
        );
    }
}
export default Timer;
