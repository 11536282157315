
export function loadWishlist()  {
    let wishlist = localStorage.getItem("wishlist");
    if(!wishlist) return [];
    try {
        wishlist = JSON.parse(wishlist);
    } catch (e) {
        return [];
    }
    return wishlist;
}
export function addWishlist(productId)  {
    let wishlist = localStorage.getItem("wishlist");
    if(!wishlist) {
        wishlist = [productId];
    } else {
        try {
            wishlist = JSON.parse(wishlist);
        } catch (e) {
            wishlist = [];
        }
        if(!wishlist.includes(productId))    {
            wishlist.push(productId);
        }
    }
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
}
export function removeWishlist(productId)  {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        try {
            wishlist = JSON.parse(wishlist);
        } catch (e) {
            return;
        }
        const index = wishlist.indexOf(productId);
        if (index > -1) {
            wishlist.splice(index, 1);
            localStorage.setItem("wishlist", JSON.stringify(wishlist));
        }
    }
}