import React from "react";
import UserLayout from "../UserLayout";
import Title from "../../../elements/Title";
import Loader from "../../../framework/Loader";
import {order_status} from "../../../../providers/order";
import {Link} from "react-router-dom";
import routes from "../../../../app/routes";
import FormatPrice from "../../../elements/FormatPrice";
import {getUserOrders} from "../../../../app/hooks";
import {formatDateTime} from "../../../../providers/time";


class Orders extends React.Component {

    state = {
        loading: true,
        orders: [],
        form: {page: 1}
    }

    render = () => {
        return <UserLayout>
            <div>
                <div className="mb-4">
                    <Title name="Porudžbine" />
                </div>

                {this.state.loading
                    ? <Loader height={300} />
                    : this.state.orders.length === 0
                        ? <div> Nemate porudžbina </div>
                        : <table className="table-bordered table">
                            <tbody>
                            {this.state.orders.map((item) => {
                                const status = order_status(item.status);
                                return <tr key={item.id}>
                                    <td>
                                        <Link to={routes.orderStatus.route(item.code)}>
                                            <div className="mb-2"> Šifra: <strong>#{item.code}</strong></div>
                                        </Link>
                                        <div className="mb-2">
                                            <strong>Cena: <FormatPrice price={item.full_price} /></strong>
                                        </div>

                                        {formatDateTime(item.created_at)}
                                    </td>
                                    <td>
                                        <strong>{item.address}</strong> <br />
                                        {item.zip} {item.city}
                                        <div className="mt-2 d-md-none">
                                            <div className="mb-2 text-center" style={{color: status.color}}>
                                                {status.title}
                                            </div>
                                            <Link to={routes.orderStatus.route(item.code)} className="btn btn-outline-primary">
                                                Detaljnije
                                            </Link>
                                        </div>
                                    </td>
                                    <td width={120} className="align-middle d-none d-md-table-cell">
                                        <div className="mb-2 text-center" style={{color: status.color}}>
                                            {status.title}
                                        </div>
                                        <Link to={routes.orderStatus.route(item.code)} className="btn btn-outline-primary">
                                            Detaljnije
                                        </Link>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>}
            </div>
        </UserLayout>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getUserOrders(form).then((response) => {
            this.setState({...this.state, loading: false, orders: response.data})
        });
    }

    componentDidMount() {
        this.load();
    }
}

export default Orders;