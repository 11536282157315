import React from "react";


class Title extends React.Component   {


    render = () => {
        let font = "font_4";
        if(this.props.font) {
            font = "font_" + this.props.font;
        }
        return <div>
            <div className="title_underline d-flex">
                {this.props.prepend &&
                <div>{this.props.prepend}</div>}
                <h3 className={`${font} align-self-stretch mb-0 font-weight-bold title mr-2`}>
                    {this.props.name}
                </h3>
                {this.props.nameAppend &&
                <div>{this.props.nameAppend}</div>}
                {this.props.append &&
                <div className="flex-grow-1 text-right">{this.props.append}</div>}
            </div>
        </div>

    }
}
export default Title;
