import React from "react";
import {Link} from "react-router-dom";


class Text0 extends React.Component   {

    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5 footer_text">
            <h4 className="mb-4 title_underline">
                <span className="title">
                    O NAMA
                </span>
            </h4>

            <p className="mb-5">
                <strong> GoGorila doo Čačak </strong><br />
                Bulevar oslobođenja 17, 32000 Čačak, Srbija <br />
                Šifra delatnosti: 4791 - trgovinu na malo posredstvom pošte ili preko interneta <br />
                Matični broj: 21608637 <br />
                Poreski broj (PIB): 112107021 <br />
                Web adresa: <Link to="https://gogorila.com">www.gogorila.com</Link>  <br />
                Kontakt telefon: <a href="tel:+381 064 860 15 54">064/860-15-54</a>  <br />
                Kontakt e-mail: <a href="mailto:web@gogorila.com">web@gogorila.com</a>  <br />
            </p>



            <h3 className="mb-5 font-weight-bold text-center pt-5 pb-5 border-dark border-top border-bottom">
                NAJJAČA PONUDA U ONLINE DŽUNGLI!
            </h3>

            <p className="mb-4">
                GoGorila.com je internet prodavnica koja je sa radom počela 2020. godine sa idejom da kupac na jednom
                mestu možete obaviti potrebnu kupovinu, na brz i lak način, uz najkraće vreme isporuke, po
                najpovoljnijim cenama. <br /><br />
                Predviđeno je da multiwebshop ima 1 000 000 proizvoda (a vremenom i više) tj. što više različitih
                kategorija, i da svojom ponudom zadovolji potrebe i najzahtevnijih kupaca. Počeli smo sa radom u
                Republici Srbiji, a ideja je da se proširi na ceo region ( Balkanska Unija) jer svojom arhitekturom i
                kompletnim sistemom može da podrži veliki broj korisnika istovremeno, kao i da im pruži dobro
                korisničko iskustvo.<br /><br />
                Pripremili smo ponudu iz raznih kategorija, sve za vaš dom, baštu, posao, hobi ili možda poklon; poput
                tehnike, alata, sportske opreme, proizvoda za negu i lepotu, gedžeta, dečje opreme i igračaka, školskog i
                kancelarijeskog pribora i opreme, auto oprema, odeće, obuće, aksesoara, dugotrajne hrane,...
                Na prvom mestu nam je zadovoljstvo kupaca kojima će, na osnovu pozitivnog iskustva, ovo biti omiljeni,
                glavni kanal kupovine.
            </p>
        </div>
    };
}

export default Text0;
