import React from 'react';
import deliveryImg from "../../assets/images/dostava.png";
import returnImg from "../../assets/images/povracaj.png";
import supportImg from "../../assets/images/support.png";
import callImg from "../../assets/images/call centar.png";
import logo from "../../assets/images/logo-go.png";
import {Link} from "react-router-dom";
import facebookImg from "../../assets/images/facebook.png";
import instagramImg from "../../assets/images/instagram.png";
import unicredit from "../../assets/images/unicredit.png";
import visa from "../../assets/images/visa.png";
import master from "../../assets/images/master.png";
import allSecure from "../../assets/images/all-secure.png";
import mcSc from "../../assets/images/mc-sc.png";
import vbv from "../../assets/images/vbv.png";
import maestro from "../../assets/images/maestro.png";
import routes from "../../app/routes";


class MainFooter extends React.Component {


    render() {
        return (
            <footer className="bg-light pb-5">

                <div className="bg_light_darker pt-5 pb-5 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mb-4 mb-md-0">
                                <div className="d-flex align-items-center">
                                    <div className="top_footer_img">
                                        <img src={deliveryImg} className="img-fluid" alt="DOSTAVA" />
                                    </div>
                                    <div>
                                        <div className="font_2 weight_500">DOSTAVA</div>
                                        <div>Cena dostave je 350 din.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4 mb-md-0">
                                <div className="d-flex align-items-center">
                                    <div className="top_footer_img">
                                        <img src={returnImg} className="img-fluid" alt="POVRAĆAJ ROBE" />
                                    </div>
                                    <div>
                                        <div className="font_2 weight_500">POVRAĆAJ ROBE</div>
                                        <div>14 dana od dana prijema</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4 mb-md-0">
                                <div className="d-flex align-items-center">
                                    <div className="top_footer_img">
                                        <img src={supportImg} className="img-fluid" alt="PODRŠKA" />
                                    </div>
                                    <div>
                                        <div className="font_2 weight_500">PODRŠKA</div>
                                        <div>Svakog radnog dana od 09-17h</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex align-items-center">
                                    <div className="top_footer_img">
                                        <img src={callImg} className="img-fluid" alt="CALL CENTAR" />
                                    </div>
                                    <div>
                                        <div className="font_2 weight_500">CALL CENTAR</div>
                                        <div>
                                            <a href="tel:064 860 15 54">064/860-15-54</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container text-center mb-4">
                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0 text-md-left">
                            <div className="font_2 font-weight-bold mb-3">
                                OPŠTE INFORMACIJE
                            </div>

                            {routes.faq.map((item) => {
                                return <div key={item.route} className="mb-2 mb-md-1">
                                    <Link to={item.route}> {item.name} </Link>
                                </div>
                            })}

                            <div className="border-bottom border-dark mt-3 mb-3" />

                            <div className="mb-1">
                                <a href="/Ugovor-o-prodaji.pdf" target="_blank">
                                    <i className="fa fa-download" /> Ugovor o prodaji (PDF)
                                </a>
                            </div>
                            <div className="mb-1">
                                <a href="/Obrazac_za_odustanak_od_ugovora_na_daljinu.pdf" target="_blank">
                                    <i className="fa fa-download" /> Obrazac za odustanak od ugovora na daljinu (pdf)
                                </a>
                            </div>
                            <div className="mb-1">
                                <a href="/Reklamacioni_list_prodavnica_gogorila.pdf" target="_blank">
                                    <i className="fa fa-download" /> Reklamacioni list (pdf)
                                </a>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4 mb-md-0 border_left border_right">
                            <img src={logo} width={200} className="img-fluid mt-4 mb-3" alt="logo" />
                            <div className="mb-4 font_18 font-weight-bold">
                                Najjača ponuda u online džungli! <br />
                                GoGorila doo
                            </div>
                            <div className="mb-4">
                                <a href="https://www.facebook.com/gogorila.shop" className="mr-2" target="_blank" rel="noopener noreferrer">
                                    <img src={facebookImg} alt="facebook" />
                                </a>
                                <a href="https://www.instagram.com/gogorila.shop/?hl=sr" className="mr-2" target="_blank" rel="noopener noreferrer">
                                    <img src={instagramImg} alt="instagram" />
                                </a>
                            </div>
                        </div>

                        <div className="col-md-3 text-md-right">
                            <div className="font_2 font-weight-bold mb-3">
                                KORISNIČKA PODRŠKA
                            </div>
                            <div className="mb-2">
                                Radno vreme: ponedeljak-petak 9-17h, subota: 9-15h
                            </div>
                            <div className="mb-4">
                                <a className="" href="mailto:web@gogorila.com">
                                    <i className="fa fa-envelope mr-1" /> web@gogorila.com
                                </a>
                            </div>
                            <div className="mb-2">
                                <div className="font_2 font-weight-bold mb-1">
                                    CALL CENTAR
                                </div>
                                <a className="" href="tel:064 860 15 54">
                                   <i className="fa fa-mobile mr-1" />  064/860-15-54
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container footer_banks text-center mb-3">
                    <div>
                        <a href='https://www.unicreditbank.rs/rs/' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={unicredit} className='img-fluid' alt="Unicredit bank" />
                        </a>
                        <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={visa} className='img-fluid' alt="Visa" />
                        </a>
                        <a href='https://www.mastercard.us/en-us/merchants/safety-security/securecode.html'rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={master} className='img-fluid' alt="Mastercard" />
                        </a>
                        <a href='https://www.allsecure.rs/' className='d-inline-block' rel="noopener noreferrer" target="_blank">
                            <img src={allSecure} className='img-fluid' alt="All Secure" />
                        </a>
                        <a href='https://www.mastercard.us/en-us/merchants/safety-security/securecode.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={mcSc} className='img-fluid' alt="Mastercard safety-security" />
                        </a>
                        <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={vbv} className='img-fluid' alt="Visa verified" />
                        </a>
                        <a href='https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html' rel="noopener noreferrer" className='d-inline-block' target="_blank">
                            <img src={maestro} className='img-fluid' alt="Maestro" />
                        </a>
                    </div>
                </div>

                <div className="container">
                    <div className="description text-center">
                        Cene na sajtu su iskazane u dinarima sa uračunatim porezom, a plaćanje se vrši isključivo
                        u dinarima. Nastojimo da budemo što precizniji u opisu proizvoda, prikazu slika i samih cena,
                        ali ne možemo garantovati da su sve informacije kompletne i bez grešaka.
                        Svi artikli prikazani na sajtu su deo naše ponude i ne podrazumeva se da
                        su dostupni u svakom trenutku. Za raspoloživost artikala pozovite naš Call centar.
                    </div>

                    <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                            <div className="description text-center mt-4 pt-4 border_top">
                                GoGorila doo &#169; 2020 Developed by <a href="https://superweb.rs" target="_blank" rel="noopener noreferrer"
                                                                         className="superweb">SuperWeb</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default MainFooter;
