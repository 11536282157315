import React from "react";
import UserLayout from "../UserLayout";
import Title from "../../../elements/Title";
import {deleteUser, getUserInfo, patchUser} from "../../../../app/hooks";
import {loginUser, logoutUser} from "../../../../providers/userProvider";
import Input from "../../../elements/Input";
import Loader from "../../../framework/Loader";
import {setUser} from "../../../../store/actions/userActions";
import {connect} from "react-redux";


class Dashboard extends React.Component {

    state = {
        loading: true,
        user: {}
    };

    patch = (field, val) => {
        patchUser({field: field, value:val}).then((response) => {
            let user = loginUser(response);
            if(user) {
                this.props.setUser(user);
            }
        });
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete nalog?");
        if (r !== true) {
            return false;
        }
        deleteUser({}).then((response) => {
            logoutUser(true);
        });
    }

    render = () => {
        return <UserLayout>
            <div className="mb-4">
                <Title name="Informacije" />
            </div>

            {this.state.loading
                ? <Loader />
                : <>
                    <div className="p-3 mb-5 bg-light">
                        Ukoliko je potrebno, vaše podatke možete u svakom momentu ažurirati.

                        <div className="mt-4">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="mb-2">E-mail </div>
                                    <input
                                        name="email"
                                        value={this.state.user.email || ""}
                                        placeholder="E-mail"
                                        type="email"
                                        readOnly={true}
                                        className="form-control form-control"
                                    />
                                </div>

                                {[{field:'username', name:'Korisničko ime'},
                                    {field:'name', name:'Ime'},
                                    {field:'lastname', name:'Prezime'},
                                    {field:'phone', name:'Telefon'}].map((item, i) => {
                                    return <div key={i} className="col-md-6">
                                        <Input
                                            submit={true}
                                            onSaved={(val) => this.patch(item.field, val)}
                                            title={<div className="font-weight-bold"> {item.name} </div>}
                                            value={this.state.user[item.field]} name={item.field}
                                            maxLength={191} autoComplete="off"  />
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <Title name="Adresa" />
                    </div>

                    <div className="p-3 mb-5 bg-light">
                        <div className="row">
                            {[{field:'address', name:'Vaša adresa'},
                                {field:'zip', name:'Zip kod'},
                                {field:'city', name:'Grad'},
                            ].map((item, i) => {
                                return <div key={i} className="col-md-6">
                                    <Input
                                        submit={true}
                                        onSaved={(val) => this.patch(item.field, val)}
                                        title={<div className="font-weight-bold"> {item.name} </div>}
                                        value={this.state.user[item.field]} name={item.field}
                                        maxLength={191} autoComplete="off"  />
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="mb-4">
                        <Title name="Obrišite nalog" />
                    </div>

                    <div className="p-3 mb-5 bg-light">
                        <button onClick={this.delete} className="btn btn-danger">
                            Obrišite
                        </button>
                    </div>
                </>}
        </UserLayout>
    }

    componentDidMount() {
        getUserInfo().then((response) => {
            this.setState({...this.state, loading: false, user: response.data});
        });
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);