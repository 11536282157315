import axios from "axios";
import store from "../store/store";
import {openDrawer} from "../store/actions/drawersActions";


axios.interceptors.request.use(function (config) {
    if(store.getState().user)       {
        var ujwt = store.getState().user.ujwt;
        config.headers['userauthorization'] = 'Bearer ' + ujwt;
    }
    return config;
}, function (error) {
    // Do something with request error
    // return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;

}, function (error) {
    if(error.response)      {
        if(error.response.status === 404)       {
            return Promise.reject('Page not found!');
        }
        else if(error.response.status === 401)       {
            //logoutUser(true);
        }
        else if(error.response.status === 402)       {

        }
        else if(error.response.status === 403)       {
            store.dispatch(openDrawer({name: "error", content: error.response.data}));
            return Promise.reject(error.response.data);
        }
        else if(error.response.status === 422)       {
            return Promise.reject(error.response.data);
        }
        else        {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject('Error with server');
});
