import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {breadcrumbs} from "../../providers/categories";
import routes from "../../app/routes";


class Breadcrumbs extends React.Component {


    render() {
        const category = this.props.category;
        let parents = [];
        if(category)    {
            parents = breadcrumbs(category.id);
        }
        return (
            <div className="text-black-50 font_09">
                <Link to={routes.welcome.route} className="text-lowercase">
                    {routes.welcome.name}
                </Link>
                <Link to={routes.shop.route()} className="text-lowercase">
                    <i className="fa fa-chevron-right ml-2 mr-2 font_07" />
                    {routes.shop.name}
                </Link>
                {category && parents.length > 0 &&
                parents.map((item) => {
                    return <span key={item.id}>
                         <i className="fa fa-chevron-right ml-2 mr-2 font_07" />
                         <Link to={routes.shop.route(item.id)} className="text-lowercase">
                           {item.name}
                        </Link>
                    </span>
                })}

            </div>
        );
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
export default connect(mapStateToProps)(Breadcrumbs);
