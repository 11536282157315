import store from '../store/store';

export function unmarshal(categories, acc=[], lvl=1)   {
    for(let i=0; i<categories.length; i++)      {
        const item = categories[i];
        item.lvl = 1;
        acc.push(item);
        if(item.children.length > 0)    {
            unmarshal(item.children, acc, lvl+1);
        }
    }
    return acc;
}

export function findCategoryById(id, categories)  {
    return checkId(id, categories);
}
function checkId(id, categories)        {
    for(let i=0; i<categories.length; i++)      {
        const item = categories[i];
        if(item.id === id)   {
            return item;
        }
        if(item.children.length > 0)        {
            const selected = checkId(id, item.children);
            if(selected) return selected;
        }
    }
}

export function breadcrumbs(id) {
    const categories = store.getState().categories;
    const found =  breadcrumbsLoop(categories, id, []);
    if(found) return found.reverse();
    return [];
}
function breadcrumbsLoop(categories, id, acc)       {
    for(let i=0; i<categories.length; i++) {
        const item = categories[i];
        if (item.id === id) {
            acc.push(item);
            return acc;
        }
        const pass = breadcrumbsLoop(item.children, id, acc);
        if(pass)        {
            pass.push(item);
            return pass;
        }
    }
    return false;
}

export function getChildrenIds(category) {
    let holder = [category.id];
    return loopChildren(category.children, holder);
}
export function loopChildren(items, holder) {
    if(items)   {
        for(let i=0; i<items.length; i++)       {
            holder.push(items[i].id);
            holder = loopChildren(items[i].children, holder);
        }
    }
    return holder;
}
