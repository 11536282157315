import React from "react";


class Transaction extends React.Component {

    render = () => {
        if(!this.props.order.checkout_report)        {
            return "";
        }
        const report = JSON.parse(this.props.order.checkout_report);
        return <div className="mb-4 bg-light p-4">
            <h3 className="text-center mb-4">Status transakcije</h3>
            <div className="mb-2 font_4">
                Status: &nbsp;
                {!this.props.order.checkout_error
                    ? <strong style={{color: "#007d19"}}>Uspešno plaćanje</strong>
                    : <strong style={{color: "#d30000"}}>Greška prilikom plaćanja</strong>
                }
            </div>
            <div className="mb-2">
                Broj porudžbine: &nbsp; <strong>{this.props.order.code}</strong>
            </div>
            {report.result && report.result.description &&
            <div className="mb-2">
                Poruka: &nbsp; <strong>{report.result.description}</strong>
            </div>}
            {report.amount &&
            <div className="mb-2">
                Iznos i valuta: &nbsp; <strong>{report.amount} {report.currency}</strong>
            </div>}
            {report.paymentBrand &&
            <div className="mb-2">
                Tip kartice: &nbsp; <strong>{report.paymentBrand}, ****{report.card.last4Digits}</strong>
            </div>}
            <div className="mb-2">
                Autorizacioni kod banke: &nbsp;
                {report.resultDetails &&
                <strong>{report.resultDetails.ConnectorTxID3}</strong>}
            </div>
            {report.timestamp &&
            <div className="">
                Vreme transakcije:  &nbsp; <strong>{report.timestamp}</strong>
            </div>}
        </div>
    };
}

export default Transaction;
