import React from "react";
import { connect } from "react-redux";
import {Route, Redirect} from "react-router-dom";
import routes from "../app/routes";
import MainLayout from "../views/layouts/MainLayout";

const GuestMiddleware = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        rest.user === false
            ? <MainLayout {...{component: Component, ...props}} />
            : <Redirect to={routes.dashboard.route}/>
    )}/>
};

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(GuestMiddleware);
