import React from "react";


class CookiesAlert extends React.Component   {

    state = {
        cookies: true
    }

    render = () => {
        return  <div>
            {!this.state.cookies &&
            <div className="cookies_alert">
                <div className="container">
                    <div className="d-md-flex align-items-center">
                        <div className="flex-grow-1 mb-3 mb-md-0">
                            <div className="mb-2 font-weight-bold font_2">
                                Ovaj veb sajt koristi kolačiće
                            </div>
                            Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija
                            društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite
                            naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.
                        </div>
                        <div>
                            <button onClick={this.cookies}
                                    className="btn btn-light font-weight-bold">PRIHVATAM</button>
                        </div>
                    </div>

                </div>
            </div>}
        </div>
    }


    cookies = () => {
        localStorage.setItem('cookiesConfirmed', true);
        this.setState({...this.state, cookies: true});
    }

    componentDidMount() {
        const cookies = localStorage.getItem('cookiesConfirmed');
        this.setState({...this.state, cookies: !!cookies});
    }
}
export default CookiesAlert;