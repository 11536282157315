import React from 'react';
import {connect} from "react-redux";
import Dropdown from "../framework/Dropdown";
import {currencies, getCurrency} from "../../providers/price";
import {setCurrency} from "../../store/actions/currencyActions";
import {getCurrencies} from "../../app/hooks";


class Currency extends React.Component {

    changeCurrency = (e, index) => {
        e.preventDefault();
        if(index === this.props.currency)   return false;

        if(index === 0) {
            this.props.setCurrency({id: index, kurs: false});
        } else {
            getCurrencies().then((response) => {
                this.props.setCurrency({id: index, kurs: response.data?.result?.eur?.sre});
            });
        }
    }

    render() {
        return (
            <Dropdown anchor="right" title={<span>
                                    <i className="fas fa-euro-sign mr-1" aria-hidden="true" />
                                    Valuta <span className="text-black-50">({getCurrency(this.props.currency.id)["short"]})</span>
                                </span>}>
                <div className="p-2">
                    {currencies().map((item, index) => {
                        return <a key={item.short} href="/" onClick={(e) => this.changeCurrency(e, index)} className="link">
                            {item.name} <span className="text-black-50">({item.short})</span>
                        </a>
                    })}
                </div>
            </Dropdown>
        );
    }
}
const mapStateToProps = state => ({
    currency: state.currency
});
const mapDispatchToProps = {
    setCurrency: setCurrency
};
export default connect(mapStateToProps, mapDispatchToProps)(Currency);
