import React from "react";
import {Link} from "react-router-dom";
import AuthLayout from "../AuthLayout";
import {postPasswordRecovery} from "../../../../app/hooks";
import routes from "../../../../app/routes";


class PasswordRecovery extends React.Component {

    form = {
        email: ""
    };
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}, success: false});
        postPasswordRecovery(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, form: this.form, success: true, error: {}});
        }).catch((error) => {
            this.setState({...this.state, loading: false, error: error, success: false});
        });
    };

    render = () => {
        let form = this.state.form;
        return <AuthLayout>
            <div>
                <form action="#" method="POST" onSubmit={this.submit}>
                    <div>
                        <input
                            name="email"
                            onChange={this.changeField}
                            value={form.email}
                            placeholder="Email"
                            type="email"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.email &&
                        <div className="alert alert-danger">{this.state.error.email}</div>}
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-primary btn-block mb-3">
                        Pošaljite zahtev za reset lozinke
                    </button>
                    <div>
                        <Link to={routes.register.route}>
                            Registrujte nalog?
                        </Link>
                    </div>
                </form>

                {this.state.error.message &&
                <div className="alert alert-danger mt-2">
                    {this.state.error.message}
                </div>}

                {this.state.success &&
                <div className="alert alert-success mt-2">
                    Poslali smo vam E-mail za reset lozinke.
                </div>}
            </div>
        </AuthLayout>
    }
}
export default PasswordRecovery;