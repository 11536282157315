import {SET_CURRENCY} from "../actions/currencyActions";

const currencyReducer = function(state = {id: 0, kurs: false}, {type, payload})      {
    switch(type)        {
        case SET_CURRENCY:
            return payload;
        default:
            return state;
    }
}
export default currencyReducer;