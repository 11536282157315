import React from "react";


class Text6 extends React.Component   {



    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5 bold footer_text">

            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                  Često postavljana pitanja
                </span>
            </h4>

            <p className="mb-4">
                <b>Da li je potrebno da imam korisnički nalog da bih izvršio kupovinu?</b>
                <br/>
                Ukoliko želite da izvršite porudžbinu, nije potrebno da kreirate korisnički nalog.
                <br/>    <br/>
                <b>Kako mogu da tražim određeni proizvod?</b>
                <br/>
                Ukoliko želite da potražite određeni proizvod, molimo koristite polje za pretraživanje u header-u sajta.
                Unesite reči za pretragu i ako postoje na sajtu, prikazaće Vam se lista rezultata.
                <br/><br/>
                <b>Gde mogu da proverim dostupnost proizvoda?</b>
                <br/>
                Ispod polja za količinu, nalazi se polje &quot;Proverite dostupnost&quot;. Klikom na njega otvara se prozor u koji
                unosite poruku i birate način primanja odgovora. Klikom na dugme “Pošaljte upit” šaljete nam zahtev na
                koji vam mi odgovaramo u najkraćem roku.
                <br/><br/>
                Takođe možete kontaktirati naš call centar
                na <a href="tel:+381 064 860 15 54">064/860-15-54</a> ili putem
                mejla: <a href="mailto:web@gogorila.com">web@gogorila.com</a>
                <br/><br/>
                <b>Da li je poštarina uključena u cenu?</b>
                <br/>
                Poštarina nije uključena u cenu. Troškovi isporuke iznose 350 dinara (uključujući PDV) za sve porudžbine
                čija je masa do 30 kg. Za sve porudžbine čija masa prelazi 30 kg, pakete velikih gabarita, isporuka bele
                tehnike, troškovi isporuke se naplaćuje po posebnom cenovniku. Detaljnije na linku:
                <br/>
                <a href="https://gogorila.com/opsti-uslovi-poslovanja" target="_blank" rel="noreferrer">
                    https://gogorila.com/opsti-uslovi-poslovanja
                </a>  stavka: <b>6. ISPORUKA</b>
                <br/><br/>
                <b>Gde mogu da proverim tehničke podatke o proizvodima?</b><br/>
                Tehničke karakteristike i specifikacije proizvoda možete naći neposredno ispod slike proizvoda. Ukoliko
                želite dodatna pojašnjenja budete slobodni da kontaktirate naš call centar
                na <a href="tel:+381 064 860 15 54">064/860-15-54</a> ili putem
                mejla:<a href="mailto:web@gogorila.com">web@gogorila.com</a>
                <br/><br/>
                <b>Kako da izmenim podatke koje sam uneo u porudžbinu nakon što sam je zaključio?</b>
                <br/>
                Ukoliko želite da promenite porudžbinu, molimo kontaktirajte naš call centar
                ili nam pošaljite mejl na: <a href="mailto:web@gogorila.com">web@gogorila.com</a>
                <br/><br/>
                <b>Prilikom poručivanja odabrao sam pogrešan način plaćanja. Šta da radim?</b>
                <br/>
                Ukoliko želite da promenite način plaćanja, a već ste zaključili porudžbinu, molimo kontaktirajte naš call
                centar ili nam pošaljite mejl na: <a href="mailto:web@gogorila.com">web@gogorila.com</a>
                <br/><br/>
                <b>Da li mogu poručiti kao pravno lice i da dobijem račun na firmu?</b>
                <br/>
                Naravno. Prilikom naručivanja, gde unosite podatke o kupcu imate karticu „pravno lice“, u nju unosite
                tražene podatke. Prilikom odabira načina plaćanja birate „Plaćanje preko računa“ i završavate
                porudžbinu. Molimo da obratite pažnju da ovde nisu iskazani troškovi isporuke. Obradom vaše
                porudžbine, u najkraćem roku, poslaćemo vam predračun za poručenu robu. Po evidentiranju vaše uplate,
                robu šaljemo na naznačenu adresu.
                <br/><br/>
                <b>Šta je potrebno ukoliko želim da moja porudžbina bude isporučena na adresu različitu od one koju sam
                    naveo u korisničkom nalogu?</b><br/>
                Nakon što ste dodali i potvrdili proizvode u korpi, sistem Vam nudi opciju da unesete drugu adresu za
                isporuku Vaše porudžbine.
                <br/><br/>
                <b>Da li mogu da poručim proizvod i da ga lično preuzmem na vašoj adresi?</b>
                <br/>
                Nažalost, ne postoji ova opcija. Obzirom da nemamo maloprodaju, robu isključivo šaljemo brzom poštom.
                <br/><br/>
                <b>Kako mogu da platim porudžbinu?</b><br/>
                Detaljne informacije o načinu plaćanja možete proveriti na
                linku: <a href="https://gogorila.com/opsti-uslovi-poslovanja" target="_blank" rel="noreferrer">
                https://gogorila.com/opsti-uslovi-poslovanja
            </a>  stavka: <b className="text-uppercase">5. plaćanje</b>
                <br/><br/>
                <b>Gde mogu pronaći informacije o reklamaciji i postupku reklamacije?</b><br/>
                Sve potrebne informacije se nalaze na ovoj
                strani: <a href="https://gogorila.com/saobraznost-i-reklamacije" target="_blank" rel="noreferrer">
                https://gogorila.com/saobraznost-i-reklamacije
            </a>
                <br/><br/>
                <b>Greškom sam naručio proizvod, želim da otkažem porudžbinu. Kako?</b><br/>
                Ukoliko želite da otkažete porudžbinu, molimo pošaljite mejl
                na: <a href="mailto:web@gogorila.com">web@gogorila.com</a> ili kontaktirajte naš call centar.
                <br/><br/>


                <b>Želim da obradujem nekoga i pošaljem mu poklon na kućnu adresu,
                    da li je to moguće i kako?</b><br/>
                Da, moguće je. Prilikom poručivanja u polje „Dodatna poruka“ upišite adresu na koju želite da pošaljete
                poklon. Molimo da obratite pažnju da ne odaberate način plaćanja „Plaćanje pouzećem“, jer će tada
                račun stići osobi kojoj želite da pošaljete poklon. Zato odaberite neki drugi način plaćanja i imajte u vidu
                da troškovi isporuke nisu uključeni. Prilikom obrade vaše porudžbine konktaktiraće vas naš call center u
                vezi iznosa troškova isporuke. Po evidentiranju uplate robu šaljemo na željenu adresu.
                <br/><br/>
                <b>Želeo bih da kupim proizvod koga nema na zalihama. Šta treba da uradim?</b>
                <br/>
                Za više informacija o dostupnosti proizvoda, molimo kontaktirajte naš call centar ili nam pošaljite mejl
                na: <a href="mailto:web@gogorila.com">web@gogorila.com</a>
                <br/><br/>
                <b>Da li je moguća isporuka u druge zemlje osim Srbije?</b>
                <br/>
                Isporuka robe vrši se isključivo na teritoriji Republike Srbije bez KiM. Isporuka van teritorije Srbije nije
                moguća.
                <br/><br/>
                <b>Da li mogu da poručim proizvod ako nisam punoletan?</b>
                <br/>
                Na našem sajtu ne postoji starosna granica za poručivanje robe, ali ako ste maloletna osoba obavezno je
                prisustvo punoletne osobe prilikom preuzimanja robe na kućnoj adresi.
                <br/><br/>
                <b>Ne mogu da se setim svoje lozinke. Šta je potrebno da uradim?</b>
                <br/>
                Ukoliko ste zaboravili lozinku, molimo koristite opciju „Zaboravili ste lozinku?“ koja se nalazi na stranici
                za prijavljivanje. U polje email uneti email pod kojim je nalog registrovan i dobićete sva potrebna
                uputstva na mail.
                <br/><br/>
                <b>Kako mogu da promenim podatke svog naloga?</b>
                <br/>
                Vaše podatke možete u svakom momentu ažurirati. Da biste promenili podatke svog korisničkog naloga,
                molimo prijavite se i koristite opciju „Informacije o nalogu“ koja se nalazi u odeljku Moj nalog.
                <br/><br/>
                <b>Mogu li pogledati istoriju svojih porudžbina?</b>
                <br/>
                Za pregled istorije svojih porudžbina morate biti ulogovani. Informacije možete dobiti u odeljku Moj
                Nalog -&gt; Porudžbine.
            </p>

        </div>
    };
}

export default Text6;
