import React from "react";
import {getBrands, postContactUs} from "../../../app/hooks";


class Text5 extends React.Component   {

    state = {
        form: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        checked: false,
        checkedError: false,
        success: false,
        error: false
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    };

    checked = (e) => {
        this.setState({...this.state, checked: e.target.checked, checkedError: !e.target.checked});
    }

    submit = (e) => {
        e.preventDefault();
        if(!this.state.checked) {
            this.setState({...this.state, checkedError: true});
            return false;
        }
        this.setState({...this.state, error: false});
        postContactUs(this.state.form).then((response) => {
            console.log(response.data)
            this.setState({...this.state, success: true, form: { name: "",
                    email: "",
                    phone: "",
                    message: "",}});
        }).catch(error => {
            console.log(error)
            this.setState({...this.state, error: error});
        });
    }

    render = () => {
        const form = this.state.form;
        return <div className="container mt-2 mt-md-5 mb-5">
            <h4 className="mb-4 title_underline">
                <span className="title">
                     Kontaktirajte nas
                </span>
            </h4>

            <div className="row">
                <div className="col-md-6">
                    <div className="bg-light p-4">
                        <div className="mb-3 h5"> KONTAKT INFORMACIJE</div>

                        <div>
                            Ukoliko imate bilo kakvo pitanje ne ustručavajte se da nas kontaktirate. Pozovite nas ili popunite formu i mi ćemo Vam odgovoriti u najkraćem mogućem roku.
                            Ono što Vi imate da kažete je za nas veoma važno i uvek ćemo Vas vrlo rado saslušati.
                            <br/><br/>
                            <h5>TELEFON:</h5>
                            064/860-15-54
                            <br/><br/>
                            <h5>EMAIL:</h5>
                            web@gogorila.com
                        </div>
                    </div>

                </div>
                <div className="col-md-6">
                    <form action="/" onSubmit={this.submit}>
                        <label className="w-100 mb-3">
                            <div className="font-weight-bold mb-2">Ime</div>
                            <input value={form.name} name="name" onChange={this.changeField}
                                   type="text" className="form-control" required={true}
                                 />
                        </label>
                        <label className="w-100 mb-3">
                            <div className="font-weight-bold mb-2">E-pošta</div>
                            <input type="email" value={form.email} name="email" onChange={this.changeField}
                                   className="form-control" required={true} />
                        </label>
                        <label className="w-100 mb-3">
                            <div className="font-weight-bold mb-2">Broj telefona</div>
                            <input type="text" value={form.phone} name="phone" onChange={this.changeField}
                                   className="form-control" required={true} />
                        </label>
                        <label className="w-100 mb-3">
                            <div className="font-weight-bold mb-2">Šta želite da nas pitate?</div>
                            <textarea  value={form.message} name="message" onChange={this.changeField}
                                className="form-control" required={true} />
                        </label>
                        <label className={`chbx p-2 ${this.state.checkedError ? "border border-danger" : ""}`}>
                            <input type="checkbox" checked={this.state.checked}
                            onChange={this.checked} /><span />
                            &nbsp; Pročitao sam i saglasan sam sa Politikom privatnosti,  Politikom kolačića i Opštim uslovima poslovanja
                            {this.state.checkedError &&
                            <div className="alert alert-danger p-2 mt-2">Obavezno.</div>}
                        </label>
                        <button type="submit" className="btn btn-primary">
                            Pošalji
                        </button>
                        {this.state.success &&
                        <div className="alert alert-success mt-2">Poruka uspesno poslata!</div>}
                        {this.state.error &&
                        <div className="alert alert-danger mt-2">{this.state.error.message}</div>}
                    </form>
                </div>
            </div>
        </div>
    };
}

export default Text5;
