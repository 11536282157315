import React from "react";
import {Route} from "react-router-dom";
import MainLayout from "../views/layouts/MainLayout";


const LayoutMiddleware = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        <MainLayout {...{component: Component, ...props}} />
    )}/>
};

export default LayoutMiddleware;
