import React from 'react';
import {connect} from "react-redux";
import {getProducts} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import Breadcrumbs from "../../framework/Breadcrumbs";
import Timer from "../../framework/Timer";
import {adminPrice, processPrice} from "../../../providers/price";
import ProductGallery from "./ProductGallery";
import Specifications from "./Specifications";
import Qty from "../../elements/Qty";
import {addToBasket} from "../../../store/actions/basketActions";
import {setData} from "../../../store/actions/dataActions";
import {addWishlist, removeWishlist} from "../../../providers/wishlist";
import {addToWishlist, removeFromWishlist} from "../../../store/actions/wishlistActions";
import {openDrawer} from "../../../store/actions/drawersActions";
import FormatPrice from "../../elements/FormatPrice";
import {seoAll, structuredData} from "../../../providers/seoProvider";
import {image} from "../../../app/helpers";
import routes from "../../../app/routes";
import {addToTracked} from "../../../store/actions/trackedActions";
import ProductAvailability from "./ProductAvailability";


class Product extends React.Component {

    state = {
        loading: true,
        product: false,
        qty: 1,

    }

    addToWishlist = () => {
        this.props.addToWishlist(this.state.product.id);
        addWishlist(this.state.product.id);
    }
    removeFromWishlist = () => {
        this.props.removeFromWishlist(this.state.product.id);
        removeWishlist(this.state.product.id);
    }

    addToBasket = () => {
        this.props.addToBasket({product: this.state.product, qty: this.state.qty, filters: null});
        this.props.openDrawer({name: "basketModal", item: this.state.product});
        //this.props.history.push(routes.basket.route);
    };

    render() {
        if(this.state.loading) {
            return <Loader />
        }
        if(!this.state.product) {
            return <div className="container p-5 font-weight-bold text-center font_2">
                Proizvod nije više dostupan.
            </div>
        }
        let product = this.state.product;
        let state = product.supply;
        if(product.stock !== null && product.stock < 1)  {
            state = false;
        }
        return (
            <div className="container">
                <div className="mb-3">
                    <Breadcrumbs category={{id: product.category_id}} />
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 pr-md-5">
                        <ProductGallery product={product} />
                    </div>

                    <div className="col-md-6">
                        {product.countdown_end && adminPrice(product) &&
                        <div className="bg-light p-2 mb-3">
                            <div className="mb-2">Ponuda ističe za:</div>
                            <Timer end={product.countdown_end} />
                        </div>}

                        <div className="mb-2">
                            <div className="text-black-50 font_2">
                                {product.ean &&
                                <div className="d-md-inline-block mb-2 mb-md-0"> EAN: {product.ean} </div>}
                                {product.ean && product.brand &&
                                <div className="d-none d-md-inline-block ml-2 mr-2"> | </div>}
                                {product.brand &&
                                <div className="d-md-inline-block mb-2 mb-md-0"> Brend: {product.brand} </div>}
                            </div>
                        </div>


                        <h1 className="font_3 mb-3"> {product.name} </h1>

                        <div className="mb-3">
                            {this.props.wishlist.includes(product.id)
                                ? <button onClick={this.removeFromWishlist}
                                          className="btn btn-link text-black-50 font-weight-bold">
                                    <i className="fa fa-heart mr-1" /> UKLONI IZ LISTE ŽELJA
                                </button>
                                : <button onClick={this.addToWishlist}
                                          className="btn btn-link font-weight-bold">
                                    <i className="fa fa-heart-o mr-1" /> DODAJ U LISTU ŽELJA
                                </button>}
                        </div>

                        {product.description &&
                        <div className="text-black-50 mb-4 prewrap" dangerouslySetInnerHTML={{__html: product.description}} />}

                        <div className="mb-3">
                            <span className="text-primary font_4 font-weight-bold">
                                <FormatPrice price={processPrice(product)} />
                            </span>
                            {product.admin_discount_price && adminPrice(product) &&
                            <del className="text-black-50 ml-3">
                                <FormatPrice price={product.admin_discount_price} />
                            </del>}
                        </div>

                        {state
                            ? <div className="mb-4">
                                <div className="d-flex mb-3">
                                    <Qty
                                        max={this.state.product.stock}
                                        value={this.state.qty}
                                        onChange={(val) => this.setState({...this.state, qty: val})} />

                                    <button onClick={this.addToBasket}
                                            className="btn btn-success text-uppercase rounded-0">
                                <span className="d-none d-md-inline-block mr-1">
                                    <i className="fas fa-shopping-cart" />
                                </span>
                                        <span className="d-none d-md-inline-block"> Dodaj </span> u korpu
                                    </button>
                                </div>

                                <ProductAvailability item={product} />
                            </div>
                            : <div className="alert mb-4 alert-info font-weight-bold">TRENUTNO RASPRODATO</div>}



                        {processPrice(product) > 3000 &&
                        <div className="mb-3 font_09 bg-light font-weight-bold text-uppercase p-2">
                            Podelite kupovinu do 12 mesečnih rata. Za više informacija kliknite
                            <a href="https://www.unicreditbank.rs/rs/pi/kredit/kartice.html" className="text-primary"
                               target="_blank" rel="noopener noreferrer"> ovde </a>.
                        </div>}

                        <div className="text-black-50 font_09 mb-3">
                            Prava potrošača: Zagarantovana sva prava kupaca po osnovu zakona o zaštiti potrošača.
                        </div>
                    </div>
                </div>

                <Specifications product={product} />
            </div>
        );
    }

    setTrackers = (product) => {
        seoAll(product.name);
        structuredData({
            "@context": "https://schema.org",
            "@type": "Product",
            "description": `${product.name}`,
            "name": product.name,
            "image": image(product.image_path),
            "brand": product.brand,
            "sku": product.id,
            "url": "https://gogorila.com" + routes.product.route(product),
            "offers": {
                "@type": "Offer",
                "availability": "https://schema.org/InStock",
                "price": parseFloat(processPrice(product)),
                "priceCurrency": "RSD"
            }
        });
        this.props.addToTracked(product);
        this.props.setData({id: window.location.href, type: "product", title: product.name, image: product.image_path});
    }

    load = (goep) => {
        getProducts({id: this.props.id, withImages: true, withFilters: true, goep: goep}).then((response) => {
            const product = response.data;
            if(product) {
                this.setTrackers(product);
            }
            this.setState({...this.state, product: product, loading: false});
        });
    }

    componentDidMount() {
        const query = document.location.search; // could be '?foo=bar'
        const urlParams =  new URLSearchParams(decodeURIComponent(query));
        let goep = false;
        if(urlParams.get('goep'))   {
            goep = urlParams.get('goep');
        }
        this.load(goep);
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
    wishlist: state.wishlist,
});
const mapDispatchToProps = {
    addToWishlist: addToWishlist,
    addToTracked: addToTracked,
    removeFromWishlist: removeFromWishlist,
    setData: setData,
    addToBasket: addToBasket,
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
