import React from "react";


class Text7 extends React.Component   {



    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5">
            <h4 className="mb-4 title_underline">
                <span className="title">
                    Politika o kolačićima
                </span>
            </h4>

            <p className="mb-4 font-weight-bold">
            Kako bi ova web stranica radila pravilno i kako bismo bili u stanju da vršimo dalja unapređenja
            stranice, a u svrhu poboljšanja vašeg iskustva pregledanja, ova stranica mora vašem računaru da
            pošalje malu količinu informacija (tzv. cookies ili kolačići). Preko 90% svih web stranica koristi ovu
            praksu, ali u skladu sa Zakonom o zaštiti podataka o ličnosti smo u obavezi da pre slanja kolačića
            obezbedimo vaš pristanak. Korišćenjem web stranice pristajete na upotrebu kolačića. Blokiranjem
            kolačića i dalje možete da pregledate stranicu, ali vam neke opcije neće biti dostupne.
            </p>
            <h5 className="mb-4 font-weight-bold">
                Šta je kolačić?
            </h5>
            <p className="mb-4">
            Kolačić (eng. Cookie) je informacija poslata vašem računaru od strane web stranice koju posetite.
            Kolačići obično čuvaju vaše postavke za web stranicu, kao što su željeni jezik ili adresa. Kasnije, kada
            opet otvorite istu web stranicu internet pretaživač šalje nazad kolačiće koji pripadaju toj stranici. Ovo
            omogućava stranici da prikaže informacije prilagođene vašim potrebama.
            </p>
            <p className="mb-4">
            Kolačići mogu da čuvaju širok pojas informacija uključujući i podatke o ličnosti (kao što je vaše ime ili e-
            mail). Ipak, ova informacija može biti sačuvana jedino ako vi to omogućite - web stranice ne mogu da
            dobiju pristup informacijama koji im vi niste dali i ne mogu da pristupe drugim datotekama na vašem
            računaru. Zadate aktivnosti čuvanja i slanja kolačića vama nisu vidljive. Ipak, možete da promenite vaše
            postavke internet pretraživača da možete sami da birate da li hoćete da zahteve za čuvanje kolačića
            odobrite ili odbijete, izbrišete sačuvane kolačiće automatski pri zatvaranju internet pretaživača i slično.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Kako onemogućiti kolačiće?
            </h5>
            <p className="mb-4 font-weight-bold">
            Isključivanjem kolačića odlučujete da li hoćete da dopustite čuvanje kolačića na vašem računaru.
            Cookie postavke mogu da se kontrolišu i konfigurišu u vašem web pretraživaču. Za informacije o
            postavkama kolačića, odaberite web pretraživač koji koristite. • Chrome • Firefox • Internet Explorer
            9 • Internet Explorer 7 i 8 • Opera (stranica na engleskom jeziku) • Safari stranica na engleskom). Ako
            onemogućite kolačiće, nećete moći da koristite neke od funkcionalnosti na ovim web stranicama.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Šta su privremeni kolačići?
            </h5>
            <p className="mb-4">
            Privremeni kolačići ili kolačići sesije uklanjaju se sa računara po zatvaranju internet pretraživača. Pomoću
            njih web mesta skladište privremene podatke, poput stavki u korpici za kupovinu
            </p>
            <h5 className="mb-4 font-weight-bold">
            Šta su stalni kolačići?
            </h5>
            <p className="mb-4">
            Stalni ili pripremljeni kolačići ostaju na računaru nakon zatvaranja programa internet pretraživača.
            Pomoću njih web mesta skladište podatke, kao što su ime za prijavu i lozinka, tako da nećete morati da
            se prijavljujete prilikom svake posete određenom mestu. Stalni kolačići ostaće na računaru danima,
            mesecima, čak i godinama.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Šta su kolačići od prve strane?
            </h5>
            <p className="mb-4">
            Kolačići prve strane dolaze sa web mesta koje gledate, a mogu biti stalni ili privremeni. Pomoću tih
            kolačića web mesta mogu da čuvaju podatke koje će ponovo koristiti prilikom sledeće posete tom web
            mestu.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Što su kolačići treće strane?
            </h5>
            <p className="mb-4">
            Kolačići treće strane dolaze sa reklama drugih web mesta (kao što su pop-up ili druge reklame) koje se
            nalaze na web mestu koje gledate. Pomoću tih kolačića web-mesta mogu da prate korišćenje Interneta u
            marketinške svrhe.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Da li GoGorila.com koristi kolačiće?
            </h5>
            <p className="mb-4">
            Da, s primarnim ciljem kako bi vam naše web stranice omogućile bolje korisničko iskustvo i jednostavniju
            upotrebu web kataloga.
            </p>
            <h5 className="mb-4 font-weight-bold">
            Kakve kolačiće koristi GoGorila.com i zašto?
            </h5>
            <p className="mb-4">
            na nekoliko spoljnih servisa koji korisniku skladište limitirane kolačiće. Ovi kolačići nisu postavljeni od
            strane ove web stranice, ali neki služe za normalno funkcionisanje određenih mogućnosti koje
            korisnicima olakšavaju pristup sadržaju. Trenutno omogućavamo:
            </p>
            <p className="mb-4">
            &bull; Društvene mreže
            </p>
            <p className="mb-4">
            Korisnici mogu da se prijave i registruju preko vlastitih naloga sa društvene mreže Facebook.
            Takođe, deljenje članaka na društvenim mrežama postavlja korisniku kolačiće.
            </p>
            <p className="mb-4">
            &bull; Merenje posećenosti
            </p>
            <p className="mb-4">
            GoGorila.com koristi servis za merenje posećenosti, Google Analytics. Ako želite da onemogućite da
            navedeni servisi skladište kolačiće, možete da ih zabranite na sledećem linku: 
                <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">Google Analytics - https://tools.google.com/dlpage/gaoptout</a>
            </p>

            <h5 className="mb-4 font-weight-bold">
            Dodatne informacija oko isključivanja kolačića
            </h5>
            <p className="mb-4">
            Trenutno postoji nekoliko web stranica za isključivanje skladištenja kolačića za različite servise.
            Više informacija možete dobiti na sledećim linkovima:
            </p>
            <p className="mb-4">
                <a href="http://www.allaboutcookies.org" rel="noopener noreferrer" target="_blank">http://www.allaboutcookies.org</a>
            </p>
            <p className="mb-4">
                <a href="http://www.youronlinechoices.eu" rel="noopener noreferrer" target="_blank">http://www.youronlinechoices.eu</a>
            </p>
        </div>
    };
}

export default Text7;
