import React from "react";
import {connect} from "react-redux";
import Lightbox from 'react-image-lightbox';
import {image} from "../../../app/helpers";

class ProductGallery extends React.Component {

    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    };

    toggleLightbox = () => {
        this.setState({...this.state, isOpen: !this.state.isOpen})
    };



    render = () => {
        let images = this.state.images;
        return <div>
            {this.state.isOpen && (
                <Lightbox
                    mainSrc={image(images[this.state.photoIndex], true)}
                    nextSrc={image(images[(this.state.photoIndex + 1) % images.length], true)}
                    prevSrc={image(images[(this.state.photoIndex + images.length - 1) % images.length], true)}
                    onCloseRequest={this.toggleLightbox}
                    onMovePrevRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + images.length - 1) % images.length})
                    }
                    onMoveNextRequest={() =>
                        this.setState({...this.state, photoIndex: (this.state.photoIndex + 1) % images.length})
                    }
                />
            )}

            <div className="row">
                <div className="ml-md-3 product_gallery">
                    {images.map((item, i) => {
                        const selected = images[this.state.photoIndex] === item ? "product_thumb_active" : "";
                        return  <div key={i} className="">
                            <div onClick={() => this.setState({...this.state, photoIndex: i})}
                                 className={`${selected} m-1 round border pointer`}>
                                <img src={image(item)} className="img-fluid" alt={item} />
                            </div>
                        </div>
                    })}
                </div>

                <div className="col">
                    <div onClick={this.toggleLightbox} className="pointer">
                        <img src={image(images[this.state.photoIndex], true)} className="img-fluid mb-3"
                             alt={this.props.product.name} />
                    </div>
                </div>
            </div>
        </div>
    };

    componentDidMount() {
        let images = this.props.product.images.map((item) => {
            return item.path;
        });
        this.setState({...this.state, images: images});
    }
}

const mapStateToProps = state => ({
    categories: state.categories
});
export default connect(mapStateToProps)(ProductGallery);
