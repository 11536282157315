import React from 'react';
import Timer from "../framework/Timer";
import {image} from "../../app/helpers";
import Loader from "../framework/Loader";
import {processPrice, adminPrice, discount} from "../../providers/price";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import {connect} from "react-redux";
import {addToWishlist, removeFromWishlist} from "../../store/actions/wishlistActions";
import {addWishlist, removeWishlist} from "../../providers/wishlist";
import {addToBasket} from "../../store/actions/basketActions";
import {openDrawer} from "../../store/actions/drawersActions";
import FormatPrice from "./FormatPrice";


class ProductRender extends React.Component {


    addToWishlist = () => {
        this.props.addToWishlist(this.props.item.id);
        addWishlist(this.props.item.id);
    }
    removeFromWishlist = () => {
        this.props.removeFromWishlist(this.props.item.id);
        removeWishlist(this.props.item.id);
    }

    addToBasket = () => {
        this.props.addToBasket({product: this.props.item, qty: 1, filters: null});
        this.props.openDrawer({name: "basketModal", item: this.props.item});
    };

    render() {
        const item = this.props.item;
        const inBasket = this.props.basket.find(basket => basket.id === item?.id);
        return (
            <div className="h-100 pl-2 pr-2 mb-2 position-relative">
                <div className="product_divider" />

                {item?.admin_discount_price && item?.admin_price && adminPrice(item) &&
                <div className="bg-primary font_08 text-white position-absolute font-weight-bold p-1 pl-2 pr-2" style={{top: 12, right: 0}}>
                    {discount(item)}%
                </div>}

                <div className="d-flex flex-column h-100">
                    {item &&
                    <>
                        <div className="ml-2 mr-2 ml-md-4 mr-md-4 mb-2 rounded overflow-hidden bg-white">
                            <Link to={routes.product.route(item)}>
                                <div className="bcg_image" style={{backgroundImage: `url("${image(item.image_path)}")`}}/>
                            </Link>
                        </div>

                        <div className="ml-2 mr-2 text-center font-weight-bold">
                            <div>
                                     <span className="text-primary font_2">
                                         <FormatPrice price={processPrice(item)} />
                                    </span>
                                {item.admin_discount_price && adminPrice(item) &&
                                <del className="text-black-50 font-weight-normal ml-md-2 d-block d-md-inline-block" style={{lineHeight: 1}}>
                                    <FormatPrice price={item.admin_discount_price} />
                                </del>}
                            </div>

                            {item.admin_price && adminPrice(item) &&
                            <div className="mt-1">
                                <Timer size="small" end={item.countdown_end} />
                            </div>}

                            <div className="overflow-hidden weight_500" style={{maxHeight: 70}}>
                                <Link to={routes.product.route(item)}>
                                    {item.name}
                                </Link>
                            </div>
                        </div>

                        {this.props.buttons &&
                        <div className="ml-2 mr-2" style={{marginTop: "auto"}}>
                            <div className="mb-2">
                                {inBasket
                                    ? <Link to={routes.basket.route}
                                            className="btn btn-success btn-block btn-sm text-white font-weight-bold mr-1 flex-grow-1">
                                        <i className="fa fa-shopping-cart mr-1" /> DODATO
                                    </Link>
                                    :  <button onClick={this.addToBasket}
                                               className="btn btn-white btn-block btn-sm font-weight-bold mr-1 flex-grow-1">
                                        <i className="fa fa-shopping-cart mr-1" /> DODAJ U KORPU
                                    </button>}
                            </div>

                            {this.props.wishlist.includes(item.id)
                                ? <button onClick={this.removeFromWishlist}
                                          className="btn btn-primary btn-block btn-sm font-weight-bold">
                                    <i className="fa fa-heart mr-1" /> UKLONI IZ LISTE
                                </button>
                                : <button onClick={this.addToWishlist}
                                    className="btn btn-white btn-block btn-sm font-weight-bold">
                                    <i className="fa fa-heart mr-1" /> LISTA ŽELJA
                                </button>}
                        </div>}
                    </>}
                    {!item &&
                    <Loader opacity={0.2} />}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    wishlist: state.wishlist,
    basket: state.basket
});
const mapDispatchToProps = {
    addToWishlist: addToWishlist,
    removeFromWishlist: removeFromWishlist,
    addToBasket: addToBasket,
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductRender);
