import React from 'react';


class FiltersBlock extends React.Component {

    state = {
        limit: 6,
        open: false
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({...this.state, open: !this.state.open});
    }

    render() {
        const items = this.props.items;
        return (
            <div className="border-bottom mb-3">
                {items.map((filter, i) => {
                    if(i >= this.state.limit && !this.state.open) return "";
                    return <div key={filter.filter_id}>
                        <label className="chbx block_label">
                            <input
                                value={filter.filter_id}
                                checked={this.props.selected[filter.parent_filter_id]?.includes(filter.filter_id)}
                                onChange={() => this.props.toggleFilter(filter)}
                                type="checkbox" /><span className="mr-2" />
                            {filter.filter_name}
                        </label>
                    </div>
                })}
                {this.state.limit < items.length &&
                <div className="text-center">
                <a onClick={this.toggle}
                    href="/" className="text-primary">
                    {this.state.open ? "zatvori" : "prikaži sve"}
                </a>
                </div>}
            </div>
        );
    }
}
export default FiltersBlock;
