import React from 'react';
import SearchButton from "../framework/SearchButton";
import routes from "../../app/routes";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getProducts} from "../../app/hooks";
import {processPrice} from "../../providers/price";
import {image} from "../../app/helpers";
import Overlay from "../framework/Overlay";
import {closeDrawer} from "../../store/actions/drawersActions";
import FormatPrice from "../elements/FormatPrice";


class MainSearch extends React.Component   {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    state = {
        show: false,
        value: "",
        products: [],
        categories: [],
        cursor: false,
    }

    close = () => {
        this.setState({...this.state, show: false});
        this.props.closeDrawer("search");
    };

    focus = () => {
        this.setState({...this.state, show: true});
        this.inputRef.current.focus();
    }

    changeInput = (e)  => {
        if(e.target.value)      {
            this.load(e.target.value);
            this.setState({...this.state, value: e.target.value, show: true});
        }
        else        {
            this.setState({...this.state, value: "", show: false, products: []});
        }
    }

    keyDown = (e) => {
        let code = e.keyCode;
        let cursor = this.state.cursor !== false ? this.state.cursor : -1;
        let max = this.state.categories.length + this.state.products.length;
        let set = false;
        let show = true;
        if(code === 40)     { // DOWN
            if(cursor === (max-1))     set = 0;
            else                       set = cursor + 1;
        }
        else if(code === 38)     { // up
            if(cursor === 0)           set = max - 1;
            else                       set = cursor - 1;
        }
        else if(code === 13)     { // ENTER
            if(cursor === false) return false;
            if(cursor < this.state.categories.length)       {
                let item = this.state.categories[cursor];
                if(!item) return false;
                this.props.history.push(routes.shop.route(item.id));
                show = false;
            }
            else if(cursor < (this.state.categories.length + this.state.products.length))    {
                let item = this.state.products[cursor - this.state.categories.length];
                this.props.history.push(routes.product.route(item));
                show = false;
            }
        }
        else  return false;
        e.preventDefault();
        this.setState({...this.state, cursor: set, show: show});
    };

    submit = (e) => {
        e.preventDefault();
        this.props.history.push(routes.shop.route() + "?search=" + this.state.value);
        this.setState({...this.state, show: false});
    }

    render = () => {
        let autocomplete = (this.state.products.length > 0 || this.state.categories.length > 0) && this.state.show;
        let inputClasses = "form-control form-control-lg";
        if(autocomplete) inputClasses += " input_rounded_top";
        return (<>
                <div className={this.state.show ? "position-relative search_fixed" : "position-relative"}>
                    <form onSubmit={this.submit} action={routes.dashboard} method="GET">
                        <input ref={this.inputRef} type="text"
                               autoComplete="off"
                               name="pretraga"
                               style={{borderRadius: 10}}
                               onFocus={this.focus}
                               value={this.state.value}
                               onChange={this.changeInput}
                               onKeyDown={this.keyDown}
                               className={inputClasses}
                               placeholder="Pretraga..." />
                        <SearchButton />
                    </form>

                    {autocomplete &&
                    <div className="search_autocomplete shadow">
                        {this.state.categories.map((item, index) => {
                            return <div key={item.id}
                                        className={this.state.cursor === index ? "search_autocomplete_active" : ""}>
                                <Link
                                    className="d-inline-block w-100 pt-2 pb-2"
                                    onClick={this.close}
                                    to={routes.shop.route(item.id)}>
                                    <span className="text-black-50">Kategorija: </span>
                                    <strong className="ml-2">{item.name}</strong>
                                    <span className="ml-1 font-weight-normal font_09 text-black-50">({item.count})</span>
                                </Link>
                            </div>
                        })}

                        {this.state.products.map((item, index) => {
                            return <div key={item.id} className={this.state.cursor === (index + this.state.categories.length) ? "search_autocomplete_active" : ""}>
                                <Link onClick={this.close} to={routes.product.route(item)} className="d-block">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-3 p-1">
                                            <img src={image(item.image_path)} width={60} className="search_autocomplete_image" alt="slika" />
                                        </div>
                                        <div className="">
                                            <div>{item.name}</div>
                                            <div className="text-primary">
                                                <FormatPrice price={processPrice(item)} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })}
                    </div>}
                </div>
                <Overlay close={this.close} show={this.state.show} />
            </>
        )
    };

    load = (search) => {
        let categories = [];
        if(search && search.length > 1)     {
            categories = Object.values(this.props.categoriesById).filter((item) => {
                let name = item.name.toLowerCase()
                    .replace(/[čć]/gi, 'c')
                    .replace(/[š]/gi, 's')
                    .replace(/[ž]/gi, 'z')
                let srch = search.toLowerCase()
                    .replace(/[čć]/gi, 'c')
                    .replace(/[š]/gi, 's')
                    .replace(/[ž]/gi, 'z')
                return name.search(srch) > -1
            });
        }
        let form = {search: search, limit: 10, autocomplete: true};
        if(this.props.tracked.length > 0)   {
            form = {...form, tracked: this.props.tracked.slice(0, 5)};
        }
        getProducts(form).then((response) => {
            this.setState({...this.state, products: response.data?.products?.data || [], categories: categories.slice(0, 5),  show: true});
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.search.status !== prevProps.search.status) && this.props.search.status)        {
            this.focus();
        }
    }
}
const mapStateToProps = state => ({
    categoriesById: state.categoriesById,
    search: state.drawers.search,
    tracked: state.tracked
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MainSearch);
