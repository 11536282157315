import React from 'react';
import {connect} from "react-redux";
import {image} from "../../../app/helpers";
import {Link} from "react-router-dom";


class IndexSlider extends React.Component {

    state = {
        index: 0,
        loaded: []
    }
    slider = null;
    isDown = false;
    startX = null;
    scrollLeft = null;
    auto = true;
    autoSide = "right";

    banner = (item) => {
        return <>
            <div className="h-100 d-none d-md-block"
                 style={{backgroundImage: `url(${image(item.image_path, false, item.image_bucket)})`, backgroundSize: "cover"}} />

            {item.show_mobile &&
            <img src={image(item.mobile_image_path || item.image_path, false, item.image_bucket)} className="w-100 d-md-none" alt={item.alt} />}

        </>
    }

    render = () => {
        const indexslider = this.props.banners?.indexslider || [false];
        return <div className="h-100 position-relative arrows_hoverable">
            {indexslider.length > 1 &&
            <div className="arrows d-none d-md-block">
                {this.state.index > 0 &&
                <button onClick={() => this.scrolling(null, "left")} className="btn btn-link arrow_left shadow">
                    <i className="fa fa-chevron-left" />
                </button>}
                {this.state.index < (indexslider.length-1) &&
                <button onClick={() => this.scrolling(null, "right")} className="btn btn-link arrow_right shadow">
                    <i className="fa fa-chevron-right" />
                </button>}
            </div>}

            <div className="round h-100">
                <div className="index_slider scroll_slider h-100">
                    <div className="scroll_slider_wrapper h-100">
                        {indexslider.map((item, i) => {
                            if(!item.link)    {
                                return <div key={i} className="h-100 w-100">
                                    {this.banner(item)}
                                </div>
                            }
                            if(item.link.startsWith("http://") || item.link.startsWith("https://")) {
                                return <div key={i} className="h-100 w-100">
                                    <a href={item.link} target={item.blank ? "_blank" : ""}  rel="noreferrer">
                                        {this.banner(item)}
                                    </a>
                                </div>
                            }
                            return <div key={i} className="h-100 w-100">
                                <Link to={item.link} target={item.blank ? "_blank" : ""}>
                                    {this.banner(item)}
                               </Link>
                              </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    };


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    scrolling = async (pageX, side = false) =>  {
        if(window.innerWidth < 760 && !side) return false;
        if(!this.props.banners?.indexslider) return false;
        let width = this.slider.clientWidth;
        let ticks = 50;
        let time = 300;
        let index = this.state.index;
        if(side === "left")        {
            if(this.state.index > 0)  {
                index = this.state.index - 1;
            }
            time = 300;
            ticks = 10;
        }
        else if(side === "right")        {
            if(this.state.index < this.props.banners?.indexslider.length-1)  {
                index = this.state.index + 1;
            }
            time = 300;
            ticks = 15;
        }
        else if((this.startX - pageX) > 0)  {
            index = Math.ceil(this.slider.scrollLeft/width);
        }
        else        {
            index = Math.floor(this.slider.scrollLeft/width);
        }
        this.setState({...this.state, index: index});
        const dest = index * width
        const curr = this.slider.scrollLeft;
        const perc = (dest - curr) / ticks;
        for(let i=1; i< (ticks+1); i++)     {
            if(this.isDown) break;
            await this.sleep(time/ticks);
            this.slider.scrollLeft = curr + (i * perc);
        }
    }

    componentDidMount() {
        this.slider = document.querySelector('.index_slider');
        setInterval(() => {
            if(this.auto)   {
                if(this.state.index === 0)        {
                    this.autoSide = "right";
                }
                else if((this.props.banners?.indexslider.length-1) === this.state.index)      {
                    this.autoSide = "left";
                }
                this.scrolling(null, this.autoSide);
            }
        }, 5000);

        this.slider.addEventListener('click', (e) => {
            if(Math.abs((this.startX + this.slider.offsetLeft) - e.pageX) > 3)  {
                e.preventDefault();
            }
        });
        this.slider.addEventListener('mousedown', (e) => {
            this.isDown = true;
            this.startX = e.pageX - this.slider.offsetLeft;
            this.scrollLeft = this.slider.scrollLeft;
        });
        /*this.slider.addEventListener('mouseleave', (e) => {
            this.isDown = false;
            this.scrolling(e.pageX);
        });*/
        this.slider.addEventListener('mouseup', (e) => {
            this.isDown = false;
            this.auto = false;
            this.scrolling(e.pageX);
        });
        this.slider.addEventListener('touchend', (e) => {
            this.isDown = false;
            this.auto = false;
            this.scrolling(e.changedTouches[0].clientX);
        });
        this.slider.addEventListener('touchstart', (e) => {
            this.isDown = true;
            this.startX = e.touches[0].clientX;
            this.setState({...this.state, loaded: [0,1,2,3,4,5,6,7,8,9,10]});
        });
        this.slider.addEventListener('scroll', (e) => {
            //console.log("asfdasdfas")
        });
        this.slider.addEventListener('mousemove', (e) => {
            if(!this.isDown) return;
            e.preventDefault();
            const x = e.pageX - this.slider.offsetLeft;
            const walk = (x - this.startX) ; //scroll-fast
            this.slider.scrollLeft = this.scrollLeft - walk;
        });
    }
}
const mapStateToProps = state => ({
});
export default connect(mapStateToProps)(IndexSlider);
