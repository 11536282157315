import React from "react";


class User extends React.Component {


    render = () => {
        let spread = {}
        if (this.props.width) {
            spread.width = this.props.width;
        }
        return  <svg {...spread} version="1.0" xmlns="http://www.w3.org/2000/svg" className={this.props.className}
	 viewBox="0 0 100 100">
<g>
	<path d="M83.334,89.5c-1.104,0-2-0.896-2-2v-8.334c0-8.087-6.58-14.666-14.668-14.666H33.333c-8.087,0-14.667,6.579-14.667,14.666
		V87.5c0,1.104-0.896,2-2,2s-2-0.896-2-2v-8.334c0-10.292,8.374-18.666,18.667-18.666h33.333c10.294,0,18.668,8.374,18.668,18.666
		V87.5C85.334,88.604,84.438,89.5,83.334,89.5z"/>
</g>
<g>
	<path d="M50,47.833c-10.293,0-18.667-8.374-18.667-18.667S39.707,10.5,50,10.5c10.292,0,18.666,8.374,18.666,18.667
		S60.292,47.833,50,47.833z M50,14.5c-8.087,0-14.667,6.58-14.667,14.667c0,8.087,6.58,14.667,14.667,14.667
		s14.666-6.58,14.666-14.667C64.666,21.08,58.087,14.5,50,14.5z"/>
</g>
</svg>
    }

}

export default User;
