import React from 'react';
import {connect} from "react-redux";
import {getProducts} from "../../../app/hooks";
import ProductRender from "../../elements/ProductRender";


class Wishlist extends React.Component {

    state = {
        products: []
    }

    render() {
        return (
            <div className="container">
                {this.props.wishlist?.length === 0
                    ? <div className="font-weight-bold font_2 p-5 text-center">
                        <div>
                            <i className="fa fa-heart"/>
                        </div>
                        Lista želja je prazna.
                    </div>
                    : <div className="row no-gutters">
                        {this.state.products.map((item) => {
                            return <div key={item.id} className="col-md-2 col-6">
                                <ProductRender item={item} buttons={true} />
                            </div>
                        })}
                    </div>}
            </div>
        );
    }

    load = () => {
        if(this.props.wishlist?.length === 0) {
            this.setState({...this.state, products: []});
            return false;
        }
        getProducts({ids: this.props.wishlist}).then((response) => {
            this.setState({...this.state, products: response.data});
        })
    }

    componentDidMount() {
       this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.wishlist?.length !== prevProps.wishlist?.length)  {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    wishlist: state.wishlist,
});
export default connect(mapStateToProps)(Wishlist);
