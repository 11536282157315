import axios from "axios";
import {server} from "./helpers";


export async function getProducts(form) {
    return await axios.get(server("products"), {params: form});
}

export async function getCategories(form) {
    return await axios.get(server("categories"), {params: form});
}
export async function getFilters(form) {
    return await axios.get(server("filters"), {params: form});
}

export async function getBlocks(form) {
    return await axios.get(server("interface/blocks"), {params: form});
}
export async function getBanners(form) {
    return await axios.get(server("interface/banners"), {params: form});
}
export async function getBrands(form) {
    return await axios.get(server("brands"), {params: form});
}

export async function getSupliers(form) {
    return await axios.get(server("suppliers"), {params: form});
}

export async function getOrders(form) {
    return await axios.get(server("orders"), {params: form});
}
export async function getOrderByCode(form) {
    return await axios.get(server("order/code"), {params: form});
}
export async function getUserOrders(form) {
    return await axios.get(server("user/orders"), {params: form});
}
export async function putOrder(form) {
    return await axios.put(server("orders"), form);
}
export async function prepareCheckout(form) {
    return await axios.post(server("order/checkout/prepare"), form);
}

export async function getCurrencies() {
    return await axios.get(server("currencies"));
}


export async function postLoginUser(form) {
    return await axios.post(server("user/login"), form);
}
export async function postLoginUserByGoogle(user) {
    return await axios.post(server("user/google"), user);
}
export async function postLoginUserByFacebook(user) {
    return await axios.post(server("user/facebook"), user);
}
export async function postRegisterUser(form) {
    return await axios.post(server("user/register"), form);
}
export async function postRegisterVerification(form) {
    return await axios.post(server("verification"), form);
}
export async function postPasswordRecovery(form) {
    return await axios.post(server("user/password/recovery"), form);
}
export async function postPasswordReset(form) {
    return await axios.post(server("user/password/reset"), form);
}
export async function getUserInfo(form) {
    return await axios.get(server("user"), form);
}
export async function patchUser(form) {
    return await axios.patch(server("user"), form);
}
export async function deleteUser(form) {
    return await axios.delete(server("user"), form);
}

export async function postAvailability(form) {
    return await axios.post(server("availability"), form);
}

export async function postContactUs(form) {
    return await axios.post(server("contact-us"), form);
}