import React from "react";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";


class Text1 extends React.Component   {



    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5 footer_text">
            <h4 className="mb-4 title_underline">
                <span className="title">
                  OPŠTI USLOVI POSLOVANJA ZA KUPOVINU PUTEM SAJTA
                </span>
            </h4>

            <p className="mb-4">
                Dobrodošli na sajt GoGorila.com.<br /><br />
                U nastavku su sadržani opšti uslovi koji se primenjuju na svaku kupovinu putem sajta GoGorila, te Vas
                molimo da ih pažljivo pročitate pre kupovine u našoj e-prodavnici. Na taj način ćete biti obavešteni
                o svim pravima i obavezama koje imate kao kupac naših proizvoda. Poručivanjem i kupovinom putem
                našeg Sajta, potvrđujete da ste se upoznali sa ovim opštim uslovima i da ih u potpunosti prihvatate.
                <br /><br />
                Uslovima korišćenja definisana su pravila pri kupovini koja obezbeđuju kvalitet naše usluge i
                zadovoljstvo kupaca.
                <br /><br />
                GoGorila doo Vam omogućava korišćenje usluga i sadržaja svog sajta koje je podložno niže navedenim
                Uslovima korišćenja. Uslovi korišćenja se primenjuju na sve sadržaje i usluge GoGorila.com. Korišćenjem
                bilo kog dela sajta, smatra se da su korisnici upoznati sa ovim uslovima, kao i da prihvataju korišćenje
                sadržaja ovog sajta isključivo za ličnu upotrebu i na sopstvenu odgovornost. Nedozvoljeno korišćenje
                sajta smatra se kršenjem pravila i podložno je tužbi.
                <br /><br />
                Kupovinom putem našeg sajta, zaključujete Ugovor o prodaji na daljinu sa:<br />
                <div className="ml-4">
                    <div className="font-weight-bold"> GoGorila doo, preduzeće za trgovinu na malo posredstvom pošte ili interneta </div>
                    sa sedištem na adresi Bulevar oslobođenja 17, Čačak;<br />
                    MB: 21608637;<br />
                    PIB: 112107021.
                </div>
                na koji će se primenjivati odredbe Zakona o elektronskoj trgovini, Zakona o zaštiti potrošača, Zakona
                o trgovini, Zakona o obligacionim odnosima i drugih važećih propisa Republike Srbije.
                <br /><br />
                GoGorila doo zadržava pravo izmene i/ili dopune ovih Opštih uslova u bilo kom trenutku. Zbog toga je
                neophodno da ih pre svake porudžbine/kupovine sa našeg sajta ponovo pročitate, kako biste bili
                blagovremeno upoznati sa eventualnim izmenama ili dopunama.
            </p>

            <div className="mb-5">
                1. <a href="#definicije"> DEFINICIJE </a><br />
                2. <a href="#odredbe"> OPŠTE ODREDBE </a> <br />
                3. <a href="#porucivanje">PORUČIVANJE</a> <br />
                4. <a href="#cene">CENE</a> <br />
                5. <a href="#placanje">PLAĆANJE</a> <br />
                    <div className="ml-4">
                        &bull; Plaćanje platnim karticama<br />
                        &bull; Izjava o konverziji plaćanja
                    </div>
                6. <a href="#isporuka">ISPORUKA</a>
                <div className="ml-4">
                    &bull; Troškovi isporuke<br />
                    &bull; Isporuka gabaritnih pošiljaka sa dva izvršioca<br />
                    &bull; Postupanje prilikom isporuke<br />
                    &bull; Otkazivanje isporuke
                </div>
                7. <a href="#zamena-proizvoda">ZAMENA PROIZVODA</a> <br />
                8. <a href="#odustanak-od-ugovora">ODUSTANAK OD UGOVORA</a> <br />
                9. <a href="#zastita-podataka-o-licnosti"> ZAŠTITA PODATAKA O LIČNOSTI </a><br />
            </div>


            <h4 id="definicije" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     1. DEFINICIJE
                </span>
            </h4>

            <p className="mb-5">
                <table className="table table-bordered">
                    <tr>
                        <td>1</td>
                        <td>
                            „Prodavac”, „Mi” ili „GoGorila” se odnosi na GoGorila doo, društvo sa ograničenom odgovornošću,

                            sa sedištem na adresi Bulevar oslobođenja 17, Čačak, MB: 21608637, PIB: 112107021, broj telefona:
                            064/860-15-54
                        </td>
                    </tr>
                    <tr><td>2</td> <td>
                        „Opšti uslovi poslovanja”, „Uslovi” ili „OUP” se odnosi na opšte uslove poslovanja koji se odnose
                        na kupovinu u našoj e-prodavnici.
                    </td></tr>
                    <tr><td>3</td><td>
                        „Kupac” ili „Vi” predstavlja svako fizičko lice koje poruči proizvod(e) putem našeg Sajta ili je
                        zainteresovano da poruči proizvoda putem našeg Sajta.
                    </td></tr>
                    <tr><td>4</td><td>
                        „Korisnik Sajta” predstavlja svako lice koje pristupi i koristi naš Sajt.
                    </td></tr>
                    <tr><td>5</td><td>
                        „Gost” predstavlja svakog Kupca koji kupuje naše proizvode bez registracije na Sajtu.
                    </td></tr>
                    <tr><td>6</td><td>
                        „Registrovani korisnik” predstavlja svakog Korisnika Sajta koji je registrovao nalog na Sajtu.
                    </td></tr>
                    <tr><td>7</td><td>
                        „Potrošač” jeste svako fizičko lice koje na tržištu pribavlja robu ili usluge u svrhe koje nisu
                        namenjene njegovoj poslovnoj ili drugoj komercijalnoj delatnosti i na koga se primenjuju propisi o
                        zaštiti potrošača.
                    </td></tr>
                    <tr><td>8</td><td>
                        „Kupoprodajni ugovor” ili „Ugovor” se odnosi na ugovor o kupoprodaji bilo kog proizvoda
                        Prodavca, koji uključuje odredbe OUP, kao i eventualne posebne uslove ugovorene između
                        Ugovornih strana.
                    </td></tr>
                    <tr><td>9</td><td>
                        „Sajt” označava sajt GoGorila, dostupan na sledećoj adresi: <br />
                        https://gogorila.com/
                    </td></tr>
                    <tr><td>10</td><td>
                        „Politika privatnosti” se odnosi na poseban akt koji sadrži objašnjenja o načinu obrade Vaših
                        podataka u vezi sa korišćenjem Sajta i u vezi sa kupovinom putem naše e-prodavnice – do Politike
                        možete doći klikom na  <Link to={routes.faq[3].route}>ovaj link</Link>.
                    </td></tr>
                    <tr><td>11</td><td>
                        „Politika kolačića” predstavlja poseban deo Politike privatnosti koji sadrži informacije i pravila o
                        prikupljanju podataka na Sajtu pomoću kolačića. Sa ovom Politikom možete se upoznati klikom
                        na  <Link to={routes.faq[4].route}>ovaj link</Link> .
                    </td></tr>
                    <tr><td>12</td><td>
                        „Ugovorne strane” su Kupac i Prodavac zajedno.
                    </td></tr>
                </table>
            </p>


            <h4 id="odredbe" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                   2. OPŠTE ODREDBE
                </span>
            </h4>

            <p className="mb-5">
                Opšti uslovi poslovanja primenjuju se na celokupno poslovanje Prodavca, što obuhvata ponudu,
                potvrdu porudžbina, isporuku i račune, kao i sve druge isprave vezane za prodaju proizvoda.
                <br/><br/>
                &bull; Svi posebni uslovi koji odstupaju od OUP, a naročito uslovi postavljeni od strane Kupca, biće
                obavezujući jedino ukoliko su posebno izričito ugovoreni i pisanim putem potvrđeni od strane
                Prodavca.
                <br/><br/>
                &bull; Svi artikli prikazani na sajtu su deo naše ponude i ne podrazumeva da su dostupni u svakom
                trenutku. Raspoloživost robe možete proveriti pozivanjem Call Centra na
                <a href="tel:+381 064 860 15 54">064/860-15-54</a> (po ceni lokalnog poziva)
                <br/><br/>
                &bull; Na našem sajtu ne postoji starosna granica za poručivanje robe, ali ako ste maloletna osoba
                obavezno je prisustvo punoletne osobe prilikom preuzimanja robe na kućnoj adresi.
                <br/><br/>
                &bull; Naručene proizvode isporučujemo isključivo na teritoriji Republike Srbije bez Kosova i Metohije.
                Ne postoje ograničenja u pogledu količine ili vrednosti poručenih proizvoda.
                <br/><br/>
                &bull; Svi sadržaji, specifikacije, proizvodi i cene proizvoda i usluga opisanih ili prikazanih na sajtu,
                mogu se promeniti u bilo kom trenutku bez prethodne najave. Uključivanje određenih proizvoda i
                usluga na sajt, u određeno vreme, ne garantuje da će isti proizvodi ili usluge biti dostupni u bilo
                koje vreme.
                <br/><br/>
                &bull; Trudimo se da sve informacije o proizvodima, oznake, mere i opisi budu što tačniji i precizniji, kao
                i sve ostale informacije koje možete dobiti na sajtu. Međutim može se desiti da su informacije na
                sajtu netačne, nepotpune ili zastarele, kao i informacije o proizvodima koji uključuju i primenjive
                boje, ne možemo garantovati precizno pirkaz boje jer to zavisi od ekrana vašeg
                računara/telefona. Napominjemo da su fotografije postavljene u informativne svrhe i da one
                mogu prikazivati određeni pribori koji se možda ne nalazi u standardnom paketu proizvoda.
                <br/><br/>
                &bull; Za korišćenje i kupovinu na sajtu nije potrebno registrovati korisični nalog. Ako koristite ovaj sajt,
                Vi ste odgovorni za održavanje poverljivosti vašeg naloga i lozinke i za ograničavanje pristupa
                svom računaru i saglasni ste da prihvatite odgovornost za sve aktivnosti koje se dešavaju pod
                vašim nalogom i lozinkom. Ako sumnjate da je bezbednost vašeg korisničkog naloga povređena,
                molimo Vas da obavestite administratora sajta što pre. GoGorila nije odgovoran za bilo kakvu
                štetu koja može nastati od neovlašćenog korišćenja vašeg korisničkog naloga.
                <br/><br/>
                &bull; Poručivanjem proizvoda, GoGorila kao vlasnik portala gogorila.com se ni u kom slučaju ne
                obavezuje da će prihvatiti kreiranu porudžbinu. U svakom trenutku bez predhodne najave
                porudžbina može biti otkazana.
                <br/><br/>
                &bull; Za DISKONT i OUTLET artikle porudžbine će se smatrati validnim tek nakon provere dostupnosti
                artikala (radi se o artiklima koji su najčešće poslednji komadi na lageru) i nakon usmenog /
                telefonskog obaveštenja korisniku od strane GoGorila doo operatera da je roba dostupna.
                <br/><br/>
                &bull; GoGorila neće ni u kom slučaju biti odgovorna po bilo kojoj osnovi, niti za bilo kakvu direktnu,
                indirektnu, slučajnu, posebnu, posledičnu ili kaznenu štetu prilikom korišćenja ovog sajta ili bilo
                kojih drugih hiperlinkovanih sajtova, uključujući, bez ograničenja, izgubljenu dobit ili prihode,
                troškove zamene, prekida poslovanja, gubitak podataka ili štete nastale upotrebom ili
                oslanjanjem na informacije sa sajta.
                <br/><br/>
                &bull; Svako neovlašćeno korišćenje bilo koje informacije ili materijala koji su prikazani ili dostupni na
                sajtu, može prekršiti Zakone o autorskim pravima, zaštiti brenda, Zakona o privatnosti i
                objavljivanju, kao i druge zakone i propise.
                <br/><br/>
                &bull; Posetioci mogu komentarisati proizvode i slati email poruke na
                <a href="mailto:web@gogorila.com">web@gogorila.com</a>. GoGorila ima
                pravo da ne objavi komentare koji su zlonamerni, šire mržnju, sadrže nepristojne izjave i na bilo
                koji način ugrožavaju prava drugih. Takvi komentari će biti uklonjeni bez ikakvog obaveštenja.
                Lažno predstavljanje i registracija sa tuđom email adresom nije dozvoljena na gogorila.com.
                Protiv takvih aktivnosti GoGorila može pokrenuti odgovarajući zakonski postupak
                <br/><br/>
                &bull; DODATNA PODRŠKA: Ukoliko ne razumete bilo koji element ovih uslova ili imate pitanje ili
                komentar, molimo da nas kontaktirate putem e-mail <a href="mailto:web@gogorila.com">web@gogorila.com</a> .
            </p>


            <h4 id="porucivanje" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     3. PORUČIVANJE
                </span>
            </h4>

            <p className="mb-5">
                Željene proizvode možete poručiti na tri načina: <br />
                - online putem sajta,<br />
                - pozivom našeg korisničkog centra ili<br />
                - slanjem poruke na naš email.
                <br /><br />
                Kupac ima mogućnost izbora poručivanja proizvoda u svojstvu Gosta ili u svojstvu Registrovanog
                korisnika. Ukoliko je prilikom prethodne kupovine već registrovao svoj nalog, Kupac može izvršiti
                poručivanje putem istog naloga.
                <br /><br />
                Poručivanje putem sajta možete obaviti u par klikova – kada se odlučite za željeni proizvod, kliknite na
                polje <b>”Dodaj u korpu“.</b> Odmah će vam izaći „prozor“ gde birate da li nastavljate sa poručivanjem (unosite
                tražene podatke) ili se vraćate nazad na sajt i nastavljate pretragu. Sadržaj korpe možete ažurirati ili
                izmeniti u bilo kom trenutku pre potvrđivanja porudžbine. U delu Informacije za poručivanje treba
                popuniti sva polja označena zvezdicom (*). Ukoliko poručujete kao pravno lice odaberite karticu „pravno
                lice“.
                <br /><br />
                U desnom delu ekrana imate deo gde odabirate <b>Način plaćanja</b>. Birate između:   <br />
                &bull; Plaćanje pouzećem (Plaćanje prilikom preuzimanja paketa kurirskoj službi)<br />
                &bull; Plaćanje preko računa<br />
                &bull; Plaćanje karticom.
                <br /><br />
                Ukoliko odaberete „Plaćanje preko računa“ i ukoliko poručite kao pravno lice u najkraćem mogućem roku
                će vam stići email od nas sa predračunom, po kome treba da izvršite uplatu (sa uključenim trškovima
                isporuke). Ukoliko porudžbinu izvršite posle radnog vremena našeg korisničkog servisa ili u dane državnih
                praznika, mejl sa predračunom će vam stići prvog narednog radnog dana.
                <br /><br />
                Ukoliko odaberete način plaćanja karticom, potrebno je da pratite instrukcije i da unesete tražene
                podatke.
                <br /><br />
                U odeljku <a href="#placanje">Plaćanje</a> imate detaljnije opisano sve načine plaćanja koji uključuju i plaćanje čekovima
                građana i kreditnim karticama na odloženo plaćanje.
                <br /><br />
                Klikom na dugme Poručite potvrđujete porudžbinu.
                <br /><br />
                Potvrđivanjem porudžbine na Sajtu u svojstvu Kupca, potvrđujete sledeće:<br />
                1. da su Vam poznati Opšti uslovi poslovanja, odnosno da Vam je pružena stvarna mogućnost da se
                upoznate sa njihovom sadržinom,<br />
                2. da su Vam, pre potvrđivanja porudžbine na Sajtu, na jasan i razumljiv način u skladu sa obavezom
                obaveštavanja Kupca pružena sva neophodna obaveštenja od strane GoGorila doo, kao i<br />
                3. da u svojstvu Kupca dajete svoju saglasnost na Opšte uslove poslovanja.
                <br /><br />
                Obaveza obaveštavanja u smislu tačke 2. naročito obuhvata informacije o:<br />
                &bull; osnovnim obeležjima robe;<br />
                &bull;  Prodavcu - poslovno ime, matični broj, adresu sedišta i broj telefona;<br />
                &bull; prodajnoj ceni, načinu obračuna prodajne cene i svim dodatnim troškovima, poput troškova<br />
                isporuke koje snosi Kupac;<br />
                &bull; načinu plaćanja, načinu i rokovima isporuke i o načinu izvršenja drugih ugovornih obaveza;<br />
                &bull; načinu izjavljivanja reklamacija i mestu prijema reklamacija, kao i o načinu postupanja Prodavca
                po njima;<br />
                &bull; zakonskoj odgovornosti zbog nesaobraznosti proizvoda;<br />
                &bull; uslovima koji se odnose na ostvarivanje prava Kupca po osnovu nesaobraznosti;<br />
                &bull; uslovima za raskid Ugovora.
                <br /><br />
                Po izvršenoj porudžbini, na email adresu koju ste naveli u formularu za poručivanje stići će automatski
                mail kojim potvrđujemo prijem Vaše porudžbine. Naša služba internet prodaje će odmah po prijemu
                porudžbine izvršiti proveru da li je poručeni proizvod raspoloživ, o čemu ćemo Vas obavestiti u roku od
                najviše 5 radnih dana.<br /><br />
                &bull; U slučaju da nismo u mogućnosti da Vam dostavimo željeni proizvod, nakon što Vas obavestimo
                o tome Vaša porudžbina će se smatrati otkazanom.<br />
                &bull; Ukoliko je moguća isporuka jednog dela Vaše porudžbine, naša služba internet prodaje će Vas
                obavestiti o tome. U tom slučaju, uz Vašu saglasnost, izvršićemo isporuku dela porudžbine koji je
                raspoloživ.<br />
                &bull; Ukoliko smo u mogućnosti da u celosti izvršimo Vašu porudžbinu, poslaćemo Vam mejl potvrde
                porudžbine.<br />
                Ukoliko imate dodatnih pitanja u pogledu Vaše porudžbine, možete nas kontaktirati putem email
                adrese <a href="mailto:web@gogorila.com">web@gogorila.com</a>  ili pozivom na broj <a href="tel:+381 064 860 15 54">064/860-15-54</a>.
            </p>

            <h4 id="cene" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                       4. CENE
                </span>
            </h4>

            <p className="mb-5">
                Cena svakog proizvoda je u dinarima (RSD) i naznačena je pored slike proizvoda. Cene uključuju PDV.
                <br/><br/>
                Cene istaknute na našem Sajtu važe isključivo za kupovinu putem interneta.
                <br/><br/>
                Troškovi isporuke su 350,00 za sve pakete čija masa ne prelazi 30 kg, a u suprotnom naknadno ćemo vas
                obavestiti o troškovima isporuke, a na osnovu adrese na koju se paket isporučuje i težine i gabarita
                paketa, o čemu više možete videti u odeljku  <a href="#isporuka">Isporuka</a> .
            </p>

            <h4 id="placanje" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                       5. PLAĆANJE
                </span>
            </h4>

            <p className="mb-5">
                Plaćanje proizvoda naručenih putem GoGorila internet prodavnice moguće je izvršiti: <br /><br />
                1. <b>Gotovinom</b> - pouzećem, prilikom preuzimanja robe na željenoj adresi.<br /><br />

                2. <b>uplatom na račun</b> GoGorila doo i to na račun broj 170-30050173000-32 koji se vodi kod UniCredit
                banke ili u skladu sa instrukcijama koje Vam pošaljemo;<br /><br />
                3. <b>Platnim karticama</b> - elektronskim putem, pomoću Visa, Mastercard ili Maestro debitnih kartica za
                internet plaćanje<br /><br />
                4. <b>Odloženo plaćanje - plaćanje na rate</b> i to:<br /><br />
                <div className="ml-4">
                    – čekovima građana, do 12 meseci uz obavezno učešće, minimalni iznos čeka je 3.000 dinara.
                    Potrebno je kontaktirati call centar u vezi sa instrukcijama.
                    <br/><br/>
                    – kreditima UniCredit banke, na 3, 6 ili 12 rata. Ukoliko nemate kreditnu karticu koju je izdala
                    UniCredit banka uslove dobijanja Flexia Mastercard kreditne kartice možete dobiti na linku: <br />
                    <a href="https://www.unicreditbank.rs/rs/pi/kredit/kartice.html" target="_blank" rel="noreferrer">
                        https://www.unicreditbank.rs/rs/pi/kredit/kartice.html
                    </a>  ili pozivom Call centra

                </div><br/>
                U slučaju plaćanja gotovinom, prilikom preuzimanja paketa kurir će vam predati proizvode koje ste
                poručili, kao i račun uz njih.
            </p>

            <h4 className="font-weight-bold mb-3">
                PLAĆANJE PLATNIM KARTICAMA
            </h4>
            <p className="mb-4">
                Plaćanje proizvoda na našoj internet prodavnici je moguće izvršiti na jedan od sledećih načina: platnim
                karticama - VISA, Maestro ili MasterCard koje podržavaju plaćanje preko Interneta. Plaćanje karticama je
                realizovano u saradnji sa AllSecure doo i UniCredit Bank Beograd i obavlja se na bezbedan i sertifikovan
                način preko AllSecure Paymet Gateway-a, jednostavnim unosom podataka sa platne kartice.
                <br /><br />
                Nakon što se unesu podaci o kartici i potvrdi plaćanje, banka autorizuje transakciju i time je porudžbina
                odobrena i ulazi u dalji proces pripreme za isporuku. Iznos će biti rezervisan na vašoj kartici (računu) i
                neće Vam biti raspoloživ za drugu namenu.
                <br /><br />
                Transkacija će biti kompletirana i iznos skinut sa vašeg računa tek kada su proizvodi pripremljeni za
                transport i predati kurirskoj službi. U slučaju da se plaćanje ne kompletira, odnosno iznos se ne skine sa
                računa do isteka 14 dana od prihvatanja Vaše porudžbine, ta porudžbenica će biti otkazana i izbrisana.
                Nakon isteka roka od 14 dana, novac rezervisane na Vašem računu se oslobadjaju i biće Vam ponovo na
                raspolaganju. Nakon toga možete ponoviti istu ili novu porudžbinu, i izvršiti plaćanje vezano za njih.
                Proverite kod banke koja je karticu izdala da li Vaša kartica podržava plaćanje preko Interneta.
            </p>

            <h5 className="font-weight-bold mb-3">
                Zaštita podataka prilikom plaćanja karticama
            </h5>
            <p className="mb-4">
                Prilikom unošenja podataka o platnoj kartici, poverljive informacije se prenose putem javne mreže u
                zaštićenoj (kriptovanoj) formi upotrebom SSL protokola, primenom najsavremenijih metoda tokenizacije
                osetljivih podataka, i u skladu sa PCI-DSS standardima. Sigurnost podataka prilikom kupovine garantuje
                procesor platnih kartica UniCredit Bank Beograd. Niti jednog trenutka podaci o platnoj kartici nisu
                dostupni trgovcu.
                <br /><br/>
                3D Secure zaštita za sve trgovce i kupce - AllSecure Payment Gateway koristi najviše globalne standarde
                zaštite i privatnosti podataka. Svi trgovci koji koriste AllSecure Payment Gateway su automatski uključeni
                u 3D-Secure zaštitu, čime se kupcima garantuje bezbednost kupovine. Brojevi platnih kartica kupaca se
                ne čuvaju na sistemu trgovca a sami upis se štiti SSL enkripcijom podataka.
                <br /><br/>
                PCI DSS Standardi - AllSecure Payment Gateway se konstantno usaglašava sa svim zahtevima kartičarskih
                organizacija u cilju povećanja nivoa bezbednosti trgovaca i kupaca. Od 2005 godine do danas, bez
                prekida, sistem je sertifikovan kao PCI-DSS Level 1 što predstavlja najviši standard u industriji. PCI Data
                Security Standard (PCI-DSS) je norma koja definiše neophodne mere bezbednosti pri obradi, čuvanju i
                prenošenju osetljivih kartičarskih podataka. PCI Standardima se štite osetljivi podaci o korisniku kartice
                tokom celog procesa plaćanja: od momenta unosa podataka na prodajnom mestu trgovca, tokom
                komunikacija između trgovca i relevantnih banaka i kartičarskih organizacija, kao i kasnije čuvanje tih
                podataka.
            </p>
            <h5 className="font-weight-bold mb-3">
                Registracija metoda plaćanja
            </h5>
            <p className="mb-4">
                Kada se podaci o kartici kupca, na njegov zahtev ili odlukom trgovca TOKENIZUJU, (pothranjuju na
                sertifikovan način za kasnije korišćenje), ti podaci se čuvaju na AllSecure PCI-DSS level 1 sertifikovanim
                serverima. Tako tokenizovani kartični podaci se prenose na potpuno bezbedan i sertifikovan način.
                Trgovac ni u jednom trenutku nema pristup podacima o broju kartice i drugim osetljivim podacima, već
                dobija referentni broj (token) koji samo on može koristiti u skladu sa uslovima ugovorenim sa kupcem.
                Kupac ima mogućnost deregistracije prethodno registrovane kartice, kao i promene detalja ranije
                tokenizovane kartice.
            </p>
            <h5 className="font-weight-bold mb-3">
                Povraćaj sredstava
            </h5>
            <p className="mb-4">
                U slučaju povraćaja sredstava kupcu koji je predhodno platio nekom od platnih kartica, delimično ili u
                celosti, a bez obzira na razlog vraćanja, taj povraćaj se vrši isključivo preko iste VISA, Maestro ili
                MasterCard kartice koja je korištena za plaćanje. Ovo znači da će naša banka na naš zahtev obaviti
                povraćaj sredstava na račun korisnika kartice.
            </p>

            <h4 className="font-weight-bold mb-3">
                IZJAVA O KONVERZIJI PLAĆANJA
            </h4>
            <p className="mb-4">
            &quot;Izjava o konverzijama - Sva plaćanja će biti obavljenima u dinarima (RSD). Ukoliko se plaća platnim
            karticama inostranih Banaka izdavalaca, dinarski iznos transakcije će biti konvertovan u settlement
            valutu Banke (EUR) prema kursu Narodne Banke Srbije. Pri zaduživanju Vaše platne kartice, već
            konvertovan iznos će se konvertovati u Vašu lokalnu valutu, prema kursu koji primenjuju operatori
            platnih kartica.&quot;
                <br/><br/>
            &quot;Please note that all payments will be effected in the Serbian currency – dinar (RSD). If the payment is
            done using foreign issuers payment cards, total amount of transaction will be converted into bank
            settlement currency, according to the current exchange rate of the Serbian National Bank. Once when
            transaction is settled the already converted amount will be converted into your local currency according
            to the exchange rate of credit card associations. As a result of this conversion there is a possibility of a
            slight difference from the original price stated in our web site.&quot;
                <br/><br/>
                <strong> GOGORILA doo Čačak </strong><br/>
            Bulevar oslobođenja 17<br/>
            PIB: 112107021<br/>
            Matični broj: 21608637
            </p>


            <h5 id="isporuka" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                        6. ISPORUKA
                </span>
            </h5>

            <p className="mb-4">
                Isporuka proizvoda koje poručeite putem našeg sajta biće dostavljeni, na teritoriji Srbije, u saradnji sa
                kurirskim službama D-Express i City express.<br/><br/>
                Isporuka robe vrši se isključivo na teritoriji Republike Srbije bez KiM. Isporuka van teritorije Srbije nije
                moguća. <br/><br/>
                Poručeni proizvodi dostavljaju se Vama lično ili licu koje ste naznačili kao primaoca pošiljke u roku od 2
                do 5 radna dana, a najkasnije u roku od 30 dana. Trudimo se da kupcima obezbedimo ažurnu i brzu
                dostavu poručenih proizvoda. Isporuka o roku ne predstavlja bitan element Ugovora.
                Pošiljka se dostavlja na adresu koju ste naveli u formularu za poručivanje. Isporuka robe se vrši radnim
                danima (ponedeljak – petak) u periodu od 8 do 17 časova, osim državnim praznicima. Isporuke robe se ne
                vrše subotom i nedeljom.<br/><br/>
                Svi naručeni artikli za koje se vrši isporuka isporučuje se do prvih vrata naručioca (do ulaza u zgradu ili
                kuću).<br/><br/>
                U slučaju da kurirska služba ne zatekne nikoga na adresi isporuke, kurir će Vas kontaktirati telefonom
                radi dogovaranja novog termina isporuke. Ukoliko i nakon toga dostavljanje ne bude moguće krivicom
                Kupca, odnosno lica koje je naznačeno kao primalac pošiljke, Kupac će biti dužan da snosi troškove
                vraćanja pošiljke Prodavcu, a Ugovor će se smatrati raskinutim.
            </p>

            <h4 className="font-weight-bold mb-3">
                TROŠKOVI ISPORUKE
            </h4>
            <p className="mb-4">
                <b>Za sve porudžbine čija je masa do 30 kg, isporuka se naplaćuje u visini od 350 RSD.</b>
                <br/><br/>
                Navedena cena isporuke važi za svaki pojedinačni paket koji može obuhvatati više proizvoda, a zbirno ne
                prelazi težinu od 30 kg. Ukoliko nije moguće spakovati više poručenih artikala u jedan paket, svaki
                upakovini paket računaće se po ceni od 350,00 RSD. Na primer: poručeno je dva bojlera – naplaćuje se
                dva paketa po 350,00 dirana.
                <br/><br/>
                <b>Za sve porudžbine čija masa prelazi 30 kg, pakete velikih gabarita, isporuka bele tehnike, troškovi
                    isporuke se naplaćuje po posebnom cenovniku.</b>
                <br/><br/>
                <b>Troškovi isporuke TELEVIZORA VEĆIH OD 43“ iznosi 1.000,00 dinara.</b>
                <br/><br/>
                <b>Za porudžbine čija ukupna masa iznosi od 30 do 60 kg,</b> kao i za preteške, glomazne i izdvojene pošiljke,
                isporuka se obračunava posebno i o tome će svaki kupac biti obavešten pre isporuke.
                <br/><br/>
                * <b>preteška pošiljka</b> (za svaki pojedinačni registrovani paket/kutiju težine veće od 30 kg)<br/>
                * <b>glomaza pošiljka</b> (za svaki pojedinačni registrovano kolet/paket koji prelazi dimenzije 60x60x60
                cm odnosno odgovara zbiru dužine i obima paketa na najširem mestu poprečno do maksimalnih 300 cm,
                s tim da najveća dimenzija pošiljke može biti do 150 cm)<br/>
                * <b>izdvojena pošiljka </b> (za svaki pojedinačni registrovani paket/kutiju od strane nalogodavca čija
                sadržina zahteva posebnu pažnju i kao takva nosi oznaku upozorenja-lomljiva sadržina)
                <br/><br/>
                <b>Kupac plaća troškove dostave kuriru prilikom isporuke naručene robe, ukoliko taj iznos već nije platio
                    preko računa.</b>
            </p>
            <h4 className="font-weight-bold mb-3">
                ISPORUKA GABARITNIH POŠILJAKA SA DVA IZVRŠIOCA
            </h4>
            <p className="mb-4">
                Isporuka sa dva izvšioca se odnosi na: <br/><br/>
                &bull; Isporuku gabaritnih pošiljki na kompletnoj teritoriji Republike Srbije (bez Kosova i Metohije)<br/>
                &bull; Dostava paketa manjih dimenzija i težine u sklopu osnovne pošiljke<br/>
                &bull; Isporuku pošiljaka do primaoca sa unošenjem pošiljke u željenu prostoriju<br/>
                &bull; Otpakivanje pošiljke, vizuelni pregled sa primaocem<br/>
                &bull; Iznošenje ambalaže prema želji primaoca
                <br/><br/>
                <b>Isporuka sa dva izvršioca obavezna je za kompletnu belu tehniku koja se po gabaritima svrstava u
                    glomaznu pošiljku</b>
                <br/><br/>
                &bull; Za svaki pojedinačni uređaj čija je težina veća od 70 kg sa ukupnom dužinom stranica preko 300
                cm tretiraće se kao dva komada.
                <br/><br/>
                Isporuku sa dva izvršioca radimo u saradnji sa City expres brzom poštom. Troškovi isporuke sa dva
                izvršioca se određuju prema zonama dostave i one su definisane prema poštanskim brojevima mesta na
                teritoriji RS, a za tačan iznos troškova isporuke morate kontaktirati Call centar na
                <a href="tel:+381 064 860 15 54">064/860-15-54</a>.
                <br/><br/>
                U tebeli ispod prikazan je cenovnik troškova isporuke po zonama.
            </p>

            <table className="table table-bordered">
                <tr>
                    <td width={100}>Zona</td>
                    <td>Cena za prvi uređaj (prvo koleto u
                        pošiljci) sa uračunatim PDV-om (RSD)</td>
                    <td>Cena po svakom sledećem uređaju na
                        istoj adresi (svako sledeće koleto u
                        pošiljci) sa uračunatim PDV-om (RSD)</td>
                </tr>
                <tr><td>1. zona</td> <td>2.500,00</td> <td>900,00</td> </tr>
                <tr> <td>2. zona</td> <td>3.200,00</td><td>1.200,00 </td></tr>
                <tr> <td>3. zona</td> <td>3.800,00</td> <td>1.400,00</td></tr>
                <tr>  <td>4. zona</td> <td>4.400,00</td> <td>1.700,00</td></tr>
                <tr> <td>5. zona</td> <td>5.000,00</td> <td>2.000,00</td></tr>
            </table>

            <p className="mb-4">
                Rizik slučajne propasti, oštećenja ili uništenja stvari prelazi na Kupca u trenutku predaje proizvoda
                Kupcu. <br /><br />
                Obaveza Prodavca da isporuči poručeni proizvod se gasi ako je, zbog naloga državnih organa, smetnji u
                prometu ili pogonu, štrajka, isključenja, manjka sirovina i svih slučajeva više sile izvršenje neprimereno
                otežano ili nemoguće, a Prodavac je za navedene događaje saznao tek nakon što je potvrdio porudžbinu.
                U tom slučaju Prodavac će o tome obavestiti Kupca bez odlaganja.
            </p>
            <h4 className="font-weight-bold mb-3">
                POSTUPANJE PRILIKOM ISPORUKE
            </h4>
            <p className="mb-4">
                Kupac je dužan da, prilikom preuzimanja pošiljke, u prisustvu kurira vizuelno pregleda paket i zahteva
                otvaranje paketa kako bi utvrdio da li postoje vidna oštećenja na pakovanju ili poručenom proizvodu.
                    <br /><br/>
                Za sva eventualna fizička oštećenja proizvoda, nedostatke u pakovanju, koje kupac uoči prilikom
                primopredaje, potrebno je da se obrati kuriru kako bi sačinio zapisnik o reklamaciji.
                <br /><br/>
                Prilikom preuzimanja paketa, Kupac je dužan da potpiše listu preuzimanja koju kurirska služba zadržava
                kao potvrdu o preuzimanju pošiljke.
                <br /><br/>
                Rok za prijavu reklamacije usled oštećenja u transportu je 24h od trenutka preuzimanja poručenih
                proizvoda, broj telefona <a href="tel:+381 064 860 15 54">064/860-15-54</a>
                ili na e-mail: <a href="mailto:web@gogorila.com">web@gogorila.com</a>. U suprotnom nećemo biti u
                mogućnosti da proizvod prihvatimo nazad.
            </p>

            <h4 className="font-weight-bold mb-3">
                OTKAZIVANJE ISPORUKE
            </h4>
            <p className="mb-5">
                Kupac ima pravo da otkaže isporuku u bilo kom trenutku, slanjem mejla na <a href="mailto:web@gogorila.com">web@gogorila.com</a>.
                <br/><br/>
                &bull; Ukoliko je isporuka otkazana pre nego što je Prodavac poslao proizvode, Kupac ima pravo da traži
                povraćaj ukupnog iznosa plaćenog za proizvode i isporuku.
                <br/><br/>
                &bull; U slučaju otkazivanja porudžbine nakon što je Prodavac poslao proizvode, Kupac ima pravo da traži
                povraćaj iznosa za proizvode, ali u tom slučaju, Kupac sam snosi troškove vraćanja proizvoda Prodavcu.
                <br/><br/>
                Po otkazivanju isporuke pozivom telefonskog broja, Kupac će dobiti potvrdu o otkazivanju porudžbine na
                svoju email adresu.
            </p>


            <h4 id="zamena-proizvoda" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                        7. ZAMENA PROIZVODA
                </span>
            </h4>
            <p className="mb-5">
                U slučaju da, po prijemu paketa sa poručenim proizvodima, utvrdite da smo vam isporučili pogrešan
                proizvod možete nas kontaktirati slanjem emaila na adresu web@gogorila.com ili pozivom na broj
                telefona: <a href="tel:+381 064 860 15 54">064/860-15-54</a>.
                Rok za prijavu pogrešno isporučenog paketa/proizvoda je 24 h od trenutka
                isporuke. <br/><br/>
                Neophodno je da artikal koji vraćate bude adekvatno zapakovan i nekorišćen, kompletan, sa originalnom
                ambalažom, jer u slučaju oštećenja prilikom transporta do prostorija Prodavca, sva odgovornost pada na
                teret pošiljaoca, odnosno Kupca. Obaveza Prodavca je da pošalje kurira na adresu Kupca kako bi preuzeo
                pogrešno isporučen paket. U tom slučaju troškove vraćanja proizvoda na adresu Prodavca snosi
                Prodavac. <br/><br/>
                Nakon što pošiljka stigne na našu adresu, izvršićemo proveru da li je dostavljeni proizvod adekvatno
                zapakovan, da li neoštećen, kompletan i sa originalnom ambalažom, i ukoliko se utvrdi da jeste, bez
                odlaganja ćemo na adresu Kupca poslati zamenski proizvod. <br/><br/>
                Zamena nije moguća ukoliko je proizvod vraćen Prodavcu u neispravnom, korišćenom ili nekompletnom
                stanju, odnosno ukoliko nije adekvatno zapakovan.
            </p>

            <h4 id="odustanak-od-ugovora" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                       8. ODUSTANAK OD UGOVORA
                </span>
            </h4>
            <p className="mb-5">
                Kupovina putem Gogorila.com internet prodavnice smatra se kupovinom na daljinu u skladu sa Zakonom
                o zaštiti potrošača. Stoga, <b>ovaj odeljak OUP se odnosi na Kupce fizička lica koja se smatraju Potrošačima.</b>
                <br/><br/>
                Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se smatra potrošačem (fizičko lice koje
                proizvod kupuje radi namirenja svojih individualnih potreba, a ne radi obavljanja profesionalne
                delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu je proizvod predat u državinu,
                odnosno u državinu lica koga je kupac odredio, a nije prevoznik. Prilikom odustanka potrošač može, ali ne
                mora da navede razloge zbog kojih odustaje.
                <br/><br/>
                Ako ste istom porudžbenicom poručili više proizvoda koji Vam nisu isporučeni istovremeno, rok za
                odustanak od Ugovora počinje da teče od dana isporuke poslednjeg poručenog proizvoda.
                <br/><br/>
                Pravo na odustanak od Ugovora ostvarujete dostavljanjem izjave o odustanku na adresu Prodavca. To
                možete učiniti popunjavanjem obrasca za odustanak koji Vam je dostavljen u štampanom obliku zajedno
                sa poručenim proizvodima ili putem emaila ili na drugi nedvosmislen način pismenim putem, uključujući
                i slanje emailom.
                <br/><br/>
                Obrazac za odustanak možete pronaći u pdf obliku na <a
                href="/Obrazac_za_odustanak_od_ugovora_na_daljinu.pdf" target="_blank">ovom linku</a>.
                <br/><br/>
                Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata na našu adresu. Ukoliko
                ste izjavu o odustanku poslali elektronskim putem, bez odlaganja ćemo Vas obavestiti u pisanoj formi ili
                na drugom trajnom nosaču zapisa o prijemu Vaše izjave.
                <br/><br/>
                U slučaju odustanka od Ugovora, Potrošač je dužan da vrati poručene proizvode na adresu Prodavca bez
                odlaganja, a najkasnije u roku od 14 dana od dana slanja izjave o odustanku. Artikal mora biti u
                ispravnom i nekorišćenom stanju, u originalnom i neoštećenom pakovanju. Uz artikal, Potrošač je dužan
                da dostavi i račun (odnosno račun-otpremnicu). Sve troškove vraćanja proizvoda Prodavcu snosi
                Potrošač. Po isteku roka od 14 dana od dana kada je poslao odustanak, proizvod se više ne može vratiti.
                U slučaju odustanka od Ugovora, Potrošač ima pravo na povraćaj novca ili zamenu proizvoda za drugi,
                ukoliko su proizvod i pakovanje dostavljeni Prodavcu u neoštećenom stanju i ukoliko je uz vraćeni
                proizvod dostavljen račun (odnosno račun-otpremnica). Iznos plaćene cene proizvoda biće vraćen
                Potrošaču nakon što proizvod bude dostavljen na našu adresu. GoGorila doo će bez odlaganja izvršiti
                povraćaj uplaćenih sredstava na račun Potrošača uključujući i troškove isporuke (ukoliko je troškove
                isporuke snosio Potrošač), a najkasnije u roku od 14 dana od prijema izjave o odustanku od ugovora.
                Povraćaj sredstava može biti odložen dok ne primimo proizvod koji ste nam poslali ili dok nam ne
                dostavite dokaz o tome da ste nam poslali proizvod.
                <br/><br/>
                U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica,
                delimično ili u celosti, a bez obzira na razlog vraćanja. GoGorila je u obavezi da povraćaj vrši isključivo
                preko VISA, Master i Maestro metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj
                sredstava na račun korisnika kartice. Ukoliko Potrošač ne zahteva povraćaj sredstava na račun koji je
                naveo u izjavi.
                <br/><br/>
                Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Potrošač odgovara za
                neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja proizvodom. Ukoliko
                vrednost proizvoda bude umanjena usled neadekvatnog rukovanja ili rukovanja proizvodom na način koji
                prevazilazi ono što je neophodno da bi se ustanovili priroda, karakteristike i funkcionalnost robe, svu
                odgovornost snosiće isključivo Potrošač. U takvim slučajevima, GoGorila doo ima pravo da Potrošaču vrati
                umanjenu cenu, a cena će biti umanjena srazmerno umanjenoj vrednosti proizvoda, o čemu odluku
                donosi Prodavac.
                <br/><br/>
                <b>Kada potrošač nema pravo na odustanak od ugovora:</b>
                <br/><br/>
                1. U slučaju isporuke robe proizvedene prema posebnim zahtevima potrošača ili jasno
                personalizovane;<br/>
                2. isporuke zapečaćene robe koja se ne može vratiti zbog zaštite zdravlja ili higijenskih razloga i koja
                je otpečaćena nakon isporuke.
            </p>

            <h4 id="zastita-podataka-o-licnosti" className="mb-4 title_underline font-weight-bold">
                <span className="title">
                      9. ZAŠTITA PODATAKA O LIČNOSTI
                </span>
            </h4>
            <p className="mb-5">
                GoGorila obrađuje podatke o ličnosti Kupaca u skladu sa propisima Republike Srbije. Za više informacija
                kliknite na link do <a href={routes.faq[3].route}> Politike privatnosti </a>.
                <br/><br/>
                Radi prikupljanja pojedinih podataka o ličnosti Korisnika Sajta, koristimo kolačiće. Više informacija o
                tome koje kolačiće koristimo možete saznati klikom na <a href={routes.faq[4].route}> Politiku kolačića </a>.
                <br/><br/>
                Ovi Opšti uslovi poslovanja važe od 17.10.2020.
            </p>

        </div>
    };

    componentDidMount() {
        if (window.location.href.includes('#isporuka')) {
            document.getElementById('isporuka').scrollIntoView();
        }
    }
}

export default Text1;
