import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import {getBrands} from "../../../app/hooks";
import Title from "../../elements/Title";


class Brands extends React.Component {

    state = {
        brands: false,
        all: [],
        search: ""
    }

    search = (e) => {
        this.setState({...this.state, search: e.target.value});
        this.load(e.target.value);
    }

    render() {
        if(!this.state.brands)       {
            return <Loader/>
        }
        return (
            <div>
                <div className="container mt-4 mb-5">

                    <div className="row pt-4">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                            <input type="text"
                                   name="Ime brenda..."
                                   value={this.state.search}
                                   onChange={this.search}
                                   className="form-control form-control-lg"
                                   placeholder="Ime brenda..." />
                        </div>

                    </div>

                    <div className="row pt-4">
                        {this.state.brands.map((item) => {
                            let col1 = this.state.brands.length === 1 ? "col-12" : "col-md-3";
                            let col2 = this.state.brands.length === 1 ? "col-md-3" : "col-12";
                            return <div key={item.firstLetter} className={col1}>
                                <div className="p-2 mb-4 border_rounded">
                                    <div className="mb-2">
                                        <Title name={item.firstLetter.toUpperCase()} />
                                    </div>
                                    <div className="row">
                                        {item.items.map((brand) => {
                                            return <div key={brand.brand} className={col2}>
                                                <Link to={routes.shop.route() + "?brand=" + brand.brand} className="link">
                                                    <span className="weight_500"> {brand.brand} </span>
                                                    <span className="text-black-50"> ({brand.total}) </span>
                                                </Link>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

            </div>
        );
    }
    load = (search = "") => {
        getBrands({search: search}).then((response) => {
            let holder = [];
            for (let i = 0; i < response.data.length; i++) {
                let item = response.data[i];
                if(!item.brand) {
                    continue;
                }
                let firstLetter = item.brand[0].toLowerCase();
                if (!firstLetter.match(/^[a-zA-Z]+$/)) {
                    continue;
                }
                if (!holder[firstLetter]) {
                    holder[firstLetter] = {};
                    holder[firstLetter]['firstLetter'] = firstLetter;
                    holder[firstLetter]['items'] = [];
                }
                holder[firstLetter]['items'].push(item);
            }
            this.setState({...this.state, brands: Object.values(holder), all: Object.values(holder)});
        });
    }
    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Brands);
