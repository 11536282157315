import React from 'react';
import {connect} from "react-redux";
import {getProducts} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import {closeDrawer} from "../../../store/actions/drawersActions";
import {deleteFromBasket, setBasket} from "../../../store/actions/basketActions";
import {processPrice} from "../../../providers/price";
import {image} from "../../../app/helpers";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";
import Qty from "../../elements/Qty";
import OrderForm from "./OrderForm";
import FormatPrice from "../../elements/FormatPrice";


class Basket extends React.Component {

    state = {
        products: false
    }

    delete = (product) => {
        let products = this.state.products.filter(item => item.unique !== product.unique);
        this.props.deleteFromBasket(product);
        this.setState({...this.state, products: products});
    }

    changeQty = (id, qty) => {
        let products = this.state.products.map((item) => {
            if(item.product.id !== id) return item;
            item.qty = qty;
            return item;
        });
        this.props.setBasket(products);
        this.setState({...this.state, products: products});
    }

    render() {
        if (this.state.products === false) {
            return <Loader height={300}/>
        }
        if (this.state.products.length === 0) {
            return <div className="text-center mt-5 mb-5">
                <i className="fa fa-shopping-cart"/>
                <div className="font_2 font-weight-bold mt-2"> KORPA JE PRAZNA</div>
            </div>;
        }
        let total = 0;
        return (
            <div className="container mt-5 mb-4">
                <div className="mb-3">
                    <div className="font_20 text-primary font-weight-bold mb-3 d-none d-md-block">
                        VAŠA KORPA
                    </div>

                    <div className="table-responsive d-md-block d-none">
                        <table className="table table-bordered">
                            <thead className="">
                            <tr className="font-weight-bold">
                                <td className="text-center"> Slika</td>
                                <td> Ime</td>
                                <td width={120}> Cena</td>
                                <td width={90} className="text-center">
                                    Količina
                                </td>
                                <td width={120}> Ukupno</td>
                                <td width={90} className="text-center">
                                    Uklonite
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.products.map((item) => {
                                const category = this.props.categoriesById[item.product.category_id];
                                let fullPrice = processPrice(item.product) * item.qty;
                                total += fullPrice;
                                return <tr key={item.unique}>
                                    <td width={120} className="d-none d-md-table-cell">
                                        <img src={image(item.product.image_path)} alt={item.product.name}
                                             className="img-fluid"/>
                                    </td>
                                    <td className="align-middle">
                                        <div>
                                            <Link to={routes.shop.route(category?.id)} className="text-primary">
                                                {category?.name}
                                            </Link>
                                        </div>
                                        <Link to={routes.product.route(item.product)} className={'font-weight-bold'}>
                                            {item.product.name}
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <FormatPrice price={processPrice(item.product)} />
                                    </td>
                                    <td className="align-middle text-center">
                                        <Qty
                                            value={item.qty}
                                            max={item.product.stock}
                                            onChange={(val) => this.changeQty(item.product.id, val)}/>
                                    </td>
                                    <td className="align-middle">
                                        <div className="font-weight-bold text-primary">
                                            <FormatPrice price={fullPrice} />
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <button className="btn btn-light" onClick={() => this.delete(item)}>
                                            <i className="fa fa-trash"/>
                                        </button>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                            <tfoot>
                            <tr className="d-md-none bcg-2">
                                <td colSpan={1}>
                                    <div className="p-4 font-weight-bold font_3 text-primary">
                                        UKUPNO : <FormatPrice price={total} />
                                    </div>
                                </td>
                            </tr>
                            <tr className="d-none d-md-table-row">
                                <td colSpan={4} className="p-4 text-right align-middle font-weight-bold font_3">
                                    Ukupno:
                                </td>
                                <td colSpan={2} className="p-4 align-middle">
                                    <div className="font-weight-bold font_3 text-primary">
                                        <FormatPrice price={total} />
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <OrderForm
                    products={this.state.products}
                    delete={this.delete}
                    changeQty={this.changeQty}
                />
            </div>
        );
    }

    load = () => {
        if (this.props.basket.length === 0) {
            this.setState({...this.state, products: []});
            return false;
        }
        const ids = this.props.basket.map(item => item.id);
        getProducts({ids: ids}).then((response) => {
            let holder = [];
            for (let j = 0; j < this.props.basket.length; j++) {
                let item = this.props.basket[j];
                const prod = response.data.find(product => product.id === item.id);
                if (prod) {
                    item.product = prod;
                    holder.push(item);
                } else {
                    this.props.deleteFromBasket(item)
                }
            }
            this.setState({...this.state, products: holder});
        });
    }

    componentDidMount() {
        this.load();
    }
}

const mapStateToProps = state => ({
    basket: state.basket,
    categoriesById: state.categoriesById
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer,
    deleteFromBasket: deleteFromBasket,
    setBasket: setBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(Basket);
