import React from "react";
import {connect} from "react-redux";


class Specifications extends React.Component   {

    state = {
        active: 1
    }

    render = () => {
        const category = this.props.categoriesById[this.props.product.category_id];
        const deklaracija = [
                {id: 'aksu',parent_name: "Model", name: this.props.product.name},
                {id: 'rtdvfg', parent_name: "Naziv i vrsta robe", name: category?.name},
                {id: 'fref', parent_name: "Uvoznik", name: "Tačan podatak o uvozniku će biti naveden na deklaraciji koju dobijate uz proizvod."},
                {id: 'vdsde', parent_name: "Zemlja porekla", name: "Tačan podatak o zemlji porekla će biti naveden na deklaraciji koju dobijate uz proizvod."},
                ];
        return <div>

            <div className="d-flex">
                <button onClick={() => this.setState({...this.state, active: 1})}
                        className="btn btn-link font-weight-bold mr-2" style={{background: this.state.active === 1 ? "#e8e8e8" : ""}}>
                    SPECIFIKACIJE
                </button>
                <button onClick={() => this.setState({...this.state, active: 2})}
                        className="btn btn-link font-weight-bold mr-2" style={{background: this.state.active === 2 ? "#e8e8e8" : ""}}>
                    DEKLARACIJA
                </button>
            </div>


            <div className="mt-4 mb-5">
                {this.state.active === 1 &&
                <table className="table table-bordered table_odd">
                    <tbody>
                    {this.props.product.filters.map((item) => {
                        return <tr key={item.id}>
                            <td style={{maxWidth: "30%", width: "30%"}}><strong> {item.parent_name} </strong></td>
                            <td> {item.name} </td>
                        </tr>
                    })}
                    </tbody>
                </table>}

                {this.state.active === 2 &&
                <table className="table table-bordered table_odd">
                    <tbody>
                    {deklaracija.map((item) => {
                        return <tr key={item.id}>
                            <td style={{maxWidth: "30%", width: "30%"}}><strong> {item.parent_name} </strong></td>
                            <td> {item.name} </td>
                        </tr>
                    })}
                    </tbody>
                </table>}
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
    categoriesById: state.categoriesById,
});
export default connect(mapStateToProps)(Specifications);
