import React from "react";
import UserLayout from "../UserLayout";
import {getUserInfo, patchUser} from "../../../../app/hooks";
import Title from "../../../elements/Title";
import Switch from "../../../framework/Switch";




class Newsletter extends React.Component {

    state = {
        newsletter: false
    }

    change = (val) => {
        patchUser({field: "newsletter", value: val ? 1 : null}).then((response) => {
            this.setState({...this.state, newsletter: val});
        });
    };

    render = () => {
        return <UserLayout>
            <div className="mb-4">
                <Title name="Newsletter" />
            </div>

            <div className="p-3 bg-light">
                <div className="mb-3 font_2">
                    {this.state.newsletter
                        ? "Prijavljeni ste za newsletter."
                        : "Niste prijavljeni za newsletter. Kliknite na dugme ispod."}


                </div>

                <Switch onChange={this.change}
                        default={this.state.newsletter}
                        label={<div className="mt-2 mt-md-0 d-md-inline-block">
                            Prijavite se za newsletter.
                        </div>} />
            </div>

        </UserLayout>
    }

    componentDidMount() {
        getUserInfo().then((response) => {
            this.setState({...this.state, loading: false, newsletter: !!response.data.newsletter})
        });
    }
}

export default Newsletter;