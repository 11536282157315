import React from "react";
import LoginForm from "./LoginForm";
import AuthLayout from "../AuthLayout";


class Login extends React.Component {

    render = () => {
        return <AuthLayout>
            <LoginForm />
        </AuthLayout>
    }
}

export default Login;