import React from "react";
import {connect} from "react-redux";
import Loader from "../../framework/Loader";
import Error404 from "../../elements/Error404";
import {emptyBasket} from "../../../store/actions/basketActions";
import {getOrderByCode} from "../../../app/hooks";
import Printable from "../../elements/Printable";
import Transaction from "../orderStatus/Transaction";



class ThankYou extends React.Component {

    state = {
        loading: true,
        order: null
    }

    render = () => {
        if(this.state.loading) {
            return <Loader height={300}/>
        }
        if(!this.state.order) {
            return <Error404 />
        }

        return <div>

            <div className="container mt-4 mb-4">
                {!this.state.order.checkout_error
                    ? <div className="alert text-center alert-success font_4">
                        Uspešno poručeno! <br />
                        Hvala na poverenju! <br />
                        <div className="mt-3">
                            GoGorila tim
                        </div>
                    </div>
                    : <div className="alert text-center alert-danger font_4">
                        Greška prilikom transakcije!
                    </div>}
            </div>

            <div className="container mt-4 mb-5 font_2">
                <Transaction order={this.state.order} />
                <Printable order={this.state.order} />
            </div>
        </div>
    };

    componentDidMount() {
        this.props.emptyBasket();
        getOrderByCode({code: this.props.match.params.code}).then((orderResponse) => {
            this.setState({...this.state, loading: false, order: orderResponse.data});
        }).catch((error) => {
            this.setState({...this.state, loading: false});
        });
    }
}
const mapStateToProps = state => ({
    categories: state.categories
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
