import React from "react";


class Text2 extends React.Component   {



    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5 footer_text">
            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     SAOBRAZNOST I REKLAMACIJA
                </span>
            </h4>

            <p className="mb-5">
                Ovaj deo Uslova poslovanja – Saobraznost i Reklamacija se odnosi na situaciju kada roba Kupcu nije
                stigla u ispravnom stanju, odnosno kada roba ima određene nedostatke, kada je nesaobrazna.
                <br/><br/>
                Ovaj deo čini sastavni deo naših <a href="https://gogorila.com/opsti-uslovi-poslovanja" target="_blank" rel="noreferrer">
                Uslova poslovanja </a> i zajedno čine celinu koja se primenjuje na Kupce i/ili
                Potrošače prilikom kupovine proizvoda na sajtu http://www.gogorila.com/.
                <br/><br/>
                Ukoliko Vam je isporučena pogrešna roba u ispravnom stanju, molimo Vas vidite odeljak  Zamena
                proizvoda .
                <br/><br/>
                Ukoliko Vam je roba isporučena u ispravnom stanju, ali kao Potrošač želite da iskoristite svoje pravo na
                predomišljanje, odnosno da je vratite i odustanete od kupovine, molimo Vas vidite odeljak  Pravo na
                odustanak .
            </p>
            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     REKLAMACIJE NA OŠTEĆENJE NASTALO U TRANSPORTU
                </span>
            </h4>
            <p className="mb-5">
                <b>Molimo Vas da prilikom isporuke dobro pogledate ambalažu i pošiljku.</b>
                <br/><br/>
                &bull;  Ukoliko primetite da je ambalaža oštećena na bilo koji način, molimo Vas skrenite pažnju
                dostavljaču na oštećenje ambalaže.
                <br/><br/>
                &bull;  Ukoliko primetite vidljive tragove oštećenja na samoj ambalaži odnosno pakovanju, zamolite
                dostavljača da sačeka da se proveri i da li je sam sadržaj isporuke oštećen.
                <br/><br/>
                &bull;  U slučaju da utvrdite da je oštećen i sam sadržaj isporuke, zatražite da se sadržaj isporuke vrati
                kompaniji GoGorila doo, kao i da se GoGorila doo istovremeno obavesti o povraćaju robe.
                <br/>
                Krajnji rok za prijavu reklamacija na oštećenje u transportu je 24h od časa prijema proizvoda. Ukoliko
                primetite oštećenje, molimo Vas da odmah pošaljete fotografije proizvoda i ambalaže
                na <a href="mailto:web@gogorila.com">web@gogorila.com</a> zajedno sa informacijama o vremenu kada je izvršena dostava, i broju računa-
                otpremnice po kome ste kupili proizvod.
            </p>

            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     REKLAMACIJE U POGLEDU NESAOBRAZNOSTI PROIZVODA
                </span>
            </h4>
            <p className="mb-5">
                Ukoliko kupovinu vrši Kupac kao Potrošač u smislu <a href="https://gogorila.com/opsti-uslovi-poslovanja"
                 target="_blank" rel="noreferrer"> odeljka 1 Uslova poslovanja</a> , primenjuju se odredbe
                Zakona o zaštiti potrošača (čl. 50. do 53.) i odredbe odeljka  Posebna prava potrošača . Ukoliko kupovinu
                vrši pravno lice ili preduzetnik, primenjivaće se odredbe Zakona o obligacionim odnosima.
            </p>

            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     POSEBNA PRAVA POTROŠAČA
                </span>
            </h4>
            <h4 className="mb-4 font-weight-bold">
                ŠTA ZNAČI SAOBRAZNOST?
            </h4>
            <p className="mb-5">
                Pretpostavlja se da je isporučena roba saobrazna ugovoru:
                <br/><br/>
                1. ako odgovara opisu koji smo Vam dali;
                <br/><br/>
                2. ako ima svojstva potrebna za naročitu upotrebu za koju je Potrošač nabavlja, a koja je bila
                poznata Prodavcu ili mu je morala biti poznata u vreme zaključenja ugovora;
                <br/><br/>
                3. ako ima svojstva potrebna za redovnu upotrebu robe iste vrste;
                <br/><br/>
                4. ako po kvalitetu i funkcionisanju odgovara onome što je uobičajeno kod robe iste vrste i što
                Potrošač može osnovano da očekuje s obzirom na prirodu robe i javna obećanja o posebnim
                svojstvima robe od strane prodavca, proizvođača ili njihovih predstavnika, naročito ako je
                obećanje učinjeno putem oglasa ili na ambalaži robe
                <br/><br/>
                <b>Ukoliko smatrate da neki od ovih uslova nije ispunjen, možete izjaviti reklamaciju.</b>
                <br/><br/>
                Molimo Vas da imate u vidu da reklamacija neće biti uspešna u slučaju:
                <br/><br/>
                &bull; neadekvatnog rukovanja proizvodom i njegovim korišćenjem, koje nije u skladu sa uputstvom za
                upotrebu i održavanje ili u skladu sa njegovom namenom;
                <br/><br/>
                &bull; u slučaju nepravilne instalacije ili montaže robe u slučaju kada je prodavac predao uputstvo radi
                samosatalne instalacije i montaže;
                <br/><br/>
                &bull; kao i u slučaju da su vršene bilo kakve popravke ili pokušaji popravki od strane neovlašćenih lica.
            </p>

            <h4 className="mb-4 font-weight-bold">
                U KOM ROKU POTROŠAČ MOŽE IZJAVITI REKLAMACIJU?
            </h4>
            <p className="mb-5">
                Rok za izjavljivanje reklamacije je <b>dve godine od dana isporuke za Potrošače</b>. U toku navedenog perioda
                GoGorila doo snosi odgovornost za nesaobraznost robe. Ukoliko se reklamacija izjavljuje nakon proteka
                2 godine od dana kupovine, potrošač je dužan da reklamaciju izjavi davaocu garancije.
                <br/><br/>
                Napominjemo da period saobraznosti artikala koji se smatraju potrošnom robom može biti manja od 24
                meseca. U takve artikle spadaju baterije za uređaje (telefona, tableta i laptopova), toneri, ketridži, glave
                štampača,... Period saobraznosti za takve artikle iznosi 6 meseci, ukoliko proizvođač nije drugačije
                propisao. Softver koji je isporučen uz računar ili uređaj ne podpada pod uslove saobraznosti. GoGorila
                doo ne preuzima nikakvu odgovornost za podatke na računaru, hard disku ili bilo kom drugom uređaju
                koji služi za smeštanje podataka.
                <br/><br/>
                Za uređaje za koje proizvođač ne nudi posebne pogodnosti ( npr. ispravno funkcionisanje stvari u periodu
                dužem od 2 godine) GoGorila doo nije u obavezi da izda izjavu o saobraznosti. Izjava o saobraznosti
                predstavlja voljni korak izdavaoca (proizvođača ili trgovca) kojim on nudi posebne pogodnosti za
                potrošača, ali isključivo veće od zakonom predviđenih.
            </p>

            <h4 className="mb-4 font-weight-bold">
                POSTUPAK IZJAVLJIVANJA REKLAMACIJE
            </h4>
            <p className="mb-5">
                Ukoliko želite da izjavite reklamaciju zbog nesaobraznosti isporučene robe, potrebno je da zahtev za
                reklamaciju dostavite poštom na adresu: GoGorila doo, Bulevar oslobođenja 17, 32000 Čačak, odnosno
                elektronskom poštom na adresu <a href="mailto:web@gogorila.com">web@gogorila.com</a> na naznakom: REKLAMACIJA.
                <br/><br/>
                Prilikom podnošenja zahteva za reklamaciju Potrošač je dužan da:
                <br/><br/>
                a. Dostavi reklamirani proizvod,  <br/><br/>
                b. Dostavi dokaz o kupovini – Ugovor ili ispravu o ugovoru, račun-otpremnicu, izvod elektronskog
                računa ili drugi dokaz kojim se potvrđuje kupovina robe,
                <br/><br/>
                c. Dostavi popunjen reklamacioni list, koji je moguće preuzeti na sledećem
                linku: <a href="/Reklamacioni_list_prodavnica_gogorila.pdf" target="_blank" rel="noreferrer"><b>REKLAMACIONI LIST</b></a>.
                Podatke koje unosite u reklamacioni list možete pronaći u narednom odeljku.
                <br/><br/>
                Napomena: Nećemo biti u mogućnosti da odlučimo o osnovanosti Vaše reklamacije pre nego što nam
                reklamirani artikal bude dostavljen, s obzirom da je potrebno pregledati proizvod i utvrditi da li postoji
                nedostatak koji ističete da postoji.
                <br/><br/>
                Robu možete reklamirati i u najbližem ovlašćenom servisu naznačen u izjavi o saobraznosti koju ste dobili
                uz poručenu robu, uz obavezu da priložite dokaz o kupovini (račun/otpremnicu). U ovom slučaju potrošač
                snosi sve troškove slanja.
                <br/><br/>
                Ukoliko imate bilo kakvo pitanje oko toka Vaše reklamacije ili Vam je potrebno bilo kakvo dodatno
                objašnjenje, možete da nas kontaktirate slanjem email-a na adresu <a href="mailto:web@gogorila.com">web@gogorila.com</a> ili telefonom na
                broj <a href="tel:+381 064 860 15 54">064/860-15-54</a>.
                Ovlašćena lica u okviru kompanije GoGorila doo će Vam odgovoriti u najkraćem
                mogućem roku.
            </p>

            <h4 className="mb-4 font-weight-bold">
                POSTUPAK REŠAVANJA REKLAMACIJE
            </h4>
            <p className="mb-5">
                Postupak reklamacije pokrećete popunjavanjem reklamacionog lista koji je moguće preuzeti na sledećem
                linku: <a href="/Reklamacioni_list_prodavnica_gogorila.pdf"><b>REKLAMACIONI LIST</b></a>.
                <br/><br/>
                Molimo Vas da u obrazac reklamacionog lista obavezno upišete sledeće podatke:
                <br/>
                &bull; svoje ime i prezime,<br/>
                &bull; adresu, broj telefona i e-mail adresu,<br/>

                &bull; vrstu/broj artikla i količinu,<br/>
                &bull; kratak opis reklamirane nesaobraznosti,<br/>
                &bull; zahtev za opravku, zamenu, umanjenje cene ili raskid ugovora i vraćanje cene,<br/>
                &bull; datum prijema robe (datum prelaska rizika na Potrošača),<br/>
                &bull; datum slanja reklamacije,<br/>
                &bull; datum kupovine,<br/>
                &bull; broj računa,<br/>
                &bull; cenu po kojoj je reklamirana roba kupljena,<br/>
                &bull; potpis.
                <br/><br/>
                Potpisom na Reklamacionom listu, potvrđujete da su podaci koje ste uneli tačni, a naročito podaci o
                Vašoj adresi i adresi elektronske pošte na koju GoGorila doo treba da dostavi pisani odgovor na izjavljenu
                reklamaciju. Potpisom na reklamacionom listu takođe potvrđujete da ste saglasni da Vam potvrdu o
                prijemu reklamacionog lista i svoj odgovor na izjavljenu reklamaciju GoGorila doo može dostaviti
                elektronskim putem na Vašu adresu za prijem elektronske pošte navedene u reklamacionom listu.
                Elektronskim putem ćemo Vam na adresu za prijem elektronske pošte, navedene u reklamacionom listu,
                dostaviti potvrdu o prijemu reklamacije i broj pod kojim je vaša reklamacija zavedna kod nas (broj radnog
                naloga).
                <br/><br/>
                <b>Nakon što primimo Vašu reklamaciju</b>, odgovorićemo na Vaš zahtev elektronskim putem u roku od osam
                dana od dana prijema reklamacije. Tom prilikom obavestićemo Vas da li prihvatamo reklamaciju,
                izjasnićemo se o Vašem zahtevu za rešavanje reklamacije i dati konkretan predlog i rok za rešavanje
                reklamacije.
                <br/><br/>
                Rok za rešavanje reklamacije neće biti duži od 15 dana, odnosno 30 dana za tehničku robu i nameštaj, od
                dana podnošenja reklamacije.
                <br/><br/>
                Rok za rešavanje reklamacije prekida se kada potrošač primi odgovor prodavca i počinje da teče iznova
                kada prodavac primi izjašnjenje potrošača na odgovor prodavca. Potrošač je dužan da se izjasni na
                odgovor prodavca najkasnije u roku od tri dana od dana prijema odgovora prodavca. Smatraće se da
                    potrošač nije saglasan sa predlogom prodavca ukoliko se ne izjasni u roku od tri dana.
                <br/><br/>
                <b>U slučaju da ne dobijemo Vašu saglasnost blagovremeno</b>, smatraće se da postoje objektivni razlozi zbog
                kojih nismo u mogućnosti da udovoljimo Vašem zahtevu u zakonskom roku od 15 dana, odnosno 30 dana
                za tehničku robu i nameštaj.
                <br/><br/>
                <b>Ukoliko iz objektivnih razloga nismo u mogućnosti</b> da udovoljimo Vašem zahtevu u roku koji je
                dogovoren, obavestićemo Vas o produžavanju roka za rešavanje reklamacije i navesti rok u kome ćemo je
                rešiti, uz Vašu saglasnost. Produžavanje roka za rešavanje reklamacija moguće je samo jednom.
                <br/><br/>
                Prodavac se obavezuje da će svaki proizvod koji je predat na servis a čija reklamacija nije rešena u
                dogovorenom roku zameniti novim, istim ili odgovarajućim artiklom. Ukoliko Kupac ne prihvati zamenu
                artikla za drugi odgovarajući, Kupcu će biti vraćen iznos kupoprodajne cene.
            </p>

            <h4 className="mb-4 font-weight-bold">
                KO ODLUČUJE O REKLAMACIJI?
            </h4>
            <p className="mb-5">
                GoGorila doo, šalje sve reklamacije u ovlašćen servis gde ovlašćena lica odlučuju o osnovanosti
                reklamacije.
            </p>

            <h4 className="mb-4 font-weight-bold">
                DOSTAVLJANJE ROBE NA REKLAMACIJU
            </h4>
            <p className="mb-5">
                Po prijemu obaveštenja da želite da reklamirate robu, Prodavac će poslati kurirsku službu da preuzme
                robu ili vas obavestiti o drugom načinu ostvarivanja prava na reklamaciju (Ukoliko je za određenu robu
                servis definisan kroz dolazak servisera na adresu potrošača ili kada potrošač reklamira robu sam u
                ovlašćenom servisu). Potrebno je da spakujete proizvod koji reklamirate, sa svim delovima i dodacima.
                Kako tokom transporta ne bi došlo do oštećenja proizvoda koje šaljete na reklamaciju, molimo Vas da ih
                zapakujete u odgovarajuću ambalažu i adekvatno zapakujete. Troškove slanja brzom poštom robe snosi
                Prodavac.
                <br/><br/>
                Proizvodi koji su nam dostavljeni radi reklamacije, a koji nisu propisno zapakovani ili oni kod kojih je
                došlo do vidljivih mehaničkih oštećenja samog proizvoda u toku transporta, neće biti preuzeti od strane
                kompanije GoGorila doo, već će Vam biti vraćeni o Vašem trošku.
                <br/><br/>
                Molimo vas da prilikom slanja kurirskom službom ili poštom, obavezno preuzmete potvrdu da ste paket
                predali.
                <br/><br/>
                Poštošač može odneti robu u ovlašćeni servis, koji je naznačen na izjavi o saobraznosti, uz obavezu da
                priloži dokaz o kupovini (račun/otpremnica). U tom slučaju sve troškove slanja i dostave brzom poštom
                robe koju reklamirate snosi Potrošač, lično.
                <br/><br/>
                GoGorila doo ne preuzima nikakvu odgovornost za sva eventualna oštećenja proizvoda i ambalaže koja
                nastanu u toku transporta na ili sa Vaše adrese, prilikom rešavanja reklamacije.
            </p>

            <h4 className="mb-4 font-weight-bold">
                PRAVA POTROŠAČA PO OSNOVU REKLAMACIJE
            </h4>
            <p className="mb-5">
                <b>Prilikom izjavljivanja reklamacije, Potrošač ima pravo da traži da GoGorila doo:</b>
                <br/><br/>
                a. obezbedi opravku proizvoda, o svom trošku,<br/>
                b. zameni kupljeni proizvod sa nedostatkom za nov, ispravan proizvod,<br/>
                c. umanji cenu reklamiranog proizvoda, ili<br/>
                d. vrati pun iznosa cene prema priloženom računu, u slučaju kada otklanjanje nesaobraznosti, na
                jedan od gore navedenih načina nije moguće.
                <br/><br/>
                <b>Ako otklanjanje nesaobraznosti nije moguće </b> popravkom ili zamenom, imate pravo da zahtevate
                odgovarajuće umanjenje cene ili raskid ugovora u sledećim slučajevima:
                <br/><br/>
                &bull; ukoliko nesaobraznost ne može da se otkloni opravkom ili zamenom uopšte niti u primerenom
                roku;<br/>
                &bull; ukoliko nismo izvršili opravku ili zamenu u primerenom roku, usled čega niste mogli da ostvarite
                pravo na opravku ili zamenu;<br/>
                &bull; ukoliko, zbog prirode robe i njene namene, opravka ili zamena ne može da se sprovede bez
                značajnijih nepogodnosti za Vas;<br/>
                &bull; otklanjanje nesaobraznosti opravkom ili zamenom predstavlja nesrazmerno opterećenje za
                GoGorila doo.<br/><br/>
                Nesrazmerno opterećenje za GoGorila doo u smislu stava 2. tačka 4. ovog odeljka, javlja se ako
                otklanjanje nesaobraznosti, u poređenju sa umanjenjem cene i raskidom ugovora, stvara preterane
                troškove, uzimajući u obzir:
                <br/><br/>
                &bull; vrednost robe koju bi imala da je saobrazna ugovoru;<br/>
                &bull; značaj saobraznosti u konkretnom slučaju;<br/>
                &bull; da li se saobraznost može otkloniti bez značajnijih nepogodnosti za Vas.
                <br/><br/>
                <b>Ukoliko ste već izjavili reklamaciju na osnovu koje smo izvršili opravku proizvoda </b>, imate pravo da
                zahtevate zamenu, odgovarajuće umanjenje cene ili da raskinete ugovor zbog istog ili drugog nedostatka
                saobraznosti koji se posle prve opravke pojavi, a ponovna opravka je moguća samo uz Vašu izričitu
                saglasnost.
                <br/><br/>
                Uzimajući u obzir prirodu robe i svrhu zbog koje je nabavljate, opravka ili zamena robe mora se izvršiti u
                primerenom roku bez značajnih neugodnosti za Vas i uz Vašu saglasnost.
                <br/><br/>
                <b>Ako se nesaobraznost pojavi u roku od šest meseci </b> od dana kada Vam je proizvod dostavljen, imate pravo
                da birate između zahteva da se nesaobraznost otkloni zamenom, odgovarajućim umanjenjem cene ili da
                izjavite da raskidate ugovor, a otklanjanje nesaobraznosti moguće je opravkom samo uz Vašu izričitu
                saglasnost.
                <br/><br/>
                Ukoliko je nesaobraznost robe neznatna, molimo Vas da imate u vidu da nemate pravo na raskid ugovora
                i zahtev za povraćaj punog iznosa cene.
                <br/><br/>
                U slučaju da raskinete ugovor sa GoGorila doo isticanjem nesaobraznosti koja ne postoji ili neznatne
                nesaobraznosti, smatraće se da je došlo do zloupotrebe Vaših prava po osnovu nesaobraznosti, u kom
                slučaju GoGorila doo ima pravo da zahteva naknadu štete, koja obuhvata kako stvarnu štetu tako i
                izmaklu korist.
                <br/><br/>
                Saobraznost se daje isključivo na ispravnost uređaja a ne na njegovu funkcionalnost jer se podrazumeva
                da je Potrošač upoznat sa tehničkim karakteristikama proizvoda, pa se saobraznost odnosi na činjenicu
                da će izabrani proizvod zadovoljiti zahteve potrošača u okviru deklarisanih tehničkih mogućnosti
                propisanih od strane proizvođača. Saobraznost se ne odnosi na poslove održavanja proizvoda tj. cišćenje
                i sl. Od potrošača se očekuje da kupljeni proizvod ne izlaže štetnim dejstvima (duvanski dim, ekstremno
                prašnjave prostorije, ekstremno hladne ili ekstremno tople prostorije i sl.). Sve eventualne nepravilnosti
                prouzrokovane ovakvim tretmanom robe će se otklanjati isključivo na teret potrošača po važećem
                cenovniku servisnih usluga.
                <br/><br/>
                Pravo na saobraznost nije moguće ostvariti ukoliko je do oštećenja ili kvara robe došlo usled transporta
                nakon isporuke, usled nepravilne montaže, usled nepravilnog održavanja, usled neispravnih električnih
                instalacija, nestabilnog napona ili usled strujnog udara ili udara groma, usled više sile (poplava,
                zemljotres, požar i sl.), usled nepravilnog korišćenja, usled nepridržavanja proizvođačkog uputstva i
                preporuka o upotrebi proizvoda, usled korišćenja neodgovarajućeg potrošnog materijala koji nije kupljen
                uz proizvod, usled bilo kakvog mehaničkog oštećenja proizvoda koje je nastalo krivicom potrošača ili
                trećeg lica za koje Prodavac ne odgovara, usled neovlašćenog popravljanja proizvoda od strane trećeg
                lica koje nije ovlašćeno od Prodavca ili proizvođača, ukoliko je proizvod vraćen bez računa/otpremnice ili
                drugog dokaza da je kupljen kod Prodavca. Potrošač je dužan da prilikom preuzimanja kupljenog
                proizvoda ustanovi kompletnost i fizičku neoštećenost proizvoda koje preuzima. Naknadne reklamacije
                koje se odnose na kompletnost i fizičku neoštećenost neće biti prihvaćene.
            </p>

            <h4 className="mb-4 font-weight-bold">
                POSTUPAK UKOLIKO JE DONETA ODLUKA DA SE UVAŽI REKLAMACIJA
            </h4>
            <p className="mb-5">
                Ukoliko Vaša reklamacija bude usvojena, GoGorila doo će Vas obavestiti o tome pisanim putem ili putem
                e-maila.
                <br/><br/>
                Ukoliko ste se opredelili za <b>opravku kupljenog proizvoda</b>, GoGorila doo će Vam poslati opravljeni
                proizvod na isti način na koji Vam je prvobitno dostavljen reklamirani proizvod, na adresu koju ste naveli
                u reklamacionom listu.
                <br/><br/>
                Ukoliko Vas dostavljač ne pronađe na navedenoj adresi, kurirska služba će Vas kontaktirati i dogovoriti
                novi termin dostave.
                <br/><br/>
                Ukoliko nakon toga dostavljanje reklamirane robe bude i dalje onemogućeno Vašom krivicom, odnosno
                osobe koja je naznačena prilikom unošenja informacija o dostavi, proizvod(i) će biti vraćen(i) u sedište
                kompanije GoGorila doo.
                <br/><br/>
                Nakon isteka perioda od 30 dana GoGorila doo ne preuzima više nikakvu odgovornost za dati proizvod i
                proizvod može biti predat na recikliranje, odnosno rashodovan.
                <br/><br/>
                Ukoliko ste se opredelili za <b>zamenu reklamiranog proizvoda</b> drugim istovetnim ili sličnim proizvodom,
                GoGorila doo će Vam novi proizvod dostaviti na adresu u roku od 15 dana od dana kada ste izjavili
                reklamaciju.
                <br/><br/>
                Troškovi dostavljanja reklamirane robe Potrošaču na adresu, spadaju na teret prodavca.
                Ukoliko ste se opredelili za <b>raskid ugovora</b>, GoGorila doo će Vam vratiti pun iznos cene koji ste platili za
                reklamirani proizvod. Povrat novca Potrošaču izvršiće se uplatom na tekući račun Potrošača u roku od 15
                dana od dana izjavljivanja reklamacije.
            </p>

            <h4 className="mb-4 font-weight-bold">
                POSTUPAK UKOLIKO JE DONETA ODLUKA DA SE NE UVAŽI REKLAMACIJA
            </h4>
            <p className="mb-5">
                Ukoliko GoGorila doo ne uvaži Vašu reklamaciju, obavestićemo Vas o tome pisanim putem, uz
                istovremeno dostavljanje reklamiranog proizvoda putem preporučene pošiljke. U obaveštenju o
                okončanju postupka po podnetoj reklamaciji, navešćemo razloge i obrazloženje za odbijanje Vašeg
                zahteva.
                <br/><br/>
                Prodavac je dužan da potrošača sveobuhvatno obavesti o mogućnosti rešavanja spora vansudskim
                putem, kao I o nadležnim telima za vansudsko rešavanje potrošačkih sporova.
                <br /><br />


                U slučaju da se pošiljka ne može uručiti Potrošaču, usled činjenice da Potrošač ne živi na adresi koju je
                naveo prilikom izjavljivanja reklamacije (nepoznata adresa) ili usled činjenice da odbija prijem pošiljke,
                momentom pokušaja uručenja preporučene pošiljke na navedenu adresu smatraće se da je Potrošač
                uredno obavešten o odgovoru na reklamaciju.
                <br/><br/>
                Troškovi dostavljanja reklamirane robe Potrošaču na adresu, kada reklamacija nije uvažena, spadaju na
                teret potrošača.
            </p>

            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     POTROŠAČKI SPOR
                </span>
            </h4>

            <p className="mb-5">
                U slučaju da smatrate da su Vaša prava kao Potrošača ugrožena, imate pravo da pokrenete sudski ili
                vansudski postupak za rešavanje potrošačkog spora.
                <br/><br/>
                Potrošački spor može se rešiti postupkom vansudskog rešavanja potrošačkih sporova. Kao trgovac smo
                dužni da vas obavestimo da smo po zakonu obavezni da učestvujemo u ovom postupku. Vansudsko
                rešavanje potrošačkih sporova obavlja se na transparentan, efikasan, brz i pravičan način pred telom
                za vansudsko rešavanje potrošačkih sporova. Ministarstvo sačinjava listu tela i javno je objavljuje.
                Dostupna je na adresi <a
                href="https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php" target="_blank" rel="noreferrer">
                https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php
            </a>
            <br/><br/>
                Postupak pred telom može da pokrene potrošač samo ukoliko je prethodno izjavio reklamaciju ili
                prigovor trgovcu. Potrošač protekom jedne godine od dana (bezuspešnog) podnošenja reklamacije
                gubi pravo na podnošenje predloga za vansudsko rešavanje spora.
                <br/><br/>
                Vansudsko rešavanje potrošačkog spora može da traje najduže 90 dana od dana podnošenja predloga.
                <br/><br/>
                Vansudsko rešavanje potrošačkih sporova, ne primenjuje se, pored ostalog: <br /><br />
                &bull; u potrošačkim sporovima koji su predmet Zakona o zaštiti potrošača, ako je vansudsko
                rešavanje sporova uređeno posebnim zakonom, a naročito u oblasti pružanja elektronskih
                komunikacionih usluga, poštanskih usluga, finansijskih usluga, osim finansijskih pogodbi, usluga
                putovanja;<br/>

                &bull; za rešavanje sporova po procedurama koje je ustanovio sam trgovac; <br/>
                &bull; na neposredne pregovore između potrošača i trgovca; <br/>
                &bull; na pokušaj mirenja strana povodom spora u parničnom postupku; <br/>
                &bull; u postupcima koje je trgovac pokrenuo protiv potrošača.

            <br/><br/>
                Svaka stranka u postupku vansudskog rešavanja potrošačkog spora plaća svoje troškove (troškovi
                zastupanja, putni troškovi i sl.). Rad tela za vansudsko rešavanje potrošačkog spora je besplatan za
                stranke u postupku vansudskog rešavanja potrošačkog spora.
            </p>
        </div>
    };
}

export default Text2;
