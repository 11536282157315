import React from "react";


class Error404 extends React.Component {

    render = () => {
        return <div className="container p-5 font-weight-bold text-center">
            Ne postoji stranica.
        </div>
    }
}

export default Error404;