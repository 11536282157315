import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import {connect} from "react-redux";
import {breadcrumbs} from "../../../providers/categories";
import Title from "../../elements/Title";


class Categories extends React.Component {

    route = (e, route) => {
        e.preventDefault();
        this.props.newRoute(route);
    }

    state = {
        cats: false
    }

    render() {
        const category = this.props.category;
        const parents = breadcrumbs(category.id);
        if(category)        {
            return (
                <div className="category_tab">
                    <div>
                        <Link to={routes.shop.route()}  onClick={(e) => this.route(e, routes.shop.route())}
                              className="weight_500 link">
                            <i className="fa fa-arrow-left font_08" /> Sve kategorije
                        </Link>
                    </div>

                    {parents.length > 1 &&
                    parents.map((item) => {
                        if(this.state.cats) {
                            if(!this.state.cats[item.id]) return "";
                        }
                        if(item.id === category.id) return "";
                        return <div key={item.id}>
                            <Link to={routes.shop.route(item.id)} className="weight_500 link"
                                  onClick={(e) => this.route(e, routes.shop.route(item.id))}>
                                <i className="fa fa-arrow-left font_08" /> {item.name}
                                <span className="ml-1 font-weight-normal font_09 text-black-50">
                                    ({this.state.cats ? this.state.cats[item.id] : item.count})
                                </span>
                            </Link>
                        </div>
                    })}
                    <div className="pt-3 pb-3">
                        <Title font={2} name={category.name}
                        appendName={<span className="font-weight-normal font_09 text-black-50">({category.count})</span>} />
                    </div>
                    {category.children.length > 0 &&
                    category.children.map((item) => {
                        if(this.state.cats) {
                            if(!this.state.cats[item.id]) return "";
                        }
                        if(parseInt(item.count) === 0)  {
                            return "";
                        }
                        return <div key={item.id}>
                            <Link to={routes.shop.route(item.id)} className="weight_500 link"
                                  onClick={(e) => this.route(e, routes.shop.route(item.id))}>
                                {item.name}
                                <span className="ml-1 font-weight-normal font_09 text-black-50">
                                ({this.state.cats ? this.state.cats[item.id] : item.count})
                            </span>
                            </Link>
                        </div>
                    })}
                </div>
            )
        }
        return (
            <div className="">
                <div className="mb-3">
                    <Title font={2} name="Kategorije" />
                </div>
                {this.props.categories.map((item) => {
                    if(this.state.cats) {
                        if(!this.state.cats[item.id]) return "";
                    }
                    if(parseInt(item.count) === 0)  {
                        return "";
                    }
                    return <div key={item.id}>
                        <Link to={routes.shop.route(item.id)}
                              onClick={(e) => this.route(e, routes.shop.route(item.id))}
                              className="weight_500 link">
                            {item.name}
                            <span className="ml-1 font-weight-normal font_09 text-black-50">
                                ({this.state.cats ? this.state.cats[item.id] : item.count})
                            </span>
                        </Link>
                    </div>
                })}
            </div>
        );
    }
    cats = () => {
        let cats = false;
        if(this.props.cats) {

            cats = {};
            for(let i=0; i<this.props.cats.length; i++)    {
                const catCount = this.props.cats[i];
                cats[catCount.category_id] = catCount.count;
                const cat = this.props.categoriesById[catCount.category_id];
                if(cat.parent)  {
                    cats[cat.parent] = (cats[cat.parent] || 0) + catCount.count;
                    const parent = this.props.categoriesById[cat.parent];
                    if(parent.parent)   {
                        cats[parent.parent] = (cats[parent.parent] || 0) + catCount.count;
                    }
                }
            }
        }
       this.setState({...this.state, cats: cats});
    }
    componentDidMount() {
        this.cats();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.cats !== prevProps.cats)  {
            this.cats();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
    categoriesById: state.categoriesById
});
export default connect(mapStateToProps)(Categories);
