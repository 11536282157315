import React from "react";
import slugify from "slugify";
import {connect} from "react-redux";


class Input extends React.Component {

    state = {
        value: this.props.value || "",
        changed: false
    }

    changed = (e) => {
        var val = e.target.value;
        if(this.props.slugify) {
            if(val[val.length-1] === " ") val = val + "-"
            val = slugify(val);
        }
        if(this.props.link) {
            val = val.replace("https:", "");
            val = val.replace("http:", "");
        }
        if(val === this.state.value) return false;
        this.setState({...this.state, value: val, changed: true});
        if(this.props.onChange)  {
            this.props.onChange(e);
        }
    }

    blured = (e) => {
        if(this.props.submit)       {
            return false;
        }
        this.process(e.target.value);
    }

    submit = () => {
        this.process(this.state.value);
    }

    process = (val) => {
        if(this.state.changed)     {
            if(this.props.price)        {
                val = val.replace(" ", "").replace(",", ".");
            }
            if(this.props.nullable && val === "") val = null;
            if(this.props.modifier)     {
                val = this.props.modifier(val);
            }
            this.setState({...this.state, changed: false});
            if(this.props.onSaved)   {
                this.props.onSaved(val);
            }
        }
    }

    keyDown = (e) => {
        let code = e.keyCode;
        if(code === 13)     {
            this.blured(e);
        }
    }

    render = () => {
        return <>
            {this.props.title}
            <div className="input-group">
            <input
                style={{width: this.props.width || ""}}
                disabled={this.props.disabled}
                value={this.state.value}
                placeholder={this.props.placeholder || ""}
                onChange={this.changed}
                onBlur={this.blured}
                onKeyDown={this.keyDown}
                name={this.props.name}
                type={this.props.type || "text"}
                maxLength={this.props.maxLength || ""}
                className={this.props.size ? "form-control form-control-" + this.props.size : "form-control"}
                autoComplete={this.props.autoComplete || ""}
                required={this.props.required || false} />
            {this.props.submit && this.state.changed &&
            <div className="input-group-append">
                <button onClick={this.submit} className="btn btn-success" type="button">Sačuvaj</button>
            </div>}
        </div>
        </>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.update !== this.props.update)      {
            let val = this.props.update;
            if(this.props.slugify) {
                val = slugify(val).toLowerCase();
            }
            this.setState({...this.state, value: val});
            if(this.props.onChange)  {
                this.props.onChange({target: {name: this.props.name, value: val}});
            }
        }
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Input);
