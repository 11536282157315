import React from "react";
import logo from "../../../assets/images/logo.png";

class AuthLayout extends React.Component {

    render = () => {
        return <div className="mt-5 mb-5 ml-3 mr-3 d-flex justify-content-center">
            <div style={{maxWidth: 440, width: '100%'}}>
                <div className="text-center mb-3">
                    <img src={logo} width={260} alt="logo" className="img-fluid" />
                </div>

                {this.props.children}
            </div>
        </div>
    }
}

export default AuthLayout;