import React from "react";


class Text3 extends React.Component {


    render = () => {

        return <div className="container mt-2 mt-md-5 mb-5 footer_text">
            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                     POLITIKA PRIVATNOSTI
                </span>
            </h4>
            <p className="mb-4">
                U skladu sa odredbama Zakona o zaštiti ličnih podataka („Sl. Glasnik RS“ broj 87/2018) GOGORILA DOO
                Čačak, donosi:
            </p>

            <h4 className="mb-4 title_underline font-weight-bold">
                <span className="title">
                      SAOPŠTENJE O KORIŠĆENJU PODATAKA O LIČNOSTI
                </span>
            </h4>
            <p className="mb-5">
                Ovim Saopštenjem se od strane GoGorila doo, Čačak, Bulevar oslobođenja 17, PIB:112107021, Matični broj:
                21608637 korisnici usluga upoznaju sa načelima obrade, pravom na zaštitu fizičkih lica u vezi sa obradom
                podataka o ličnosti i slobodnim protokom takvih podataka, pravima lica na koje se podaci odnose,
                obavezama rukovalaca i obrađivača podataka o ličnosti, kodeksom postupanja, prenosom podataka o
                ličnosti u druge države i međunarodne organizacije, nadzorom nad sprovođenjem ovog zakona, pravna
                sredstva, odgovornost i kazne u slučaju povrede prava fizičkih lica u vezi sa obradom podataka o
                ličnosti.
                GoGorila doo, Čačak, preduzeće za trgovinu na malo posredstvom pošte ili interneta, u obavljanju svoje
                delatnosti prikuplja i obrađuje podatke o ličnosti poštujući sve relevantne propise.
                Zaštita podataka o ličnosti je uređena Zakonom o zaštiti podataka o ličnosti (dalje:Zakon) koji se
                primarno primenjuje i na osnovu kog se donosi Saopštenje.
                <br/><br/>
                Osnovni motiv i cilj ovog saopštenja je da se obezbedi zaštita osnovnih prava i sloboda fizičkih lica, a
                posebno njihovog prava na zaštitu podataka o ličnosti, kom se od strane našeg privrednog društva
                poklanja velika pažnja.
                <br/><br/>
                Stoga, molimo Vas da pažljivo pročitate ovu Politiku kako biste u potpunosti razumeli sva važna pitanja
                koja se tiču zaštite Vaše privatnosti.
                <br/><br/>
                1. <a href="/politika-privatnosti#ko-je-rukovodilac-podacima-o-licnosti">
                Ko je rukovalac podacima o ličnosti?
            </a> <br/>
                2. <a href="/politika-privatnosti#definicije-u-politici">Definicije u Politici</a><br/>
                3. <a href="/politika-privatnosti#primena-politike">Primena Politike</a><br/>
                4. <a href="/politika-privatnosti#kako-i-kada-prikupljamo-vase-podatke">Kako i kada prikupljamo Vaše
                podatke o ličnosti?</a><br/>
                5. <a href="/politika-privatnosti#vrsta-podataka">Vrsta podataka, svrha i osnov</a><br/>
                6. <a href="/politika-privatnosti#gde-i-kako-cuvamo-vase-podatke">Gde i kako čuvamo Vaše
                podatke?</a><br/>
                7. <a href="/politika-privatnosti#obradjivaci-podataka-o-licnosti">Obrađivači podataka o
                ličnosti</a><br/>
                8. <a href="/politika-privatnosti#koliko-dugo-cuvamo-vase-podatke">Koliko dugo čuvamo Vaše
                podatke?</a><br/>
                9. <a href="/politika-privatnosti#vasa-prava">Vaša prava</a><br/>
                10. <a href="/politika-privatnosti#stupanje-na-snagu-i-izmene-politike">Stupanje na snagu i izmene
                Politike</a>
            </p>

            <h4 id="ko-je-rukovodilac-podacima-o-licnosti" className="mb-4 font-weight-bold">
                KO JE RUKOVALAC PODACIMA O LIČNOSTI?
            </h4>
            <p className="mb-5">
                GoGorila je rukovalac podacima o ličnosti, odnosno subjekt koji određuje svrhu i način na koji se
                obrađuju podaci o ličnosti, u smislu Zakona o zaštiti podataka o ličnosti.
                <br/><br/>
                Kako bismo Vam omogućili da na što jednostavniji način ostvarite Vaša prava, u slučaju da imate pitanja
                ili nedoumica u pogledu zaštite Vaših podataka, možete nas kontaktirati slanjem email-a na adresu:
                <a href="mailto:web@gogorila.com">web@gogorila.com</a> ili pozivom na broj
                telefona: <a href="tel:+381 064 860 15 54">064/860-15-54</a>.
                <br/><br/>
                Obrada podataka se vrši u cilju efikasnog i funkcionalnog vršenja informacionih usluga koji
                predstavljaju
                sadržinu usluga koje GoGorila pruža svojim klijentima, obezbedili pravnu sigurnost i zaštitili klijete
                od
                svih vrsta zloupotreba i manipulacija na tržištu, omogućili neometano i kontinuirano korišćenje internet
                stranice i mobilne aplikacije.
                <br/><br/>
                U slučajuj kada je obrada neophodna u cilju ostvarivanja legitimnih interesa rukovaoca ili treće strane,
                osim ako su nad tim interesima pretežniji interesi ili osnovna prava i slobode lica na koje se podaci
                odnose koji zahtevaju zaštitu podataka o ličnosti, a posebno ako je lice na koje se podaci odnose
                maloletno lice, korisnik će biti obavešten o osnovu prikupljanja podataka.
            </p>

            <h4 id="definicije-u-politici" className="mb-4 font-weight-bold">
                2. DEFINICIJE U POLITICI
            </h4>
            <p className="mb-5">
                <table className="table table-bordered">
                    <tbody>
                    <tr className="font-weight-bold">
                        <td>#</td>
                        <td>DEFINICIJA</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>
                            <b> „E-prodavnica”</b> podrazumeva elektronsku prodaju koja se obavlja putem Sajta i koja
                            nudi
                            proizvode iz asortimana trgovca GoGorila.
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>
                            <b> Gost</b> jeste Kupac koji obavlja porudžbinu proizvoda bez registracije naloga na Sajtu.
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>
                            <b>„Korisnik Sajta”</b> obuhvata svako fizičko lice koje pristupi Sajtu, nezavisno od toga
                            da li se
                            registrovalo ili je poručilo proizvode preko Sajta u svojstvu Gosta.
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>
                            <b>„Lice”</b> ili <b>„Vi”</b> jesu sva fizička lica koja dele podatke o ličnosti sa nama,
                            nezavisno da li su
                            Korisnici Sajta, Gosti ili Registrovani korisnici.
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>
                            <b>„Obrađivač</b> je svako fizičko ili pravno lice koje obrađuje podatke u ime Rukovaoca.
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>
                            <b>„Opšti uslovi poslovanja”</b> predstavljaju uslove poslovanja koji čine sastavni deo
                            Ugovora koji
                            zaključujete sa GoGorila i koji su dostupni na Sajtu.
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>
                            <b>„Podatak”</b> se odnosi na podatak o ličnosti, odnosno podatak koji se odnosi na fizičko
                            lice čiji je
                            identitet određen ili odrediv, neposredno ili posredno na osnovu tih podataka. U podatke o
                            ličnosti ne svrstavaju se podaci koji se odnose na entitete poput pravnih lica, životinja
                            ili slično.
                            Međutim, ukoliko podatak o nekom pravnom licu može da dovede do identifikacije nekog
                            fizičkog lica, takav podatak se smatra podatkom o ličnosti. Primera radi, podaci o
                            preduzetnicima ili jednočlanim društvima u kojima su sadržani lični podaci fizičkih lica.
                            Takođe,
                            ovim pojmom su obuhvaćeni i podaci u vezi sa poslovnim aktivnostima fizičkih lica, kao što
                            su
                            email adrese zaposlenih u pravnim licima koja glasi „ime.prezime@firma.com”, a koja lako
                            mogu dovesti do identifikacije fizičkog lica.
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>
                            <b>„Politika kolačića”</b> se odnosi na posebna pravila o prikupljanju pojedinih podataka o
                            ličnosti
                            na Sajtu putem kolačića, koja čine sastavni deo Politike privatnosti.
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>
                            <b>„Poverenik”</b> je Poverenik za informacije od javnog značaja i zaštitu podataka o
                            ličnosti, Bulevar
                            kralja Aleksandra 15, 11000 Beograd, e-mail <a
                            href="mailto:office@poverenik.rs">office@poverenik.rs</a>.
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>
                            <b>„Registrovani korisnik”</b> je lice koje ima registrovan nalog na Sajtu.
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>
                            <b>„Rukovalac”</b> se odnosi na GoGorila kao subjekt koji određuje svrhu i način na koji se
                            obrađuju
                            Podaci.
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>
                            <b>„Saglasnost”</b> se odnosi na Vaš nedvosmisleni pristanak na obradu podataka o ličnosti.
                            Lice
                            starije od 15 godina može samostalno dati saglasnost za obradu podataka o ličnosti u
                            korišćenju usluga informacionog društva. Lica mlađa od 15 godina mogu dati saglasnost preko
                            svojih roditelja/staratelja. Saglasnost je samo jedan od zakonskih osnova za obradu
                            podataka,
                            pa se podaci mogu obrađivati i po drugim osnovima.
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>
                            <b>„Sajt”</b> se odnosi na web sajt na adresi https://www.gogorila.com/.
                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>
                            <b>„Zakon”</b> označava Zakon o zaštiti podataka o ličnosti Republike Srbije.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className="mt-3">
                    Ostali termini koji su u Politici napisani velikim slovom su definisani
                    u <a href="/opsti-uslovi-poslovanja">Opštim uslovima poslovanja</a>.
                </div>
            </p>

            <h4 id="primena-politike" className="mb-4 font-weight-bold">
                3. PRIMENA POLITIKE
            </h4>
            <p className="mb-5">
                Ova Politika se, shodno i u relevantnim delovima, primenjuje na Lica koja su:
                <br/><br/>
                a. Korisnici Sajta nezavisno od toga da li su posetioci ili Registrovani korisnici,<br/>
                b. Kupci.<br/><br/>
                Politika se primenjuje samo na podatke o ličnosti fizičkih lica, kako je objašnjeno
                u odeljku <a href="/politika-privatnosti#definicije-u-politici">Definicije u Politici</a>.
                <br/><br/>
                GoGorila naročito brine o privatnosti i bezbednosti maloletnika na Internetu, zbog toga nećemo
                prikupljati i obrađivati Podatke o licima koja su mlađa od 15 godina. Lica mlađa od 15 godina ne smeju
                da
                nam otkrivaju svoje podatke o ličnosti. Ukoliko imate saznanje ili sumnjate da smo prikupili podatke o
                licima mlađim od 15 godina, molimo Vas da nas bez odlaganja obavestite
                na <a href="mailto:web@gogorila.com">web@gogorila.com</a>, a
                GoGorila će preduzeti sve neophodne mere i procedure radi što efikasnijeg otklanjanja eventualne
                greške.
            </p>

            <h4 id="kako-i-kada-prikupljamo-vase-podatke" className="mb-4 font-weight-bold">
                4. KAKO I KADA PRIKUPLJAMO VAŠE PODATKE O LIČNOSTI?
            </h4>
            <p className="mb-5">

                Podaci o ličnosti moraju: <br/><br/>
                1. se obrađivati zakonito, pošteno i transparentno u odnosu na lice na koje se podaci odnose
                (&quot;zakonitost, poštenje i transparentnost&quot;). Zakonita obrada je obrada koja se vrši u skladu sa
                zakonom, odnosno drugim zakonom kojim se uređuje obrada;<br/>
                2. se prikupljati u svrhe koje su konkretno određene, izričite, opravdane i zakonite i dalje se ne
                mogu obrađivati na način koji nije u skladu sa tim svrhama (&quot;ograničenje u odnosu na svrhu
                obrade&quot;);<br/>
                3. biti primereni, bitni i ograničeni na ono što je neophodno u odnosu na svrhu obrade
                (&quot;minimizacija podataka&quot;);<br/>
                4. biti tačni i, ako je to neophodno, ažurirani. Uzimajući u obzir svrhu obrade, moraju se
                preduzeti sve razumne mere kojima se obezbeđuje da se netačni podaci o ličnosti bez
                odlaganja izbrišu ili isprave (&quot;tačnost&quot;);<br/>
                5. se čuvati u obliku koji omogućava identifikaciju lica samo u roku koji je neophodan za
                ostvarivanje svrhe obrade (&quot;ograničenje čuvanja&quot;);<br/>
                6. se obrađivati na način koji obezbeđuje odgovarajuću zaštitu podataka o ličnosti, uključujući
                zaštitu od neovlašćene ili nezakonite obrade, kao i od slučajnog gubitka, uništenja ili
                oštećenja primenom odgovarajućih tehničkih, organizacionih i kadrovskih mera (&quot;integritet i
                poverljivost&quot;). Rukovalac je odgovoran za primenu odredaba stava 1. ovog člana i mora biti u
                mogućnosti da predoči njihovu primenu (&quot;odgovornost za postupanje&quot;).
                <br/><br/>
                Prikupljanje podataka o ličnosti se u prvom redu odvija prilikom uspostavljanja poslovne saradnje, kada
                se prikupljaju podaci o imenu i prezimenu fizičkog lica, adresi prebivališta, datumu rođenja, BPG,
                PIB-u,
                matičnom broju, e-mail adresi i broju telefona.
                <br/><br/>
                Svrha prikupljanja ovih podataka jeste pružanje informacionih usluga, što je privredna delatnost kojom
                se GoGorila doo bavi, bezbednost i zaštita korisnika usluga, obezbeđivanje objavljivljivanja sadržaja na
                internet platformi i mobilnoj aplikaciji u skladu sa važećim propisima, te sačinjavanje i izdavanje
                finansijskih dokumenata u skladu sa odredbama Zakona o porezu na dodatu vrednost.
                <br/><br/>
                GoGorila može na nekoliko različitih načina da prikuplja Vaše Podatke.
                <br/><br/>
                Najčešće podatke prikupljamo direktno od Vas – kada pristupite našem Sajtu, prilikom kupovine i/ili
                naručivanja proizvoda od nas, kada nas kontaktirate radi pružanja podrške kupcima ili kada su Vaši
                podaci na drugi način relevantni za kupovinu, odnosno naše poslovanje zbog čega nam podatke direktno
                dostavljate.
                <br/><br/>
                GoGorila naročito vodi računa o Vašim podacima koje prikupljamo u marketinške svrhe. Obrada Vaših
                podataka u marketinške svrhe će se vršiti isključivo na osnovu Vaše prethodne izričite saglasnosti. O

                tome Vas uvek posebno obaveštavamo, a u svakom momentu možete odlučiti da povučete svoju
                saglasnost datu na takvu obradu. Za više informacija, molimo Vas pročitajte
                odeljak <a href="/politika-privatnosti#vasa-prava">Vaša prava</a>.
                <br/><br/>
                Pojedine podatke prikupljamo automatski, kada koristite naš Sajt. To su podaci poput IP adrese, vrste
                uređaja, vrste pretraživača i druge tehničke informacije. Ove podatke ćemo najčešće prikupiti putem
                kolačića ili drugih sličnih tehnologija, o čemu se detaljnije možete informisati u
                našoj <a href="/politika-kolacica" target="_blank" rel="noreferrer">Politici kolačića </a> .
                <br/><br/>
                Većina navedenih podataka nam je neophodna kako bismo Vam pružili potpuno korisničko iskustvo
                našeg Sajta. U tom smislu, ukoliko ne želite da nam otkrijete podatke o ličnosti koji su nam neophodni,
                ne
                možemo garantovati da će pružene informacije i usluge u potpunosti zadovoljiti Vaše potrebe.
                Svaki način prikupljanja podataka ima svoju svrhu i pravni osnov zasnovan na Zakonu, o čemu detaljne
                informacije možete naći u sledećem odeljku Politike.
            </p>

            <h4 id="vrsta-podataka" className="mb-4 font-weight-bold">
                5. VRSTA PODATAKA, SVRHA I OSNOV
            </h4>
            <h5 className="mb-4 font-weight-bold">
                REGISTRACIJA NALOGA
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Ukoliko želite da izvršite kupovinu proizvoda putem naše e-prodavnice ili
                kako bismo
                Vam omogućili korišćenje dodatnih funkcija našeg Sajta, možete registrovati nalog na Sajtu. Registracija
                korisničkog naloga nije obavezna, a Vaš nalog uvek možete obrisati.
                <br/> <br/>
                <b>Podaci koje prikupljamo:</b> Ime i prezime, email adresa, lozinka, broj telefona, adresa na koju će
                biti
                izvršena isporuka i dostavljena faktura.
                <br/> <br/>
                <b>Osnov obrade:</b> Obrada je neophodna za održavanje Vašeg naloga i za izvršenje ugovora zaključenog
                sa
                licem na koje se podaci odnose ili za preduzimanje radnji, na zahtev lica na koje se podaci odnose, pre
                zaključenja ugovora. U odsustvu takvih podataka ne bi bilo moguće izvršiti registraciju korisnika, ali
                bi se
                ugovor o prodaji mogao zaključiti i izvršiti ukoliko Kupac, koji je fizičko lice, izvrši kupovinu kao
                Gost.
                Drugim rečima, registracija nije neophodna da biste izvršili kupovinu naših proizvoda.
            </p>
            <h5 className="mb-4 font-weight-bold">
                OBRADA PORUDŽBINA
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Kako bismo mogli da obradimo Vašu porudžbinu i izvršimo isporuku proizvoda,
                neophodno je da sa nama podelite određene podatke o ličnosti, koji su neophodni za izvršenje
                kupoprodajnog ugovora. U tom smislu, podaci su nam neophodni za zaključenje i izvršenje ugovora, za
                naplatu proizvoda, dostavljanje poručenih proizvoda, kao i za upravljanje i primanje prigovora, pritužbi
                i
                reklamacija.
                <br/> <br/>
                <b>Podaci koje prikupljamo:</b> Ime i prezime, email adresa, broj telefona, adresa, grad, poštanski
                broj, a
                ukoliko se odlučite za elektronsko plaćanje, prikupićemo i podatke o broju Vaše platne kartice, banci i
                tekućem računu.
                <br/> <br/>
                <b>Osnov obrade:</b> Obrada je neophodna za izvršenje ugovora o prodaji koji zaključujemo sa Vama ili za
                preduzimanje radnji, na Vaš zahtev, pre zaključenja ugovora. U odsustvu takvih podataka, ne bi bilo
                moguće ni zaključenje ni izvršavanje ugovora.
            </p>
            <h5 className="mb-4 font-weight-bold">
                PRAĆENJE OBRAZACA PONAŠANJA, IZRADA I SLANJE PERSONALIZOVANIH PONUDA
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Prilikom porudžbine na Sajtu možete da odaberete opciju da ste saglasni sa
                praćenjem obrazaca svog ponašanja, na osnovu čega će GoGorila izraditi i poslati Vama personalizovanu
                ponudu. Na taj način dajete saglasnost da pratimo podatke o Vašim ranijim kupovinama, na osnovu čega
                će se formirati personalizovane ponude, tj. ponude o proizvodima za koje verujemo da ćete biti
                zainteresovani.
                <br/> <br/>
                <b>Podaci koje prikupljamo:</b> Koristimo podatke koji su prikupljeni prilikom obrade porudžbine.
                Kasnije
                prikupljamo podatke o sprovedenim kupovinama kako bismo utvrdili obrasce Vašeg ponašanja i
                preporučili Vam proizvode za koje verujemo da ćete biti zainteresovani.
                <br/> <br/>
                <b>Osnov obrade:</b> Vaše podatke koristimo u navedenu svrhu samo na osnovu Vaše izričite saglasnosti,
                koju
                možete dati prilikom registracije naloga ili prilikom kupovine. Niste u obavezi da date ovu saglasnost,
                i u
                tom slučaju nećemo pratiti Vaše ponašanje, niti ćemo Vam slati personalizovane ponude.U slučaju da se
                saglasite, ovu saglasnost možete povući u svako doba, s tim da to neće uticati na zakonitost obrade koja

                je vršena dok je postojala saglasnost. Više informacija o povlačenju saglasnosti možete naći u
                odeljku <a href="/politika-privatnosti#vasa-prava">Vaša prava</a>.
            </p>

            <h5 className="mb-4 font-weight-bold">
                PODRŠKA KUPCIMA
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Ukoliko Vam je potrebna bilo kakva podrška u vezi sa poručenim proizvodima ili
                korisničkim nalogom, možete nas kontaktirati putem Sajta, email-a ili telefona (na primer, radi dodatnih
                informacija, načinu da ostvarite svoja prava, dodatnim upitima, reklamacijama, pravu na odustanak ili
                zamenu proizvoda i sl.). U takvim situacijama biće nam potrebni Vaši podaci kako bismo mogli da Vam
                pružimo pomoć, odnosno rešimo o zahtevu koji ste nam uputili.
                <br/><br/>
                <b>Podaci koje prikupljamo:</b> Prikupljamo sledeće podatke: ime, broj telefona, email, broj tekućeg računa i
                adresu stanovanja u slučaju reklamacije, kao i druge podatke vezane za pitanje zbog koga ste nas
                kontaktirali, a koje sami odlučite da nam dostavite.
                <br/><br/>
                <b>Osnov obrade:</b> Obrada je neophodna u cilju poštovanja pravnih obaveza odnosno radi pružanja podrške
                Kupcima. Obrada se vrši i na osnovu našeg legitimnog interesa, jer podatke koristimo kako bismo Vam
                dali informacije koje tražite od nas ili koje Vam mogu biti od pomoći povodom zahteva ili pitanja koje
                ste
                uputili našoj službi za pomoć Kupcima.
            </p>
            <h5 className="mb-4 font-weight-bold">
                NEWSLETTER
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Na našem Sajtu se možete prijaviti za Newsletter, ukoliko želite da budete
                informisani
                o novinama, ponudama i prodajnim podsticajima koje nudimo.
                <br/><br/>
                <b>Podaci koje prikupljamo:</b> Podatak koji se prikuplja je e-mail.
                <br/><br/>
                <b>Osnov obrade:</b> Podaci se prikupljaju na osnovu Vaše izričite saglasnosti. U slučaju da se saglasite, ovu
                saglasnost možete povući u svako doba, s tim da to neće uticati na zakonitost obrade koja je vršena dok
                je postojala saglasnost. Više informacija o povlačenju saglasnosti možete naći u
                odeljku <a href="/politika-privatnosti#vasa-prava">Vaša prava</a>
            </p>
            <h5 className="mb-4 font-weight-bold">
                LOYALTY PROGRAM I KUPONI ZA POPUST
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Prilikom kupovine proizvoda preko našeg Sajta, možete odabrati opciju da se
                prijavite
                za dobijanje kupona za popust, na osnovu kojih možete ostvariti određene pogodnosti prilikom kupovine
                (akcije, popusti, drugi prodajni podsticaji).
                <br/><br/>
                <b>Podaci koje prikupljamo:</b> Podatak koji se prikuplja je e-mail.
                <br/><br/>
                <b>Osnov obrade:</b> Podaci se prikupljaju na osnovu Vaše saglasnosti. U slučaju da se saglasite, ovu
                saglasnost
                možete povući u svako doba, s tim da to neće uticati na zakonitost obrade koja je vršena dok je
                postojala
                saglasnost.
            </p>
            <h5 className="mb-4 font-weight-bold">
                OSTAVLJANJE RECENZIJA
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Ukoliko želite da podelite Vaše iskustvo i ocenite naš proizvod, možete ostaviti
                recenziju na našem Sajtu.
                <br/><br/>
                <b> Podaci koje prikupljamo:</b> Podatak koji se prikuplja je Vaše ime i prezime, kao i sadržaj recenzije,
                odnosno
                ocene u kojem mogu da se nalaze podaci o ličnosti koje odlučite da podelite sa nama.
                <br/><br/>
                <b> Osnov obrade:</b> Podaci se prikupljaju na osnovu Vaše saglasnosti. U slučaju da se saglasite, ovu
                saglasnost
                možete povući u svako doba, s tim da to neće uticati na zakonitost obrade koja je vršena dok je
                postojala
                saglasnost. Više informacija o povlačenju saglasnosti možete naći u
                odeljku <a href="/politika-privatnosti#vasa-prava">Vaša prava</a>.
            </p>
            <h5 className="mb-4 font-weight-bold">
                INFORMISANJE O IZMENAMA OPŠTIH USLOVA POSLOVANJA ILI POLITIKE PRIVATNOSTI
            </h5>
            <p className="mb-5">
                <b>Svrha prikupljanja:</b> Ukoliko imamo podatak o Vašem e-mailu, prikupljen radi ostvarivanja jedne od
                prethodno navedenih svrha, možemo Vam poslati obaveštenje o izmenama Opštih uslova poslovanja ili
                Politike privatnosti.
                <br/><br/>
                <b>Podaci koje prikupljamo:</b> Email adresa.
                <br/><br/>
                <b>Osnov obrade:</b> Obrada se vrši u cilju ostvarenja našeg legitimnog interesa, ali i interesa samih Kupaca,
                kako bi se obezbedila transparentnost u odnosu na Kupce i kako bi Kupci mogli da razmotre da li su
                izmene od uticaja na njih. Ukoliko ne raspolažemo Vašim podacima za navedenu svrhu, nećemo Vas
                direktno obavestiti o izmeni ovih akata, a obaveštenje o izmeni ćete moći da vidite na našem Sajtu.
                <br/><br/>
                Pored navedenih podataka, pojedine podatke o ličnosti prikupljamo putem kolačića
                u skladu sa <a href="/politika-kolacica" target="_blank" rel="noreferrer">Politikom kolačića</a>.
            </p>

            <h4 id="gde-i-kako-cuvamo-vase-podatke" className="mb-4 font-weight-bold">
                6. GDE I KAKO ČUVAMO VAŠE PODATKE?
            </h4>
            <p className="mb-5">
                Veoma smo posvećeni čuvanju Vaših podataka od neovlašćenog ili nezakonitog obrađivanja, od slučajnog
                ili nezakonitog gubitka, izmene, kao i od neovlašćenog pristupa Vašim podacima o ličnosti. Sa tim ciljem,
                implementirali smo primerene tehničke i organizacione mere, poput kontrole fizičkog pristupa, enkripcije
                podataka, sigurnosti lozinke.
                <br/><br/>
                Najveći broj Vaših podataka biće sačuvan u elektronskom obliku, uz primenu standardnih sigurnosnih
                principa i procedura.
                <br/><br/>
                Prikupljeni podatci o ličnosti se čuvaju za vreme koliko je potrebno radi ostvarenja konkretne svrhe, ili
                pak do momenta opoziva datog pristanka, odnosno do isteka vremenskog perioda na koji je dat pristanak
                za obradu, nakon čega se podaci brišu ili se čine neprepoznatljivim. Podaci koji su prikupljeni prilikom
                registracije čuvaju se do deaktivacije korisničkog naloga.
                <br/><br/>
                Izuzetno, podaci mogu da se čuvaju i nakon povlačenja pristanka ili ispunjenja svrhe, u situacijama kada
                je to neophodno radi izvršenja zakonske obaveze ili radi podnošenja, ostvarivanja ili odbrane pravnog
                zahteva.
            </p>

            <h4 id="obradjivaci-podataka-o-licnosti" className="mb-4 font-weight-bold">
                7. OBRAĐIVAČI PODATAKA O LIČNOSTI
            </h4>
            <p className="mb-5">
                Obrada podataka se vrši na osnovu sledećih zakonskih kriterijuma:
                <br/><br/>
                1) lice na koje se podaci o ličnosti odnose je pristalo na obradu svojih podataka o ličnosti za jednu ili više
                posebno određenih svrha;<br/>
                2) obrada je neophodna za izvršenje ugovora zaključenog sa licem na koje se podaci odnose ili za
                preduzimanje radnji, na zahtev lica na koje se podaci odnose, pre zaključenja ugovora;
                3) obrada je neophodna u cilju poštovanja pravnih obaveza rukovaoca;<br/>
                4) obrada je neophodna u cilju zaštite životno važnih interesa lica na koje se podaci odnose ili drugog
                fizičkog lica;<br/>
                5) obrada je neophodna u cilju obavljanja poslova u javnom interesu ili izvršenja zakonom propisanih
                ovlašćenja rukovaoca;<br/>
                6) obrada je neophodna u cilju ostvarivanja legitimnih interesa rukovaoca ili treće strane, osim ako su
                nad tim interesima pretežniji interesi ili osnovna prava i slobode lica na koje se podaci odnose koji
                zahtevaju zaštitu podataka o ličnosti, a posebno ako je lice na koje se podaci odnose maloletno lice
                Pristup prikupljenim podacima imaju zaposleni u GoGorila doo, kao i druga lica angažovana u GoGorila
                doo na osnovu drugih formi angažovanja, u meri u kojoj je to potrebno za ispunjavanje zadataka i ciljeva
                obrade a u zavisnosti od svrhe prikupljanja i naši poslovni saradnici, u meri u kojoj je to potrebno radi
                ostvarivanja specifičnih zadataka i ciljeva.<br/><br/>
                Sa ciljem postizanja najvišeg standarda u pružanju naših usluga, GoGorila koristi usluge trećih lica, koja
                nam pomažu u ostvarenju tog zadatka. U svakom trenutku nastojimo da Vam omogućimo brzu, ugodnu i
                sigurnu kupovinu, a treća lica koja angažujemo u tom pogledu nalaze se u ulozi Obrađivača podataka o
                ličnosti.
                <br/><br/>
                Svi Obrađivači sa kojima sarađujemo su, u skladu sa ugovorima koje potpisujemo sa njima, u obavezi da
                koriste podatke koji su im povereni isključivo u skladu sa našim uputstvima, i strogo za svrhu koju smo
                naveli. Obrađivači su dužni da obezbede čuvanje poverljivosti podataka koje smo im preneli i odgovorni
                su za bezbednost podataka.
                <br/><br/>
                U pojedinim slučajevima, Vaši podaci mogu biti izneti izvan Srbije, na serverima koji se nalaze u drugim
                državama. Serveri koje koristimo ispunjavaju nivo zaštite koji predviđa Opšta uredba EU o zaštiti
                podataka o ličnosti (GDPR), te je nivo zaštite podataka o ličnosti (u najmanju ruku) jednak nivou zaštite u
                okviru Republike Srbije.
                <br/><br/>
                U slučaju kada podatke iznosimo u zemlje van EU, onda vodimo računa da u tim zemljama postoji
                adekvatan nivo zaštite podataka o ličnosti.
                <br/><br/>
                Iz tabele u nastavku možete saznati ko su naši trenutni Obrađivači, koja je njihova uloga i gde je njihovo
                sedište.
                <br/><br/>
            </p>

            <table className="table table-bordered">
                <tbody>
                <tr className="font-weight-bold">
                    <td>Obrađivač</td><td>Uloga</td><td>Sedište</td>
                </tr>
                <tr>
                    <td>YU-PD Express doo</td><td> Usluge dostavljanja</td><td> Srbija</td>
                </tr>
                <tr>
                    <td>CITY EXPRES DOO</td> <td>Usluge dostavljanja </td><td>Srbija</td>
                </tr>
                <tr>
                    <td>Google LLC, 1600 Amphitheatre Parkway, Mountain View, GCA
                        94043</td>
                    <td>Analitičke i marketinške usluge </td><td>Sjedinjene Američke Države (USA)</td>
                </tr>
                <tr>
                    <td>SUPERWEB</td><td>Analitičke usluge</td><td>
                    Srbija, Svetozara Markovića 20,

                    32000 Čačak
                </td>
                </tr>
                <tr>
                    <td>Facebook Ireland Limited </td><td>Analitičke i marketinške usluge  </td><td>Malta</td>
                </tr>
                <tr>
                    <td>DIGITAL OCEAN LLC  </td><td>hosting kompanija </td> <td>Sjedinjene Američke Države (USA)</td>
                </tr>
                <tr>
                    <td>INNSITE </td><td>Analitičke i marketinške usluge </td><td>Srbija</td>
                </tr>
                <tr>
                    <td>Uspon doo </td><td>Knjigovodstvene usluge i RMA </td><td>Srbija, Čačak</td>
                </tr>
                <tr>
                    <td>Agencija Đorđević </td><td>Knjigovodstvene usluge </td><td>Srbija, Čačak</td>
                </tr>
                </tbody>
            </table>

            U cilju zaštite bezbednosti podataka koji se prikupljaju i obrađuju, primenjuju se neophodne tehničke,
            organizacione i kadrovske mere zaštite u skladu sa zahtevima važećeg ZZPL, uključujući, ali ne
            ograničavajući se na: tehničke mere zaštite, mere zaštite koje se odnose na fizički pristup mestu gde su
            pohranjeni vaši podaci, mere zaštite informacione bezbednosti vaših podataka u skladu sa važećom
            regulativom, i ostale mere koje su primerene obradi i neophodne da bi se obezbedila zaštita konkretnih
            podataka o ličnosti.
            <br/><br/>
            Treća lica koja obrađuju podatke takođe imaju obavezu da primenjuju sve potrebne tehničke,
            organizacione i kadrovske mere.

            <h4 id="koliko-dugo-cuvamo-vase-podatke" className="mb-4 font-weight-bold">
                8. KOLIKO DUGO ČUVAMO VAŠE PODATKE?
            </h4>
            <p className="mb-5">
                GoGorila čuva Vaše podatke samo dok postoji svrha i osnov za to u skladu sa Zakonom. Podatke o
                ličnosti, koji su prikupljeni u cilju izvršenja prava i obaveza iz ugovornog odnosa, obrađivaćemo sve dok
                traje ugovorni odnos sa licem na koje se podaci odnose, osim u slučajevima kada smo u obavezi da
                podatke čuvamo i nakon okončanja ugovornog odnosa sa licem na koje se podaci odnose, na osnovu
                relevantnih zakona, saglasnosti lica na koje se podaci odnose ili legitimnog interesa (npr. u slučaju
                eventualnog spora sa licem na koje se podaci odnose).
                <br/><br/>
                Ukoliko je pokrenut sudski, upravni ili vansudski postupak, podaci o ličnosti se mogu čuvati do kraja
                takvog postupka.
                <br/><br/>
                Podaci prikupljeni samo zbog pružanja prodajnih transakcija, odnosno za izvršenje ugovora, čuvaju se još
                12 meseci od datuma poslednje transakcije (na primer, transakcija isporuke, dostave, reklamacije i sl.) ili
                od isteka zakonskog roka za reklamaciju, koji god da je od tih datuma kasniji.
                <br/><br/>
                Podaci o ličnosti koji se obrađuju isključivo na osnovu Vaše saglasnosti obrađujemo u skladu sa svrhom
                zbog koje su prikupljeni, odnosno do povlačenja Vaše saglasnosti. Na primer, ukoliko se odjavite sa
                Newsletter-a, obrisaćemo Vaš podatak o email-u, osim ukoliko ste Vašu email adresu podelili sa nama i
                za druge svrhe. Vašu saglasnost možete povući u svakom momentu u skladu sa
                odeljkom  <a href="/politika-privatnosti#vasa-prava">Vaša prava</a>.
            </p>

            <h4 id="vasa-prava" className="mb-4 font-weight-bold">
                9. VAŠA PRAVA
            </h4>
            <p className="mb-4">
                Kao licu čiji se podaci o ličnosti obrađuju, Zakon Vam garantuje određena prava. To su:
            </p>
            <h5 className="mb-4 font-weight-bold">
                PRAVO PRISTUPA
            </h5>
            <p className="mb-5">
                U svakom trenutku imate pravo da nam uputite zahtev radi dobijanja informacije o tome da li
                obrađujemo Vaše podatke o ličnosti. Ukoliko se podaci obrađuju, imate pravo da tražite kopiju pojedinih
                ili svih podataka o ličnosti.
                <br/><br/>
                Vaš zahtev možete uputiti na sledeću email adresu:web@gogorila.com. Tražene informacije ćemo Vam
                pružiti najkasnije u roku od 30 dana od dana prijema zahteva. U izuzetnim okolnostima, kada je
                pronalaženje ili pribavljanje informacija posebno složeno ili opravdano iziskuje duže vreme, ovaj period
                se može produžiti za još 60 dana. Međutim, u takvim situacijama uredno ćemo Vas obavestiti o svakom
                kašnjenju ili razlozima kašnjenja.
                <br/><br/>
                Ukoliko se ne slažemo sa Vašim zahtevom za pribavljanje podataka, obavestićemo Vas u roku od 30 dana
                o razlozima odbijanja zahteva i o Vašem pravu na podnošenje pritužbe Povereniku.
                <br/><br/>
                Lice čiji se podaci prikupljaju ima pravo da bude obavešten o sledećim okolnostima:
                <br/><br/>
                1) o svrsi obrade;<br/>
                2) o vrstama podataka o ličnosti koji se obrađuju;<br/>
                3) o primaocu ili vrstama primalaca kojima su podaci o ličnosti otkriveni ili će im biti otkriveni, a
                posebno primaocima u drugim državama ili međunarodnim organizacijama;<br/>
                4) o predviđenom roku čuvanja podataka o ličnosti, ili ako to nije moguće, o kriterijumima za
                određivanje tog roka;<br/>
                5) pravu da se od rukovaoca zahteva ispravka ili brisanje njegovih podataka o ličnosti, prava na
                ograničenje obrade i prava na prigovor na obradu;<br/>
                6) o pravu da se podnese pritužba Povereniku;<br/>
                7) dostupne informacije o izvoru podataka o ličnosti, ako podaci o ličnosti nisu prikupljeni od lica
                na koje se odnose;<br/>
                8) o postojanju postupka automatizovanog donošenja odluke, uključujući profilisanje iz člana 38.
                st. 1. i 4. ovog zakona, i, najmanje u tim slučajevima, svrsishodne informacije o logici koja se pri
                tome koristi, kao i o značaju i očekivanim posledicama te obrade po lice na koje se podaci
                odnose.
            </p>

            <h5 className="mb-4 font-weight-bold">
                PRAVO NA ISPRAVKU
            </h5>
            <p className="mb-5">
                Lice na koje se podaci odnose ima pravo da se njegovi netačni podaci o ličnosti bez nepotrebnog
                odlaganja isprave, dopune i ažuriraju. U zavisnosti od svrhe obrade, lice na koje se podaci odnose ima
                pravo da svoje nepotpune podatke o ličnosti dopuni, što uključuje i davanje dodatne izjave.
            </p>

            <h5 className="mb-4 font-weight-bold">
                PRAVO NA ZABORAV (BRISANJE PODATAKA)
            </h5>
            <p className="mb-5">
                Lice može zahtevati da se njegovi podaci o ličnosti izbrišu od strane rukovaoca.
                <br/><br/>
                Ukoliko je deo podataka potreban radi ispunjavanja zakonskih obaveza, biće izbrisani samo podaci koji
                nisu neophodni radi ispunjavanja zakosnkih obaveza.
            </p>
            <h5 className="mb-4 font-weight-bold">
                PRAVO NA PRIGOVOR
            </h5>
            <p className="mb-5">
                Lice čiji se podaci prikupljaju i obrađuju može uputiti prigovor na prikupljanje, zahtev za izmenu, dopunu
                ili ažuriranje kao i brisanje prikupljenih podataka na sledeću mejl
                adresu <a href="mailto:web@gogorila.com">web@gogorila.com</a>. GoGorila će
                na ovaj zahtev odgovoriti u najkraćem mogućem roku a najkasnije u roku od 90 dana.
            </p>
            <h5 className="mb-4 font-weight-bold">
                PRAVO NA OGRANIČENJE OBRADE
            </h5>
            <p className="mb-5">
                Lice ima pravo da zahteva ograničenje obrade podataka u skladu sa odredbama zakona. GoGorila će
                obavestiti sve primaoce kojima su podaci o ličnosti otkriveni o svakoj ispravci ili brisanju podataka o
                ličnosti ili ograničenju njihove obrade, osim ako je to nemoguće ili zahteva prekomeran utrošak vremena
                i sredstava.
                <br/><br/>
                Lice na koje se podaci odnose ima pravo da njegove podatke o ličnosti koje je prethodno dostavilo
                rukovaocu primi od njega u strukturisanom, uobičajeno korišćenom i elektronski čitljivom obliku i ima
                pravo da ove podatke prenese drugom rukovaocu bez ometanja od strane rukovaoca kojem su ti podaci
                bili dostavljeni, pod zakonom propisanim uslovima.
            </p>

            <h5 className="mb-4 font-weight-bold">
                PRAVO NA PRIGOVOR
            </h5>
            <p className="mb-5">
                Ukoliko je naš legitimni interes osnov za obradu Vaših podataka, imate pravo da podnesete prigovor na
                način na koji rukujemo Vašim podacima. Pored toga, imate pravo i da u bilo kom trenutku uputite
                prigovor na obradu Vaših podataka u cilju direktnog marketinga, koja uključuje profilisanje u meri u kojoj
                je povezano sa takvim direktnim marketingom.
            </p>
            <h5 className="mb-4 font-weight-bold">
                PRAVO NA OPOZIV SAGLASNOST
            </h5>
            <p className="mb-5">
                Lice ima pravo da u svakom momentu opozove, odnosno povuče datu saglasnost za prikupljanje
                podataka, nakon čega je GoGorila obavezan da obustavi obradu ličnih podataka kao i da iste obriše, na
                način kako je to napred predviđeno.
                <br/><br/>
                Opoziv pristanka se može izvršiti dostavljanjem izjave o opozivu na
                mejl <a href="mailto:web@gogorila.com">web@gogorila.com</a>. Pitanja,
                pritužbe, zahteve i dodatne informacije oko Obaveštenja o privatnosti, zaštite podataka o ličnosti i
                ostvarivanja vaših prava možete postaviti slanjam na
                mejl <a href="mailto:web@gogorila.com">web@gogorila.com</a>.
            </p>

            <h5 className="mb-4 font-weight-bold">
                PRAVO NA PRITUŽBU
            </h5>
            <p className="mb-5">
                U slučaju da smatrate da se Vaši podaci obrađuju nezakonito, možete da podnesete pritužbu Povereniku
                za informacije od javnog značaja i zaštitu podataka o ličnosti, Bulevar Kralja Aleksandra 15, 11000
                Beograd, Srbija,
                email: <a href="mailto:office@poverenik.rs">office@poverenik.rs</a> ili na broj telefona 011/3408-900.
            </p>


            <h4 id="stupanje-na-snagu-i-izmene-politike" className="mb-4 font-weight-bold">
                10. STUPANJE NA SNAGU I IZMENE POLITIKE
            </h4>
            <p className="mb-5">
                Ova Politika stupa na snagu dana 17.10.2020. godine.
                <br/><br/>
                Informacije o obradi podataka o ličnosti ažuriramo redovno i blagovremeno, u skladu sa promenama u
                sopstvenim poslovnim procesima ili na osnovu obaveza koje proističu iz relevantnih propisa.
                <br/><br/>
                Važeća verzija Politike će uvek biti objavljena na našem Sajtu na vidljivom mestu i ažurirana pravila iz

                Politike će se primenjivati od trenutka kada objavimo ažuriranu Politiku na našem Sajtu. U slučaju da
                dođe do značajnih promena koje u većoj meri utiču na obradu podataka o ličnosti, informaciju ćemo bez
                odlaganja objaviti na Sajtu i direktno ćemo Vas informisati putem Vaše e-mail adrese, ukoliko
                raspolažemo ovim podatkom.
            </p>
        </div>
    };
}

export default Text3;
