import React from 'react';
import {connect} from "react-redux";
import {Switch, BrowserRouter} from "react-router-dom";
import Welcome from "../views/pages/welcome/Welcome";
import {getCategories} from "./hooks";
import {setCategories} from "../store/actions/categoriesActions";
import Shop from "../views/pages/shop/Shop";
import ScrollToTop from "./ScrollToTop";
import routes from "./routes";
import Wishlist from "../views/pages/wishlist/Wishlist";
import Loader from "../views/framework/Loader";
import Basket from "../views/pages/basket/Basket";
import {setCategoriesById} from "../store/actions/categoriesByIdActions";
import ThankYou from "../views/pages/thankYou/ThankYou";
import OrderStatus from "../views/pages/orderStatus/OrderStatus";
import LayoutMiddleware from "../providers/LayoutMiddleware";
import Socket from "../views/framework/Socket";
import Categories from "../views/pages/categories/Categories";
import Brands from "../views/pages/brands/Brands";
import Text0 from "../views/pages/text/Text0";
import Text1 from "../views/pages/text/Text1";
import Text2 from "../views/pages/text/Text2";
import Text3 from "../views/pages/text/Text3";
import Text4 from "../views/pages/text/Text4";
import Text5 from "../views/pages/text/Text5";
import Text6 from "../views/pages/text/Text6";
import Text7 from "../views/pages/text/Text7";
import GuestMiddleware from "../providers/GuestMiddleware";
import Login from "../views/pages/auth/login/Login";
import UserMiddleware from "../providers/UserMiddleware";
import Dashboard from "../views/pages/auth/dashboard/Dashboard";
import Register from "../views/pages/auth/register/Register";
import PasswordRecovery from "../views/pages/auth/reset/PasswordRecovery";
import Newsletter from "../views/pages/auth/newsletter/Newsletter";
import Orders from "../views/pages/auth/orders/Orders";
import ReactPixel from 'react-facebook-pixel';
import Recommended from "../views/pages/recommended/Recommended";

const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
ReactPixel.init('528172655187482', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view


class App extends React.Component {

    state = {
        loaded: false
    }

    render() {
        if(!this.state.loaded)  {
            return <Loader height="100vh" />
        }
        return (
            <div>
                <Socket />
                <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                        <LayoutMiddleware exact path="/" component={Welcome} />
                        <LayoutMiddleware exact path={routes.wishlist.route} component={Wishlist} />
                        <LayoutMiddleware exact path={routes.categories.route} component={Categories} />
                        <LayoutMiddleware exact path={routes.brands.route} component={Brands} />
                        <LayoutMiddleware exact path={routes.basket.route} component={Basket} />
                        <LayoutMiddleware exact path={routes.recommended.route(":slug?")} component={Recommended} />

                        <LayoutMiddleware exact path={routes.offer.route} component={Shop} />
                        <LayoutMiddleware exact path={routes.faq[0].route} component={Text0} />
                        <LayoutMiddleware exact path={routes.faq[1].route} component={Text1} />
                        <LayoutMiddleware exact path={routes.faq[2].route} component={Text2} />
                        <LayoutMiddleware exact path={routes.faq[3].route} component={Text3} />
                        <LayoutMiddleware exact path={routes.faq[4].route} component={Text4} />
                        <LayoutMiddleware exact path={routes.faq[5].route} component={Text5} />
                        <LayoutMiddleware exact path={routes.faq[6].route} component={Text6} />

                        <LayoutMiddleware exact path={routes.thankYou.route(":code?")} component={ThankYou} />
                        <LayoutMiddleware exact path={routes.orderStatus.route(":code")} component={OrderStatus} />

                        <GuestMiddleware exact path={routes.login.route} component={Login}/>
                        <GuestMiddleware exact path={routes.register.route} component={Register}/>
                        <GuestMiddleware exact path={routes.password_recovery.route} component={PasswordRecovery}/>
                        <UserMiddleware exact path={routes.dashboard.route} component={Dashboard}/>
                        <UserMiddleware exact path={routes.newsletter.route} component={Newsletter}/>
                        <UserMiddleware exact path={routes.orders.route} component={Orders}/>

                        <LayoutMiddleware exact path="*" component={Shop} />
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }

    getNestedChildren = (arr, parent, lvl = 1) => {
        var out = [];
        for(let i in arr) {
            if(arr[i].parent === parent) {
                arr[i].children = this.getNestedChildren(arr, arr[i].id, lvl+1);
                for(let j=0; j<arr[i].children.length; j++) {
                    arr[i].count = parseInt(arr[i].count) + parseInt(arr[i].children[j].count);
                }
                out.push(arr[i]);
            }
            else if(lvl === 1)   {
                out.push(arr[i]);
            }
        }
        return out
    }

    componentDidMount() {
        getCategories({all: true, withCounter: true}).then((response) => {
            let content = this.getNestedChildren(response.data, null);
            content = content.filter(item => item.parent === null);
            this.props.setCategories(content);
            this.props.setCategoriesById(content);
            this.setState({...this.state, loaded: true});
        });
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    setCategories: setCategories,
    setCategoriesById: setCategoriesById
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
