import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AuthLayout from "../AuthLayout";
import {postRegisterUser} from "../../../../app/hooks";
import {loginUser} from "../../../../providers/userProvider";
import routes from "../../../../app/routes";
import {setUser} from "../../../../store/actions/userActions";

class Register extends React.Component {

    form = {
        email: "",
        username: "",
        password: "",
        password_confirmation: ""
    }
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    };

    submit = (e) => {
        e.preventDefault();
        if(this.state.form.password !== this.state.form.password_confirmation)        {
            this.setState({...this.state, error: {password_confirmation: "Ne poklapaju se lozinke."}});
            return false;
        }
        this.setState({...this.state, loading: true, error: {}, success: false});
        postRegisterUser(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, form: this.form, success: true, error: false});
            this.login(response);
        }).catch((error) => {
            this.error(error);
        });
    };

    login = (response) => {
        let user = loginUser(response, this.state.form.remember);
        if(user) {
            this.props.setUser(user);
            window.location.reload();
        }
    };

    error = (error) => {
        this.setState({...this.state, loading: false, error: error, success: false});
    };

    render = () => {
        let form = this.state.form;
        return <AuthLayout>
            <div>
                <form action="#" method="POST" onSubmit={this.submit}>
                    <div>
                        <input
                            name="email"
                            onChange={this.changeField}
                            value={form.email}
                            placeholder="Email"
                            type="email"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.email &&
                        <div className="alert alert-danger">{this.state.error.email}</div>}
                    </div>

                    <div>
                        <input
                            name="username"
                            onChange={this.changeField}
                            value={form.username}
                            placeholder="Korisničko ime"
                            type="text"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.username &&
                        <div className="alert alert-danger">{this.state.error.username}</div>}
                    </div>

                    <div>
                        <input
                            name="password"
                            type="password"
                            onChange={this.changeField}
                            value={form.password}
                            placeholder="Lozinka"
                            className="form-control mb-3 form-control-lg"
                            required={true}
                        />
                        {this.state.error.password &&
                        <div className="alert alert-danger">{this.state.error.password}</div>}
                    </div>

                    <div>
                        <input
                            name="password_confirmation"
                            type="password"
                            onChange={this.changeField}
                            value={form.password_confirmation}
                            placeholder="Ponovite lozinku"
                            className="form-control mb-3 form-control-lg"
                            required={true}
                        />
                        {this.state.error.password_confirmation &&
                        <div className="alert alert-danger">{this.state.error.password_confirmation}</div>}
                    </div>


                    <button type="submit" disabled={this.state.loading} className="btn btn-lg btn-block btn-primary mb-3">
                        Registrujte se
                    </button>
                </form>

                {this.state.error.message &&
                <div className="alert alert-danger mt-2">
                    {this.state.error.message}
                </div>}

                {this.state.success &&
                <div className="alert alert-success mt-2">
                    Uspešno ste kreirali nalog.
                </div>}

                <div className="font_1 mb-2">
                    <Link to={routes.login.route}>
                        Imate nalog? Ulogujte se.
                    </Link>
                </div>
            </div>
        </AuthLayout>
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);