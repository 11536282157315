import React from "react";

class Loader extends React.Component {

    state = {

    }

    render = () => {
        let height = this.props.height ? this.props.height : 300;
        let w = 40;
        let h = 40;
        if(this.props.size === "sm")   {
            height = "";
            w = 30;
            h = 30;
        }
        return <div className="d-flex justify-content-center align-items-center"
                    style={{height: height, opacity: this.props.opacity || 1}}>
            <div className="text-center">
                <div className="d-flex justify-content-center mb-1">
                    <div className="loader" style={{width: w, height: h}} />
                </div>
            </div>
        </div>

    };
}

export default Loader;
