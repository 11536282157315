import moment from "moment";

export function adminPrice(product)   {
    if(product.countdown_start)      {
        if(moment.utc(product.countdown_start).local().diff() > 0)  {
            return false;
        }
    }
    if(product.countdown_end)      {
        if(moment.utc(product.countdown_end).local().diff() < 0)  {
            return false;
        }
    }
    return true;
}
export function processPrice(product) {
    if (product.admin_price) {
        if(adminPrice(product))     {
            return product.admin_price;
        }
    }
    if (product.recommended_price) {
        return product.recommended_price;
    }
    if (product.calculated_price) {
        return product.calculated_price;
    }
    return "";
}

export function discount(item) {
    return Math.round(((item.admin_discount_price-item.admin_price)*100)/item.admin_discount_price);
}

export function currencies()        {
    return [{name: "Dinar", short: "RSD"},{name: "Euro", short: "EUR"}]
}
export function getCurrency(id)        {
    if(!currencies()[id]) return {};
    return currencies()[id];
}

export function formatPrice(price) {
    if (!price && price !== 0) return '';
    price = parseFloat(price).toFixed(0);
    let strPrice = String(price);
    if (price > 999) {
        const pos = -3;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    if (price > 999999) {
        const pos = -7;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    return strPrice.replace(".", ",") + " RSD";
}