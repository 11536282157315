import React from 'react';
import Carousel from "../../framework/Carousel";
import IndexSlider from "./IndexSlider";
import {getBanners, getBlocks} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import Banner from "../../elements/Banner";
import {connect} from "react-redux";
import {setData} from "../../../store/actions/dataActions";
import Title from "../../elements/Title";


class Welcome extends React.Component {

    state = {
        banners: false,
        blocks: false,
    }

    render() {
        let trackedId = 0;
        return (
            <div>
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-8 mb-4">
                            <div className="overflow-hidden rounded h-100 noSwipe">
                                {this.state.banners
                                    ? <IndexSlider banners={this.state.banners} />
                                    : <div>
                                        <div className="d-md-none">
                                            <Loader height={140} opacity={0.2} />
                                        </div>
                                        <div className="d-none d-md-block">
                                            <Loader height={400} opacity={0.2} />
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="ml-md-4">
                                <Banner className="mb-4" banner={this.state.banners?.index1 && this.state.banners?.index1[0]} />
                            </div>

                            <div className="ml-md-4">
                                <Banner className="mb-4" banner={this.state.banners?.index2 && this.state.banners?.index2[0]} />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.blocks
                    ? this.state.blocks.map((block) => {
                        if(block.carousel_name && block.carousel_display === 1) {
                            trackedId = trackedId + 1;
                        }
                        return <div key={block.id}>
                            {block.banner_name &&
                            <div className="container">
                                {block.title &&
                                <div className="mb-3">
                                    <Title name={block.title} />
                                </div>}
                            <div className="row">
                                {block.banners.map((banner) => {
                                    return <div className="col-md" key={banner.id}>
                                        <Banner className="mb-4" banner={banner} />
                                    </div>
                                })}
                            </div>
                            </div>}

                            {block.carousel_name &&
                            <Carousel className="mb-4" block={block} trackedId={trackedId}  />}
                        </div>
                    })
                    : <Loader opacity={0.1} />}
            </div>
        );
    }
    componentDidMount() {
        this.props.setData({id: window.location.href, type: "page", title: "Naslovna"});
        getBlocks().then((response) => {
            this.setState({...this.state, blocks: response.data});
        });
        getBanners().then((response) => {
            let group = response.data.reduce((r, a) => {
                r[a.name] = [...r[a.name] || [], a];
                return r;
            }, {});
            this.setState({...this.state, banners: group});
        });
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    setData: setData
};
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
