import React from "react";
import {getBlocks} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import ProductRender from "../../elements/ProductRender";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";
import slugify from "slugify";
import Title from "../../elements/Title";

class Recommended extends React.Component {

    state = {
        blocks: false
    }

    render()    {
        if(!this.state.blocks)  {
            return <Loader />
        }
        return <div className="container mt-3 mb-5">
            <div className="row">
                <div className="col-md-3">
                    {this.state.blocks
                        && this.state.blocks.map((block) => {
                            if(!block.title) return null;
                            if(block.carousel_display !== 3) return null;
                            let active = "";
                            if(this.props.match.params.slug)    {
                                if(this.props.match.params.slug === slugify(block.title).toLocaleLowerCase()) active = "text-primary";
                            }
                            return <div key={block.id}>
                                <Link to={routes.recommended.route(slugify(block.title).toLocaleLowerCase())}
                                      className={`weight_500 link ${active}`}>
                                    {block.title}
                                    <span className="ml-2 font-weight-normal font_09 text-black-50">
                                        ({block.carousel.length})
                                    </span>
                                </Link>
                            </div>
                        })}
                </div>
                <div className="col-md-9">
                    {this.state.blocks
                    && this.state.blocks.map((block) => {
                        if(!block.title) return null;
                        if(block.carousel_display !== 3) return null;
                        if(this.props.match.params.slug)    {
                            if(this.props.match.params.slug !== slugify(block.title).toLocaleLowerCase()) return null;
                        }
                        return <div key={block.id} className="mb-4">
                            <div className="mb-3">
                                <Title name={block.title} nameAppend={<span className="ml-1 font-weight-normal font_09 text-black-50">
                                    ({block.carousel.length})
                                </span>} />
                            </div>

                            <div key={block.id} className="row">
                                {block.carousel.map(item => {
                                    return <div key={block.id +"_" +item.id} className="col-md-3 col-6 p-0 mb-3">
                                        <ProductRender item={item.product} />
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        getBlocks().then((response) => {
            this.setState({...this.state, blocks: response.data});
        });
    }
}
export default Recommended;