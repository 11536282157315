import React from "react";
import {connect} from "react-redux";
import Title from "../../elements/Title";
import {Link} from "react-router-dom";
import {setUser} from "../../../store/actions/userActions";
import {logoutUser} from "../../../providers/userProvider";
import routes from "../../../app/routes";


class UserLayout extends React.Component {

    logOut = (e) => {
        e.preventDefault();
        logoutUser();
        this.props.setUser(false);
    };

    render = () => {
        return <div className="mt-4 mb-5">
            <div className="container">

                <div className="row">
                    <div className="col-md-3">
                        <div className="mb-3">
                            <Title name="Moj nalog" />
                        </div>
                        <div className="list_buttons">
                            <div className="mb-2">
                                <Link to={routes.dashboard.route}>Informacije o nalogu</Link>
                            </div>
                            <div className="mb-2">
                                <Link to={routes.orders.route}>Porudžbine</Link>
                            </div>
                            <div className="mb-2">
                                <Link to={routes.newsletter.route}>Prijave za newsletter</Link>
                            </div>
                            <div className="mb-2">
                                <a href="/" onClick={this.logOut}>
                                    Izlogujte se
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-9">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);