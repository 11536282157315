import React from 'react';
import {connect} from "react-redux";
import MainHeader from "./MainHeader";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import MainFooter from "./MainFooter";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import FloatingSidebar from "./FloatingSidebar";
import Drawer from "../framework/Drawer";
import CategoriesDrawer from "../drawers/CategoriesDrawer";
import BasketModal from "../drawers/BasketModal";
import Currency from "./Currency";
import CookiesAlert from "./CookiesAlert";

import {GA4React} from "ga-4-react";

const ga4react = new GA4React(
    'G-RD0HR77RE9',
    { /* ga custom config, optional */ },
    [ /* additional code, optional */ ],
    5000 /* timeout, optional, defaults is 5000 */,
    //options /* { nonce: ['first-script-is-async','second-script'] } */
);

ga4react.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event','pageview','path') // or your custom gtag event
},(err) => {
    console.error(err)
})


class MainLayout extends React.Component {

    state = {
        open: true
    }

    render() {
        let Component = this.props.component;
        return (
            <div>
                <CookiesAlert />
                <div className="bg-light mt-5 mt-md-0 ml-3 ml-md-0 mr-3 mr-md-0 mb-3">
                    <div className="container pt-2 pb-2 font_09">
                        <div className="d-md-flex align-items-center justify-content-between text-center text-md-left">
                            <a href="tel:+381 64 860 15 54">
                                <i className="fa fa-phone-alt" /> Call centar: 064/860-15-54
                            </a>
                            <div className="mt-md-0 mt-2">
                                <Link to={routes.wishlist.route} className="mr-3">
                                    <i className="far fa-heart mr-1" aria-hidden="true" />
                                    Lista želja <span className="text-black-50">({this.props.wishlist?.length})</span>
                                </Link>

                                <Currency />


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <MainHeader open={this.state.open} history={this.props.history} location={this.props.history.location} />
                </div>

                <div className="mt-4 pb-5">
                    <Component match={this.props.match} history={this.props.history} location={this.props.history.location} />
                </div>

                <MainFooter />
                <FloatingSidebar open={this.state.open} />
                <Drawer
                    width="80%"
                    anchor="left"
                    show={this.props.drawers.categories.status}
                    close={() => this.props.closeDrawer("categories")}>

                    <CategoriesDrawer />
                </Drawer>
                <BasketModal />
            </div>
        );
    }

    scrolled = 0;
    isScrolling = false;
    scroll = () => {
        window.clearTimeout( this.isScrolling );
        if(this.scrolled > window.scrollY)      { // SCROLLED TOP
            this.setState({...this.state, open: true});

        } else  {
            this.setState({...this.state, open: false});
        }
        this.scrolled = window.scrollY;
        this.isScrolling = setTimeout(() => {
            this.setState({...this.state, open: true});
        }, 1);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.scroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll);
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
    drawers: state.drawers,
    wishlist: state.wishlist
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
