import React from 'react';
import {Link} from "react-router-dom";
import {image} from "../../app/helpers";
import Loader from "../framework/Loader";


class Banner extends React.Component {

    image = (banner) => {
        const className = this.props.className || "";
        return <>
            <div className={"overflow-hidden rounded d-none d-md-block " + className}>
                <img src={image(banner.image_path, false, banner.image_bucket)} className="w-100" alt={banner.alt} />
            </div>
            {banner.show_mobile &&
            <div className={"overflow-hidden rounded d-md-none " + className}>
                <img src={image(banner.mobile_image_path || banner.image_path, false, banner.image_bucket)} className="w-100" alt={banner.alt} />
            </div>}
        </>
    }

    render() {
        if(!this.props.banner) {
            return <Loader height={240} opacity={0.2} />
        }
        const banner = this.props.banner;
        if(!banner.link)    {
            return this.image(banner);
        }
        if(banner.link.startsWith("http://") || banner.link.startsWith("https://")) {
            return <a href={banner.link} target={banner.blank ? "_blank" : ""}  rel="noreferrer">
                {this.image(banner)}
            </a>
        }
        return (
            <Link to={banner.link} target={banner.blank}>
                {this.image(banner)}
            </Link>
        );
    }
}
export default Banner;
