import React from 'react';
import Overlay from "./Overlay";


class Modal extends React.Component   {

    state = {
        render: false
    }

    render = () => {
        let style = {};
        if(this.props.width) style.maxWidth = this.props.width;
        if(this.props.zIndex) style.zIndex = this.props.zIndex;
        let classes = this.props.show ? "fm_modal" : "fm_modal hide";
        if(this.props.scroll) classes = classes + " scroll ";
        if(this.props.anchor)  classes = classes + " " + this.props.anchor;
        return <React.Fragment>
            <div style={style} className={classes}>
                <div className="fm_modal_content_wrapper">
                <div className="fm_modal_content">
                    <button onClick={this.props.close} className="btn btn-link modal_close">
                        <i className="font_4 fa fa-close" />
                    </button>

                    {this.state.render &&
                    this.props.children}
                </div>
                </div>
            </div>
            <Overlay z={this.props.zIndex ? this.props.zIndex-1 : 21} show={this.props.show} close={this.props.close} />
        </React.Fragment>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show !== this.props.show)  {
            if(this.props.show)     {
                this.setState({...this.state, render: true});
            }
            else {
                if(this.props.reload)       {
                    let t = this;
                    setTimeout(function () {
                        t.setState({...t.state, render: false});
                    }, 300);

                }
            }
        }
    }
}

export default Modal;
