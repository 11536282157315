import {ADD_TO_TRACKED, SET_TRACKED, REMOVE_FROM_TRACKED} from "../actions/trackedActions";
import moment from "moment";
import {processPrice} from "../../providers/price";

function load() {
    let favs = localStorage.getItem('favs');
    if(!favs)
        return [];
    let parsed;
    try {
        parsed = JSON.parse(favs);
    }
    catch(err) {
        localStorage.removeItem('favs');
        return [];
    }
    if(!Array.isArray(parsed))      {
        localStorage.removeItem('favs');
        return [];
    }
    const newState = parsed.filter((item) => {
       if(item.timestamp)       {
           if((moment().unix() - item.timestamp) > 60*60*24*7)    { // posle 7 dana se brise
               return false;
           }
       }
       return true;
    });
    localStorage.setItem('favs', JSON.stringify(newState));
    return newState;
}

const trackedReducer = function(state = load(), {type, payload})      {
    switch(type)        {
        case SET_TRACKED:
            return payload;
        case ADD_TO_TRACKED:
            let inside = false;
            const pp = processPrice(payload);
            let newState = [...state];
            for(let i=0; i<newState.length; i++)   {
                if(parseInt(newState[i].category_id) === parseInt(payload.category_id))    {
                    inside = true;
                    newState[i].count = newState[i].count + 1;
                    newState[i].timestamp = moment().unix();
                    newState[i].price = (parseFloat(newState[i].price) + parseFloat(pp))/2;
                }
            }
            if(!inside) {
                newState.push({category_id: payload.category_id, count: 1, price: pp, timestamp: moment().unix()});
            }
            newState.sort((a, b) => (a.count < b.count || a.timestamp < b.timestamp) ? 1 : -1);
            localStorage.setItem('favs', JSON.stringify(newState));
            return newState;
        case REMOVE_FROM_TRACKED:
            const nS =  state.filter(item => item.category_id !== payload);
            localStorage.setItem('favs', JSON.stringify(nS));
            return nS;
        default:
            return state;
    }
}
export default trackedReducer;