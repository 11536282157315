import React from 'react';
import Overlay from "./Overlay";
import { Swipeable } from "react-swipeable";

class Drawer extends React.Component   {

    state = {
        render: false
    }

    render = () => {
        let classes = this.props.show ? "drawer show" : "drawer";
        let swipe = {onSwipedRight: this.props.close};
        let style = {};
        if(this.props.anchor === "left")    {
            classes = classes + " left";
            swipe = {onSwipedLeft: this.props.close}
        }
        if(this.props.width)        {
            style.width = this.props.width;
        }
        if(this.props.zIndex)        {
            style.zIndex = this.props.zIndex;
        }
        if(this.props.maxWidth)        {
            style.maxWidth = this.props.maxWidth;
        } else      {
            style.maxWidth = "90%";
        }
        return <React.Fragment>
                <div className={classes} style={style}>
                    {this.props.anchor === "bottom"
                        ? <Swipeable {...swipe} className="">
                            <div className="">
                                {this.state.render &&
                                this.props.children}
                            </div>
                        </Swipeable>
                        : <Swipeable {...swipe} className="swipeable">
                            <div className="drawer_scrollable">
                                {this.state.render &&
                                this.props.children}
                            </div>
                        </Swipeable>}
                </div>

            {!this.props.noOverlay &&
            <Overlay show={this.props.show} close={this.props.close} />}
        </React.Fragment>
    };

    escFunction = (e) =>  {
        if(e.keyCode === 27) {
            this.props.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        if(this.props.preload)      {
            this.setState({...this.state, render: true});
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto";
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show !== this.props.show)  {
            if(this.props.show)     {
                document.body.style.overflow = "hidden";
                this.setState({...this.state, render: true});
            }
            else {
                document.body.style.overflow = "auto";
                if(this.props.reload)       {
                    let t = this;
                    setTimeout(function () {
                        t.setState({...t.state, render: false});
                    }, 300);

                }
            }
        }
    }
}

export default Drawer;
