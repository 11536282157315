import React from "react";
import SocialButton from "../SocialButton";
import {postLoginUserByFacebook} from "../../../../app/hooks";


class Facebook extends React.Component {

    handleSocialLogin = (user) => {
        const accessToken = user._token.accessToken;
        const userId = user._profile.id;
        postLoginUserByFacebook({userId: userId, accessToken: accessToken}).then((response) => {
            this.props.login(response);
        }).catch((error) => {
            this.props.error(error);
        });
    }

    handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    render = () => {
        return  <div>
            <SocialButton
                className="btn btn-lg btn-block btn-facebook align-items-center justify-content-center facebook_btn mb-3 d-flex"
                provider='facebook'
                appId='612058306132900'
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
            >
                <i className="fab fa-facebook-square font_5 mr-2" />
                <div>
                    <span className="d-none d-md-inline-block"> Uloguj se </span>
                    <span> Facebook </span>
                    <span className="d-none d-md-inline-block"> nalogom </span>
                </div>
            </SocialButton>
        </div>
    }
}

export default Facebook;