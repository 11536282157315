import React from 'react';
import {connect} from "react-redux";
import {openDrawer} from "../../store/actions/drawersActions";
import {Link} from "react-router-dom";
import routes from "../../app/routes";
import User from "../../assets/svg/User"
import Bag from "../../assets/svg/Bag"


class FloatingSidebar extends React.Component   {

    render = () => {
        return <div className="position-fixed bg-white shadow text-center d-md-none floating_sidebar"
                    style={{bottom: this.props.open ? 8 : -60}}>
            <div className="row no-gutters">
                <div className="col-3">
                    <button className="btn btn-link" onClick={() => this.props.openDrawer({name: "search"})}>
                        <i className="fa fa-search" />
                        <div> PRETRAGA </div>
                    </button>

                </div>
                <div className="col-3">
                    <Link to={routes.basket.route}
                        className="position-relative btn btn-link">
                        {this.props.basket?.length > 0 &&
                        <span className="position-absolute d-inline-block bg-primary text-center text-white"
                              style={{top: 0, right: 5, width: 20, height: 20, borderRadius: "50%"}}>
                            {this.props.basket.length}
                        </span>}
                        <Bag width={20} />
                        <div> KORPA </div>
                    </Link>
                </div>
                <div className="col-3">
                    <Link to={routes.login.route} className="btn btn-link">
                        <User width={20} />
                        <div> NALOG </div>
                    </Link>
                </div>
                <div className="col-3">
                    <button className="btn btn-link text-primary" onClick={() => this.props.openDrawer({name: "categories"})}>
                    <i className="fa fa-bars" />
                        <div> KATEGORIJE </div>
                    </button>
                </div>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    basket: state.basket
});
const mapDispatchToProps = {
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(FloatingSidebar);
