import React from "react";
import SocialButton from "../SocialButton";
import logo from "../../../../assets/images/google.webp"
import {postLoginUserByGoogle} from "../../../../app/hooks";

class Google extends React.Component {

    handleSocialLogin = (user) => {
        const idTokent = user._token.idToken;
        postLoginUserByGoogle({idToken: idTokent}).then((response) => {
            this.props.login(response);
        }).catch((error) => {
            this.props.error(error);
        });
    }

    handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    render = () => {
        return  <div>
            <SocialButton
                className="btn btn-lg btn-block btn-google align-items-center justify-content-center google_btn mb-3 d-flex"
                provider='google'
                appId='739803745067-7pvur3h0actf75fovf4otllibbmu6lhl.apps.googleusercontent.com'
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
            >
                <img src={logo} alt="Google" width={30} className="mr-2" />
                <div>
                    <span className="d-none d-md-inline-block"> Uloguj se </span>
                    <span> Google </span>
                    <span className="d-none d-md-inline-block"> nalogom </span>
                </div>
            </SocialButton>
        </div>
    }
}

export default Google;